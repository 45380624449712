import {
    ArrowUpOutlined,
    DisconnectOutlined,
    DollarOutlined,
    DownSquareOutlined,
    FallOutlined,
    FieldTimeOutlined,
    FullscreenOutlined,
    FundOutlined,
    MinusOutlined,
    StrikethroughOutlined,
    SubnodeOutlined,
    SwapOutlined,
    UpSquareOutlined
} from "@ant-design/icons";

const defaultLocalizations = {
    'macro.yield_curve_slope_10yr_vs_2yr': 'Yield Curve Slope (10-Yr vs 2-Yr)',
    'macro.yield_curve_slope_10yr_vs_3m': 'Yield Curve Slope (10-Yr vs 3-Mo)',
    'macro.treasury_forward_spread_1yr': '1-Year Treasury Forward Spread',
    'min_vol': 'Min Volatility',
    'max_vol': 'Max Volatility',
    'stock.exception': 'Sell',
    'stock.normal': 'Hold',
    'stock.none': 'N/A',
    'stock.success': 'Buy',
    'stock.cash': 'Cash',
    'stock.stocks': 'Stocks',
    'stock.bonds': 'Bonds',
    'stock.other': 'Other',
    'stock.unknown': 'Unknown',
    'factor.Sector': 'Sector',
    'factor.sector': 'Sector',
    'factor.datadate': 'Date',
    'factor.date': 'Date',
    'factor.year': 'Year',
    'factor.quarter': 'Quarter',
    'factor.month': 'Month',
    'factor.size': 'Size',
    'factor.style': 'Style',
    'factor.vbg_style': 'Style',
    'factor.future_total_return_1m': 'Future 1 month',
    'factor.future_total_return_1q': 'Future 3 month',
    'factor.future_total_return_2q': 'Future 6 month',
    'factor.future_total_return_1yr': 'Future 12 month',
    'backtest.future_total_return_1m': 'Monthly',
    'backtest.future_total_return_1q': 'Quarterly',
    'backtest.future_total_return_2q': '6 month',
    'backtest.future_total_return_1yr': 'Yearly',
    'factor.IndustryName': 'Industry',
    'factor.VG': 'VG',
    'factor.StyleSize': 'StyleSize',
    'factor.Size': 'Size',
    'factor.div_yield': 'Distr. rate',
    'factor.asset_class': 'Asset class',
    'factor.expense_ratio': 'Expense ratio',
    'factor.global_category': 'Category',
    'factor.sscore_plus': 'Equity Rating',
    'factor.MacroScore': 'Macro Score',
    'factor.MacroScore1Yr': '1 Yr Macro Score',
    'factor.sscore_plus_growth': 'Growth',
    'factor.sscore_plus_avg': 'Average',
    'factor.sscore_plus_value': 'Value',
    'factor.sscore_plus_yield': 'Yield',
    'factor.sscore_plus_profitability': 'Profitability',
    'factor.sscore_plus_momentum': 'Momentum',
    'factor.sscore_plus_safety': 'Safety',

    'factor.equal_vs_cap_weighted_spread_1yr': 'Equal vs Cap Weighted Spread (1Y)',
    'factor.vg_spread_1yr': 'Value vs Growth Spread (1Y)',
    'factor.sl_spread_1yr': 'Small vs Large Spread (1Y)',
    'factor.valuation_spread_sp500_sp400': 'Valuation Spread',
    "factor.treasury_forward_spread_1yr": "1-Year Treasury Forward Spread",
    "factor.yield_curve_slope_10yr_vs_3m": "Yield Curve Slope (10Y-3M)",
    "factor.yield_curve_slope_10yr_vs_2yr": "Yield Curve Slope (10Y-2Y)",

    'factor.short.equal_vs_cap_weighted_spread_1yr': 'Equal vs Cap Weighted',
    'factor.short.vg_spread_1yr': 'Value vs Growth',
    'factor.short.sl_spread_1yr': 'Small vs Large',
    'factor.short.valuation_spread_sp500_sp400': 'Valuation Spread',
    "factor.short.treasury_forward_spread_1yr": "Treasury Forward Spread",
    "factor.short.yield_curve_slope_10yr_vs_3m": "Yield Curve Slope",
    "factor.short.yield_curve_slope_10yr_vs_2yr": "Yield Curve Slope",

    'portfolio.type.model': 'Models',
    'portfolio.type.benchmark': 'Benchmarks',
    'portfolio.type.client': 'Client Portfolios',
    'portfolio.type.proposed': 'Proposals',
    'confirm.orion.load': 'The client needs to be SPAS-synchronized, this usually takes about 40 seconds. Continue?',
    'stock.Not Applicable': 'N/A',
    'stock.Not Defined': 'Not Defined',
    'stock.Mixed': 'Mixed',
    'stock.Blend': 'Blend',
    'stock.0': '-',

    // 'stock.Information Technology': 'IT',
    // 'stock.Health Care': 'Hlth Care',
    // 'stock.Communication Services': 'Communications',
    // 'stock.Consumer Discretionary': 'Cons Disc',
    // 'stock.Consumer Staples': 'Cons Stpls',
    // 'stock.Energy': 'Energy',
    // 'stock.Financials': 'Financials',
    // 'stock.Industrials': 'Industrials',
    // 'stock.Materials': 'Materials',
    // 'stock.Real Estate': 'R Estate',
    // 'stock.Utilities': 'Utilities',

    'stock.Technology': 'IT',
    'stock.Healthcare': 'Hlth Care',
    'stock.Consumer Cyclical': 'Cons Cycl',
    'stock.Consumer Defensive': 'Cons  Dfns',
    'stock.Financial Services': 'Financials',
    'stock.Basic Materials': 'Materials',
    'stock.Information Technology': 'IT',
    'stock.Health Care': 'Hlth Care',
    'stock.Communication Services': 'Communications',
    'stock.Consumer Discretionary': 'Cons Cycl',
    'stock.Consumer Staples': 'Cons Dfns',
    'stock.Energy': 'Energy',
    'stock.Financials': 'Financials',
    'stock.Industrials': 'Industrials',
    'stock.Materials': 'Materials',
    'stock.Real Estate': 'R Estate',
    'stock.Utilities': 'Utilities',

    'stock.Large': 'Large',
    'stock.Medium': 'Medium',
    'stock.Small': 'Small',
    'stock.Micro': 'Micro',
    'stock.LG': 'LG',
    'stock.LV': 'LV',
    'stock.MG': 'MG',
    'stock.MV': 'MV',
    'stock.SG': 'SG',
    'stock.SV': 'SV',
    'stock.Large Blend': 'LB',
    'stock.Large Growth': 'LG',
    'stock.Large Value': 'LV',
    'stock.Medium Blend': 'MB',
    'stock.Medium Growth': 'MG',
    'stock.Medium Value': 'MV',
    'stock.Small Blend': 'SB',
    'stock.Small Growth': 'SG',
    'stock.Small Value': 'SV',
    'stress.2013 bull market': '2013 bull market',
    'stress.2008 bear market': '2008 bear market',
    'stress.financial crisis': '2008 Financial crisis',
    'stress.interest rate hike': 'Interest rate hike',
    'stress.2020 stock market crash': '2020 stock market crash',
    'stress.interest rate hike 2022': '2022 interest rate hike',
    'stress.bear market 2022': '2022 bear market',
    'stress.bull market 2021': '2021 bull market',
    'stress.flat market 2015': '2015 flat market',
    'stress.benchmark.iShares Russell 3000 ETF (IWV)': 'Stock market',
    'stress.benchmark.iShares US Aggregate Bond ETF (AGG)': 'Bond market',

    'statistics.alpha': 'Alpha',
    'statistics.mean': 'Mean',
    'statistics.median': 'Median',
    'statistics.min': 'Min',
    'statistics.max': 'Max',
    'statistics.std': 'Std. dev.',
    'statistics.sharpe_ratio': 'Sharpe ratio',
    'statistics.mean_semidiviation': 'Semideviation',
    'statistics.mean_semideviation': 'Semideviation',
    'statistics.negative_semideviation': 'Negative semideviation',
    'statistics.n': '# observations',
    'statistics.n_obs': 'Mean stocks count',
    'statistics.jensens_alpha': "Jensen's alpha",
    'statistics.beta': 'Beta',
    'statistics.r2': 'R squared',
    'statistics.tracking_error': 'Tracking error',
    'client.risk_score': 'Risk Score',
    'client.age': 'Age',
    'client.full_name': 'Name',
    'client.market_value': 'Mk. Val.',
    'supervision.flag.full_name.high_portfolio_risk': 'High portfolio risk',
    'supervision.flag.short_name.high_portfolio_risk': 'Risk',
    'supervision.flag.icon.high_risk': <FallOutlined/>,
    'supervision.flag.full_name.large_positions': 'Large positions',
    'supervision.flag.short_name.large_positions': 'Overweight',
    'supervision.flag.icon.large_positions': <UpSquareOutlined/>,
    'supervision.flag.full_name.small_positions': 'Small positions',
    'supervision.flag.short_name.small_positions': 'Underweight',
    'supervision.flag.icon.small_positions': <DownSquareOutlined/>,
    'supervision.flag.full_name.high_equity_weight': 'High equity weight',
    'supervision.flag.short_name.high_equity_weight': 'Eq. overweight',
    'supervision.flag.icon.high_equity_weight': <ArrowUpOutlined/>,
    'supervision.flag.full_name.large_cash': 'High cash weight',
    'supervision.flag.short_name.large_cash': '$ overweight',
    'supervision.flag.icon.large_cash': <DollarOutlined/>,
    'supervision.flag.full_name.negative_cash': 'Negative cash weight',
    'supervision.flag.short_name.negative_cash': '$ negative',
    'supervision.flag.icon.negative_cash': <MinusOutlined/>,
    'supervision.flag.full_name.short_positions': 'Short positions',
    'supervision.flag.short_name.short_positions': 'Short',
    'supervision.flag.icon.short_positions': <StrikethroughOutlined/>,
    'supervision.flag.full_name.short_funds': 'Short/inverse funds',
    'supervision.flag.short_name.short_funds': 'Funds Short',
    'supervision.flag.icon.short_funds': <FundOutlined/>,
    'supervision.flag.full_name.leveraged_funds': 'Leveraged funds',
    'supervision.flag.short_name.leveraged_funds': 'Leverage',
    'supervision.flag.icon.leveraged_funds': <FullscreenOutlined/>,
    'supervision.flag.full_name.forgotten_client': 'Forgotten client',
    'supervision.flag.short_name.forgotten_client': 'Forgotten',
    'supervision.flag.icon.forgotten_client': <FieldTimeOutlined/>,
    'supervision.flag.full_name.high_trading_frequency': 'High trading frequency',
    'supervision.flag.short_name.high_trading_frequency': 'Freq',
    'supervision.flag.icon.high_trading_frequency': <SwapOutlined/>,
    'supervision.flag.full_name.billing_on_not_managed': 'Billing on not managed accounts',
    'supervision.flag.short_name.billing_on_not_managed': 'Wrong billing',
    'supervision.flag.icon.billing_on_not_managed': <DisconnectOutlined/>,
    'supervision.flag.full_name.high_expense_ratio_funds': 'High expense ratio funds',
    'supervision.flag.short_name.high_expense_ratio_funds': 'High expense ratio',
    'supervision.flag.icon.high_expense_ratio_funds': <SubnodeOutlined/>,
    'supervision.flag.full_name.forgotten_account': 'Forgotten account',
    'supervision.flag.full_name.high_risk_drift': 'High portfolio risk drift',
    'supervision.flag.full_name.agg_asset_class_deviation_from_last_rebalancing': 'Large asset allocation drift',
    'supervision.flag.full_name.bad_sector_diversification': 'Bad sector diversification',
    'supervision.flag.full_name.fundamentally_weak_equity_portfolio': 'Low score',
    'supervision.description.summary': 'On this page you can find the information on the warnings system raised after analyzing your clients\' accounts. Click on a row to view the detailed information and take actions.',
    'supervision.description.client': `The list below represents all checks being done for a client. Checks with warnings raised are marked with red. Click on a check row to view detailed information on the client compliance results and settings or update this client checks parameters. 
    The muted (aka inactive) status means the check result for current client wont be taken into account when creating representative or summary reports; the color represents the check result.`,
    'supervision.description.representatives': 'The table below shows problem client count per advisor (grouped by check type). Expand advisor row to observe the clients with warnings. Click on a client to open the details in another tab.',
    'supervision.description.clientLimits': 'The table below provides the information on custom client limits, muted checks and muted clients.',
    'supervision.client.name': 'Name',
    'supervision.client.representative': 'Representative',
    'supervision.client.state': 'State',
    'supervision.client.start_date': 'Joined',
    'supervision.client.market_value': 'Market value',
    'supervision.client.email': 'Email',
    'supervision.client.birth_date': 'Born',
    'supervision.client.source': 'Source',
    'supervision.client.external_id': 'ID',
    'supervision.action.mute': 'Mute',
    'supervision.action.unmute': 'Unmute',
    'supervision.action.mute_client': 'Mute',
    'supervision.action.unmute_client': 'Unmute',
    'supervision.action.set_limit': 'Set custom limit',
    'supervision.action.unset_limit': 'Remove custom limit',
    'supervision.action.set_status.in_progress': 'Start progress',
    'supervision.action.set_client_status.in_progress': 'Issue started',
    'supervision.action.set_client_status.resolved': 'Issue resolved',
    'supervision.action.set_status.resolved': 'Mark as resolved',
    'supervision.action.set_status.unaddressed': 'Mark as unaddressed',
    'supervision.action_for_text.mute': 'muted',
    'supervision.action_for_text.unmute': 'unmuted',
    'supervision.action_for_text.mute_client': 'muted',
    'supervision.action_for_text.unmute_client': 'unmuted',
    'supervision.action_for_text.set_limit': 'set custom limit',
    'supervision.action_for_text.unset_limit': 'removed custom limit',
    'supervision.action.create_issue': 'Issue created',
    'supervision.action.comment_issue': 'Issue commented',
    'supervision.action.resolve_issue': 'Issue marked as resolved',
    'supervision.action.close_issue': 'Issue closed',

    'backtest_v2.future_total_return_1m': 'Monthly',
    'backtest_v2.future_total_return_1q': 'Quarterly',
    'backtest_v2.future_total_return_2q': 'Semiannually',
    'backtest_v2.future_total_return_1yr': 'Annually',

    'screener.company.collection': "Collection",
    'screener.company.portfolio': "Portfolio",
    'screener.company.div_yield': "Div. Yield",
    'screener.company.sscore_plus': "Equity Rating",
    'screener.company.macro_score_1q': "Macro Score 1Q",
    'screener.company.macro_score_1yr': "Macro Score 1Y",
    'screener.company.z_score': "ZScore",
    'screener.company.cm_rank_er': "CM Rank, ER",
    'screener.company.comp_rating_ibd': "Comp. Rating, IBD",
    'screener.company.zacks_rank_zks': "Zacks Rank",
    'screener.company.country': "Country",
    'screener.company.sector': "Sector",
    'screener.company.size': "Size",
    'screener.company.vbg_style': "Style",
    'screener.company.timeliness_rank_vl': "Timeliness Rank, VL",
    'screener.company.total_return_1m': "1M Return, %",
    'screener.company.total_return_1yr': "1Y Return, %",
    'screener.company.total_return_ytd': "YTD Return, %",
    'screener.fund.collection': "Collection",
    'screener.fund.portfolio': "Portfolio",
    'screener.fund.entity': "Fund Type",
    'screener.fund.div_yield': "Distribution Rate",
    'screener.fund.expense_ratio': "Expense Ratio",
    'screener.fund.risk_score': "Risk Score",
    'screener.fund.sscore_plus': "Equity Rating",
    'screener.fund.global_broad_category_group': "Broad Category",
    'screener.fund.global_category': "Category",
    'screener.fund.ms_institutional_category': "Institutional Category",
    'screener.fund.total_return_1m': "1M Return, %",
    'screener.fund.total_return_1yr': "1Y Return, %",
    'screener.fund.total_return_ytd': "YTD Return, %",
    'screener.fund.asset_class': "Asset Class",
    'screener.fund.sector': "Sector",
    'screener.fund.size': "Size",
    'screener.fund.style': "Style",
    'screener.fund.fi_credit_rating': "Credit Rating",
    'screener.fund.fi_maturity': "Maturity Date",
    'screener.fund.fi_sector': "FI Sector",

    'screener.portfolio.collection': "Collection",
    'screener.portfolio.provider': "Provider",
    'screener.portfolio.div_yield': "Distribution Rate",
    'screener.portfolio.expense_ratio': "Expense Ratio",
    'screener.portfolio.risk_score': "Risk Score",
    'screener.portfolio.sscore_plus': "Equity Rating",
    'screener.portfolio.asset_class': "Asset Class",
    'screener.portfolio.size': "Size",
    'screener.portfolio.style': "Style",
    'screener.portfolio.stocks_weight': "Stocks Weight",
    'screener.portfolio.bonds_weight': "Bonds Weight",

    'screener.portfolio.provider.BlackRock': "BlackRock",
    'screener.portfolio.provider.JPMorgan': "JPMorgan",
    'screener.portfolio.provider.Signet': "SignetFM",
    'screener.portfolio.provider.GoldmanSachs': "Goldman Sachs",

    // 'isector.Healthcare': 'Healthcare',
    // 'isector.Financial Services': 'Financial Services',
    // 'isector.Technology': 'Technology',
    // 'isector.Consumer Defensive': 'Consumer Defensive',
    // 'isector.Real Estate': 'Real Estate',
    // 'isector.Industrials': 'Industrials',
    // 'isector.Energy': 'Energy',
    // 'isector.Basic Materials': 'Basic Materials',
    // 'isector.Utilities': 'Utilities',
    // 'isector.Consumer Cyclical': 'Consumer Cyclical',
    // 'isector.Communication Services': 'Communication Services',
    // 'isector.Health Care': 'Healthcare',
    // 'isector.Financials': 'Financial Services',
    // 'isector.Information Technology': 'Technology',
    // 'isector.Consumer Staples': 'Consumer Defensive',
    // 'isector.Materials': 'Basic Materials',
    // 'isector.Consumer Discretionary': 'Consumer Cyclical',
}

export const capitalize = s => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

export default class Localizator {

    static labelMap;

    static t(label) {
        if (defaultLocalizations.hasOwnProperty(label)) {
            return defaultLocalizations[label]
        } else {
            return '' + label
        }
    }
}
