/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import '@app/AppSpas.less';
import PortfolioCreator from "@components/portfolio/new/PortfolioCreator";
import {Button, Col, message, Modal, Row, Spin, Typography} from "antd";
import {
    cancel,
    clone,
    createFromFile,
    fromOrion,
    getModels,
    getPortfolioExcelWithFactors,
    getSnapshot,
    getSupervisionSnapshot
} from "@API/portfolio";
import {PortfolioListContext} from "@components/portfolio/contexts/PortfolioListContext";
import PortfolioSelector from "@components/portfolio/selector/PortfolioSelector";
import AssetClassPie from "@components/chart/AssetClassPie";
import '@root/components/portfolio/Portfolio.less';
import PortfolioStatsMini from "@components/portfolio/PortfolioStatsMini";
import FactorAnalysisTable from "@components/portfolio/factor/FactorAnalysisTable";
import PlaygroundPresetCreator from "@components/playground/PlaygroundPresetCreator";
import PlaygroundPresetSelector from "@components/playground/PlaygroundPresetSelector";
import PlaygroundStockAnalysis from "@components/playground/PlaygroundStockAnalysis";
import PlaygroundFixedIncomeAnalysis from "@components/playground/PlaygroundFixedIncomeAnalysis";
import {useSearchParams} from "react-router-dom";
import {downloadFile} from '@global/FileDownloader';
import ReportSettingsModal
    from "@holistico/proposal/view/report/ReportSettingsModal";

// const KEYS_TO_PARAMS = ['definition_id', 'supervision_id']
const {Title, Paragraph} = Typography;
const Playground = () => {
    const portfolio_type = 'temporal'
    const [searchParams, setSearchParams] = useSearchParams();
    const [showAddPortfolio, setShowAddPortfolio] = useState(false);
    const [showGenerateReport, setShowGenerateReport] = useState(false);
    const [columnNames, setColumnNames] = useState(["global_category", "asset_class_alloc", "div_yield", "expense_ratio"])
    const [loading, setLoading] = useState(true)
    const [benchmarks, setBenchmarks] = useState([]);
    const [selectedPortfolio, setSelectedPortfolio] = useState({
        name: 'Click to select main portfolio', calculated_values: {}
    })
    const [selectedBenchmark, setSelectedBenchmark] = useState({
        name: 'Click to select the benchmark', calculated_values: {}
    })
    const abortController = new AbortController();

    const [portfolioPositions, setPortfolioPositions] = useState([])
    // const [benchmarkPositions, setBenchmarkPositions] = useState([])

    useEffect(() => {
        //positions should be loaded separately with given columns
        if (selectedPortfolio._id) {
            fetchMainPortfolioData(selectedPortfolio._id)
        }
    }, [selectedPortfolio]);

    useEffect(() => {
        if (selectedPortfolio._id) {
            fetchMainPortfolioData(selectedPortfolio._id)
        }
    }, [columnNames]);

    useEffect(() => {
        let params = Object.fromEntries(searchParams)
        console.debug(params)
        if ('main_definition_id' in params) {
            fetchMainPortfolioData(params['main_definition_id'], () => {
                fetchModelData()
            })
        } else if ('main_supervision_id' in params && !selectedPortfolio?._id) {
            fetchMainPortfolioDataFromSupervision(params['main_supervision_id'], (supervisionPortfolio) => {
                fetchModelData(supervisionPortfolio?.calculated_values?.risk?.risk_score)
            })
        } else {
            fetchModelData()
        }

        return () => {
            abortController.abort();
        };

    }, []);

    const updateColumns = (newColumns) => {
        console.log('updating table columns to ' + newColumns)
        setColumnNames(newColumns)
    }

    const applyPreset = (preset) => {
        console.log('applying the preset ' + JSON.stringify(preset))
        fetchMainPortfolioData(preset['main_definition_id'], () => {
            fetchBenchmarkData(preset['benchmark_definition_id'])
        })
    }

    const fetchModelData = (mainRisk = null) => {
        getModels(true, null, (data, err) => {
            setLoading(false)
            if (!err) {
                setBenchmarks(data)
                if (mainRisk) {
                    let benchmark_to_use = data[0]
                    console.info('main risk is ' + mainRisk + ', setting default benchmark automatically. ')
                    let best_diff = 100
                    for (let benchmark of data) {
                        let bench_risk = benchmark?.calculated_values?.risk?.risk_score
                        let diff = Math.abs(mainRisk - bench_risk)
                        if (diff < best_diff) {
                            best_diff = diff
                            benchmark_to_use = benchmark
                        }
                    }
                    console.info('setting default benchmark to ' + benchmark_to_use.name)
                    setSelectedBenchmark(benchmark_to_use)
                } else {
                    console.warn('no default benchmark is being set!')
                }
            } else {
                if(!abortController.signal.aborted) {
                    message.error('Failed to load SPAS models!')
                }
            }
        }, abortController.signal);
    }

    const fetchBenchmarkData = (definition_id) => {
        if (!definition_id) {
            return
        }
        setLoading(true)
        getSnapshot([definition_id], [], (result, error) => {
            if (!error) {
                if (result._id != selectedBenchmark?._id) {
                    setSelectedBenchmark(result)
                }
            } else {
                message.error('Error loading benchmark data!');
            }
            setLoading(false)
        })
    }

    const fetchMainPortfolioDataFromSupervision = (spv_id, callback) => {
        setLoading(true)
        getSupervisionSnapshot(spv_id, columnNames, (result, error) => {
            if (!error) {
                result.spv_id = spv_id
                setSelectedPortfolio(result)
                setPortfolioPositions(result.positions)
            } else {
                message.error('Error loading supervision portfolio data!');
            }
            if (callback) {
                callback(result)
            } else {
                setLoading(false)
            }
        })
    }
    const fetchMainPortfolioData = (definition_id, callback) => {
        if (!definition_id) {
            if (callback) callback()
            return
        }
        setLoading(true)
        getSnapshot([definition_id], columnNames, (result, error) => {
            if (!error) {
                if (result._id != selectedPortfolio?._id) {
                    setSelectedPortfolio(result)
                } else {
                    setPortfolioPositions(result.positions)
                }
            } else {
                message.error('Error loading portfolio data!');
            }
            if (callback) {
                callback(result)
            } else {
                setLoading(false)
            }
        })
    }

    const hideAddPortfolio = () => {
        setShowAddPortfolio(false);
    }

    const hideAddPortfolioAndLoad = () => {
        setLoading(true)
        setShowAddPortfolio(false);
    }

    const addFromFile = (formData, callback) => {
        hideAddPortfolioAndLoad()
        createFromFile(formData, (result, error) => {
            if (!error) {
                fetchMainPortfolioData(result.id)
            } else {
                setLoading(false)
                message.error('Error loading portfolio from file!');
            }

            if (callback) {
                callback()
            }
        })
    }

    const addEmpty = (postData, callback) => {
        message.error("Empty portfolio is not useful in playground regime");
        callback();
    }


    const addFromOrion = (orion_id, callback) => {
        hideAddPortfolioAndLoad()
        fromOrion(orion_id, portfolio_type, (result, error) => {
            if (!error) {
                fetchMainPortfolioData(result.id)
            } else {
                setLoading(false)
                message.error('Loading from Orion failed!');
            }
            if (callback) {
                callback();
            }
        })
    }

    //we do nothing with weight here
    const addFromOther = (id, expand_portfolio_positions = false, weight, callback = () => {
    }) => {
        hideAddPortfolioAndLoad()
        clone(id, portfolio_type, {}, expand_portfolio_positions, (result, error) => {
            if (!error) {
                fetchMainPortfolioData(result.id)
            } else {
                setLoading(false)
                message.error('' + error);
            }
            if (callback) {
                callback()
            }
        })
    }

    const getMainPortfolios = () => {
        if (selectedPortfolio._id) {
            return {
                name: selectedPortfolio.name,
                main_ids: selectedPortfolio._id.split(','),
                main_calculated_values: selectedPortfolio.calculated_values
            }
        } else {
            return null
        }
    }

    const getBenchmarkPortfolios = () => {
        if (selectedBenchmark._id) {
            return {
                name: selectedBenchmark.name,
                benchmark_ids: selectedBenchmark._id.split(','),
                benchmark_calculated_values: selectedBenchmark.calculated_values
            }
        } else {
            return null
        }
    }

    const mainPortfolioSelected = (portfolio) => {
        setSelectedPortfolio(portfolio)
        setSearchParams({main_definition_id: portfolio._id})
    }

    const downloadFactorsExcel = (additionalColumns, callback) => {
        getPortfolioExcelWithFactors([selectedPortfolio._id], additionalColumns, (response, error) => {
            if (error) {
                message.error("Error while downloading excel");
                callback();
                return
            }
            let file_type = response.headers['content-type'];
            let file_name = `Analyzed ${selectedPortfolio.name} (${new Date().toLocaleString()}).xlsx`;
            downloadFile(response.data, file_name, file_type, () => {
                message.success("Factors excel was downloaded successfully");
                callback();
            })
        })
    }

    return (<>
        <Typography.Title level={3}>Playground</Typography.Title>
        <Spin style={{height: 800}} spinning={loading} tip="Preparing your playground..." size={"large"}>
            <Button
                style={{marginRight: 12}}
                type="primary"
                onClick={() => setShowAddPortfolio(true)}>Add portfolio</Button>
            <PlaygroundPresetSelector presetSelected={applyPreset}/>
            {selectedPortfolio?._id &&
            <PlaygroundPresetCreator presetSaved={() => null} benchmarkPortfolio={selectedBenchmark}
                                     mainPortfolio={selectedPortfolio}/>}
            {selectedPortfolio?._id && <Button style={{marginLeft: 30}}
                                               onClick={() => setShowGenerateReport(true)}>Generate report</Button>}
            <div className={'portfolio-analysis'}>
                <Row gutter={12}>
                    <Col span={12}>
                        <Title level={5}>Portfolio</Title>
                        <PortfolioSelector selected={selectedPortfolio} portfolios={benchmarks}
                                           setSelectedPortfolio={mainPortfolioSelected} showMarketValue={true}/>
                    </Col>
                    <Col span={12}>
                        <Title level={5}>Benchmark</Title>
                        <PortfolioSelector selected={selectedBenchmark} portfolios={benchmarks}
                                           setSelectedPortfolio={setSelectedBenchmark} showMarketValue={false}/>
                    </Col>
                </Row>
                {selectedPortfolio?.calculated_values?.market_value ? <div>
                    <Row>
                        <Col span={12}>
                            <PortfolioStatsMini calculated_values={selectedPortfolio?.calculated_values}/>
                        </Col>
                        <Col span={12}>
                            <PortfolioStatsMini calculated_values={selectedBenchmark?.calculated_values}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Title level={3}>Asset class</Title>
                            <Paragraph>The extent to which you choose to employ asset allocation as a means of
                                diversification is
                                going to be an individual decision that is guided by your personal investment goals and
                                your risk
                                tolerance. If you're very risk-averse, then you may want to invest only in relatively
                                safe asset
                                classes. You may aim to diversify within an asset class. Stock investors commonly
                                diversify by
                                holding a selection of large-cap, mid-cap, and small-cap stocks. Alternately, they may
                                seek
                                diversification through investing in unrelated market sectors.</Paragraph>
                        </Col>
                        <Col span={12}>
                            <AssetClassPie loading={loading}
                                           allocation={selectedPortfolio.calculated_values['asset_class']}/>
                        </Col>
                        <Col span={12}>
                            <AssetClassPie loading={loading}
                                           allocation={selectedBenchmark?.calculated_values['asset_class']}/>
                        </Col>
                    </Row>
                    <PlaygroundStockAnalysis selectedBenchmark={selectedBenchmark}
                                             selectedPortfolio={selectedPortfolio} loading={loading}/>
                    <PlaygroundFixedIncomeAnalysis selectedBenchmark={selectedBenchmark}
                                                   selectedPortfolio={selectedPortfolio} loading={loading}/>
                    <Row style={{marginTop: "35px"}}>
                        <Col span={24}>
                            <Title level={3}>{selectedPortfolio.name} positions</Title>
                        </Col>
                        <Col span={24}>
                            <FactorAnalysisTable positions={portfolioPositions} loading={loading}
                                                 reloadPositions={updateColumns} downloadFactors={downloadFactorsExcel}/>
                        </Col>

                    </Row>
                </div> : null}
                <PortfolioListContext.Provider
                    value={{
                        addFromOther: addFromOther,
                        addFromOrion: addFromOrion,
                        reloadContext: () => fetchMainPortfolioData(),
                        addEmpty: addEmpty,
                        addFromFile: addFromFile
                    }}>
                    <Modal
                        title={<Typography.Title>Report generation</Typography.Title>}
                        className={'portfolio-select-modal'}
                        open={showAddPortfolio}
                        width={750}
                        closable={false}
                        maskClosable={true}
                        onCancel={v => setShowAddPortfolio(false)}
                        footer={[]}
                    >
                        <PortfolioCreator hideForm={hideAddPortfolio} type={portfolio_type}/>
                    </Modal>
                    <ReportSettingsModal
                        open={showGenerateReport}
                        onCancel={() => setShowGenerateReport(false)}
                        reportClass={"playground"}
                        idsMain={selectedPortfolio?._id?.split(',')}
                        idsProposed={selectedBenchmark?._id?.split(',')}
                        cvMain={selectedPortfolio.calculated_values}
                        cvProposed={selectedBenchmark.calculated_values}
                        getBenchmarkPortfolios={() => (idsProposed)}
                        collapseSections={true}
                        collapseOptions={false}
                    />

                </PortfolioListContext.Provider>
            </div>
        </Spin>
    </>);
}

export default Playground;
