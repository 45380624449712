import React from "react";
import {Alert, Flex} from "antd";
import PlaidUpdateLink
    from "@components/retail/portfolio/plaid/PlaidUpdateLink";


const ERROR_PROPS = {
    ITEM_LOGIN_REQUIRED: {
        title: "sync error",
        description:
            "We were unable to update your accounts because of incorrect" +
            " credentials. This error may appear due to a changed password" +
            " or expired multi-factor authentication. Update credentials" +
            " to resume syncing.",
        showUpdateLink: true,
        updateLinkText: "Update credentials"

    },
    ACCESS_NOT_GRANTED: {
        title: "sync error",
        description: "Insufficient Sharing Permissions: There was an error" +
            " connecting to your account. Try linking your account again" +
            " by selecting the required information to share.",
        showUpdateLink: true,
        updateLinkText: "Update link"
    },
};

export const ERRORS_TO_SHOW = Object.keys(ERROR_PROPS);


const PlaidAlert = ({clientId, item}) => {
    const error_props = ERROR_PROPS[item.error.code];

    return (ERRORS_TO_SHOW.includes(item.error.code)
            ? <Alert
                type={"error"}
                showIcon={true}
                message={`${item.error.institution_name} sync error`}
                description={
                    <Flex vertical gap={"middle"} align={"flex-start"}>
                        {error_props.description}
                        {error_props.showUpdateLink
                            && <PlaidUpdateLink
                                clientId={clientId}
                                itemId={item.item_id}
                                buttonText={error_props.updateLinkText}
                            />
                        }
                    </Flex>
                }
            />
            : <></>
    )
}

export default PlaidAlert;
