import React, {useEffect, useState} from "react";
import {
    ConfigProvider,
    Form,
    Input,
    message,
    Modal, theme,
} from "antd";
import { v4 as uuidv4 } from 'uuid';
import {createReportPreset} from "@API/report";


const {useToken} = theme;


const CreateTemplateModal = ({
                                 open,
                                 onCancel,
                                 onSave,
                                 preset
                             }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const {token} = useToken();

    const onFinish = (values) => {
        setLoading(true);
        const key = values["title"].toLowerCase().replaceAll(" ", "_") + "_" + uuidv4();
        const _preset = {
            key:  key,
            title: values["title"],
            description: values["description"],
            type: "user",
            report_class: preset.report_class === "enrollment" ? "client" : preset.report_class,
            version: '2.0',
            sectionsOrder: preset.sectionsOrder,
            selectedSections:  preset.selectedSections,
            sectionsFilter: preset.sectionsFilter,
            options: preset.options,
        };
        createReportPreset(_preset, (data, error) => {
            if (error) {
                message.success("Error while saving template!");
            } else {
                message.success("Template was saved successfully!");
            }
            onSave(key);
            setLoading(false);
        })
    }

    useEffect(() => {
        form.setFieldsValue({
            title: preset.title + ' (copy)',
            description: preset.description
        });
    }, [preset])

    return <ConfigProvider theme={{
        components: {
            Modal: {contentBg: token.colorBgContainer}
        }
    }}>
        <Modal
            open={open}
            title={null}
            closable={false}
            onOk={form.submit}
            onCancel={onCancel}
            okText="Save template"
            okButtonProps={{loading: loading}}
        >
            <Form
                form={form}
                onFinish={onFinish}
                autoComplete="off"
                layout="vertical"
                requiredMark={false}
            >
                <Form.Item
                    name="title"
                    label="Template name"
                    requiredMark={false}
                    rules={[{
                        required: true,
                        message: 'Enter template name',
                    }]}
                >
                    <Input placeholder="Name"/>
                </Form.Item>
                <Form.Item
                    name="description"
                    label="Description"
                    required={false}
                >
                    <Input.TextArea placeholder="Description"/>
                </Form.Item>
            </Form>
        </Modal>
    </ConfigProvider>


}

export default CreateTemplateModal;
