import React, {useEffect, useRef, useState} from "react";
import {
    Typography,
    Button,
    Card,
    Row,
    Col,
    Divider,
    Flex,
    Form,
    InputNumber,
    Rate,
    theme, Tooltip, Segmented, Tour,
} from "antd";
import {
    CompassFilled,
    HeartOutlined,
} from "@ant-design/icons";
import ValueFormatter, {is_null} from "@global/ValueFormatter";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import {
    getRiskScoreFromQuestionnaire
} from "@holistico/proposal/view/client/questionnaire/Questionnaire";
import {
    getQuizTotal, getRiskScoreByQuizScore
} from "@holistico/proposal/view/client/behavioral_quiz/BehavioralQuiz";
import BehavioralQuizForm
    from "@holistico/proposal/view/client/behavioral_quiz/BehavioralQuizForm";
import StatsCard, {
    StatsCardButton,
    StatsCardDescription
} from "@components/retail/cards/StatsCard";
import MobileDrawer from "@components/retail/cards/MobileDrawer";
import CardHeader from "@components/retail/cards/CardHeader";
import {useSize} from "ahooks";
import {
    getToleranceLevelByRiskScore
} from "@holistico/proposal/view/client/utils";
import {useAuth} from "@hooks/AuthHook";
import TourButton from "@components/retail/cards/TourButton";
import moment from "moment";


const {useToken} = theme;


const QuestionnaireFormItems = ({}) => {
    const currentYear = moment().year();

    return <Row gutter={[16, 0]}>
        <Col lg={12} md={24} sm={24} xs={24}>
            <Form.Item
                label="Birth year"
                name={["questionnaire", "birth_year"]}
                rules={[
                    {
                        // Without custom validator it works weird
                        warningOnly: true,
                        validator: (_, value) => {
                            if (!value) {
                                return Promise.reject(new Error("Required for the risk ability estimation"));
                            }
                            if (value < 1900 || value > currentYear) {
                                return Promise.reject(new Error(`Enter value be between 1900 and ${currentYear}`));
                            }
                            return Promise.resolve();
                        }
                    },
                ]}
            >
                <InputNumber placeholder={"Year"}/>
            </Form.Item>
        </Col>
        <Col lg={12} md={24} sm={24} xs={24}>
            <Form.Item
                label="Health status"
                name={["questionnaire", "hstatus"]}
                rules={[
                    {
                        // Without custom validator it works weird
                        warningOnly: true,
                        validator: (_, value) =>
                            value
                                ?
                                Promise.resolve()
                                :
                                Promise.reject(new Error("Required for the risk ability estimation")),
                    },
                ]}
            >
                <Rate
                    character={<HeartOutlined/>}
                    style={{fontSize: 24, color: '#F86767FF'}}
                    defaultValue={null}
                />
            </Form.Item>
        </Col>
        <Col lg={12} md={24} sm={24} xs={24}>
            <Form.Item
                label="Investment horizon"
                name={["questionnaire", "investment_time_horizon"]}
            >
                <Segmented
                    options={[
                        {label: "10 years or more", value: "long"},
                        {label: "6 - 9 years", value: "intermediate"},
                        {label: "5 years or less", value: "short"},
                    ]}
                    defaultValue={null}
                />
            </Form.Item>
        </Col>
        <Col lg={12} md={24} sm={24} xs={24}>
            <Form.Item
                label="Non-investment income"
                name={["questionnaire", "non_investment_income"]}
            >
                <Segmented
                    options={[
                        {label: "Above spending", value: "above_spending"},
                        {label: "Below spending", value: "below_spending"},
                    ]}
                    defaultValue={null}
                />
            </Form.Item>
        </Col>
        <Col lg={12} md={24} sm={24} xs={24}>
            <Form.Item
                label="Working status"
                name={["questionnaire", "wstatus"]}
            >
                <Segmented
                    options={[
                        {label: "Working", value: "working"},
                        {label: "Sabbatical", value: "not_working"},
                        {label: "Retired", value: "retired"},
                    ]}
                    defaultValue={null}
                />
            </Form.Item>
        </Col>
        <Col lg={12} md={24} sm={24} xs={24}>
            <Form.Item
                label="Marital status"
                name={["questionnaire", "mstatus"]}
            >
                 <Segmented
                    options={[
                        {label: "Married", value: "married"},
                        {label: "Single", value: "single"},
                        {label: "Divorced", value: "divorced"},
                    ]}
                    defaultValue={null}
                />
            </Form.Item>
        </Col>
    </Row>
}


const QuestionnaireForm = ({setOpen}) => {
    const {proposal, updateQuestionnaire} = useHolisticoProposalContext();
    const [form] = Form.useForm();
    const {sendGAEvent} = useAuth();

    useEffect(() => {
        form.setFieldsValue(proposal);
    }, [proposal])

    const onFinish = (values) => {
        console.log("Submit", values);
        sendGAEvent({
            category: 'Risk Profile',
            action: 'Click',
            label: 'Finish questionnaire'
        })
        const riskScore = getRiskScoreFromQuestionnaire(values);
        if (riskScore) {
            values["questionnaire"]["risk_profile"] = {"ability": riskScore};
        }
        setOpen(false);
        updateQuestionnaire(values["questionnaire"], true);
    };

    return <Form
        form={form}
        onFinish={onFinish}
        layout={"vertical"}
        onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
    >
        <Typography.Title level={4} style={{margin: 0}}>
            Risk ability questionnaire
        </Typography.Title>
        <Divider/>
        <QuestionnaireFormItems/>
        <Divider/>
        <Flex justify={"flex-start"} align={"center"} gap={"small"}>
            <Button htmlType="button" onClick={() => setOpen(false)}
                    style={{width: 180}}>
                Cancel
            </Button>
            <Button htmlType="submit" type={"primary"} style={{width: 180}}>
                Save
            </Button>
        </Flex>
    </Form>
}


const QuizForm = ({setOpen}) => {
    const { updateQuestionnaire } = useHolisticoProposalContext();
    const [saving, setSaving] = useState(false);
    const {sendGAEvent} = useAuth();

    const submitQuizResults = (values) => {
        sendGAEvent({
            category: 'Risk Profile',
            action: 'Click',
            label: 'Finish quiz'
        })
        if (!values) {
            return
        }
        const total = getQuizTotal(values["answers"])
        const riskScore = getRiskScoreByQuizScore(total)
        const questionnaire = {"behavioral_quiz": {...values, total: total}}
        if (riskScore) {
            questionnaire["risk_profile"] = {"behavioral": riskScore}
        }
        setSaving(true);
        updateQuestionnaire(questionnaire, true, true, () => {
            setSaving(false);
            if (riskScore) {
                setOpen(false);
            }
        })
    }

    return <>
        <Typography.Title level={4} style={{margin: 0}}>
            Risk tolerance quiz
        </Typography.Title>
        <Divider/>
        <BehavioralQuizForm onFinish={submitQuizResults} saving={saving}/>
    </>
}


export const QuestionnaireDrawer = ({open, setOpen}) => {
    return <MobileDrawer open={open} onClose={() => setOpen(false)}>
        <Card>
            <QuestionnaireForm setOpen={setOpen}/>
        </Card>
    </MobileDrawer>
}


export const QuizDrawer = ({open, setOpen}) => {
    const {token} = useToken();

    return <MobileDrawer open={open} onClose={() => setOpen(false)}>
        <Card>
            <QuizForm setOpen={setOpen}/>
        </Card>
    </MobileDrawer>
}


const RiskStatsCard = ({title, score, vertical, tooltip=null, id, children}) => {
    const {token} = useToken();

    const level = is_null(score) ? "-" : getToleranceLevelByRiskScore(score);

    return <StatsCard
        tooltip={tooltip}
        value={<Flex gap={"small"} vertical style={{
            minHeight: vertical ? "auto" : 80,
        }}
        >
            <Typography.Text style={{
                fontSize: token.fontSizeXS,
                textTransform: "uppercase",
                lineHeight: 1.2,
                letterSpacing: 1,
            }}>
                {title}
            </Typography.Text>
            <Typography.Text style={{
                fontSize: token.fontSizeHeading2,
                color: token.colorStats,
                lineHeight: 1,
            }}>
                {is_null(score) ? "-" : ValueFormatter.int_number(score)}
            </Typography.Text>
            {!is_null(score)
                && <Typography.Paragraph ellipsis style={{
                    fontSize: token.fontSize,
                    textTransform: "uppercase",
                    lineHeight: 1,
                    color: token.colorStats,
                    margin: 0,
                }}>
                    {level}
                </Typography.Paragraph>
            }
        </Flex>}
        title={null}
        style={vertical ? null : {maxWidth: "35%"}}
        id={id}
    >
        {children}
    </StatsCard>
}


const RiskProfileCardTour = ({}) => {
    const steps = [
        {
            title: "Current portfolio risk",
            target: "risk-profile-card-portfolio",
        },
        {
            title: "Your risk ability calculated on the questionnaire",
            target: "risk-profile-card-ability",
        },
        {
            title: "Click to fill the questionnaire and get your risk ability",
            target: "risk-profile-card-ability-questionnaire",
        },
        {
            title: "Your risk tolerance calculated on the quiz results",
            target: "risk-profile-card-tolerance",
        },
        {
            title: "Click to take the quiz and get your risk tolerance",
            target: "risk-profile-card-tolerance-quiz",
        },
    ];
    return <TourButton steps={steps}/>
}


const RiskProfileCard = ({}) => {
    const {sendGAEvent} = useAuth();
    const {proposal} = useHolisticoProposalContext();
    const [openQuestionnaire, setOpenQuestionnaire] = useState(false);
    const [openQuiz, setOpenQuiz] = useState(false);
    const {token} = useToken();
    const ref = useRef(null);
    const size = useSize(ref);
    const vertical = size?.width < 540;

    const portfolioScore = proposal?.p_bucket_cur?.calculated_values?.risk?.risk_score;
    const abilityScore = proposal?.questionnaire?.risk_profile?.ability;
    const toleranceScore = proposal?.questionnaire?.risk_profile?.behavioral;

    return <Card hoverable ref={ref} id={"risk-profile-card"} style={{
        borderColor: token.colorBorderStrong,
        cursor: "default"
    }}>
        <CardHeader
            title="Risk profile"
            icon={<CompassFilled />}
            tour={<RiskProfileCardTour/>}
            id={"risk-profile-card-header"}
        />
        <Flex gap="small" align={"stretch"} vertical={vertical}>
            <RiskStatsCard
                title={"Portfolio risk"}
                score={portfolioScore}
                vertical={vertical}
                // tooltip={
                //     "Portfolio risk score measures your portfolio risk"
                //     + " — from 0 (no risk) to 99 (extremely high risk)."
                // }
                id={"risk-profile-card-portfolio"}
            >
                <StatsCardDescription>
                    Measures your portfolio risk level — from 0 (no risk)
                    to 99 (extremely high risk).
                </StatsCardDescription>
            </RiskStatsCard>
             <RiskStatsCard
                title={"Risk ability"}
                score={abilityScore}
                vertical={vertical}
                id={"risk-profile-card-ability"}
            >
                 <StatsCardDescription>
                    Reflects your financial ability to withstand declines in the
                    portfolio value.
                    Based on the investment profile questionnaire.
                </StatsCardDescription>
                <StatsCardButton
                    onClick={() => {
                        setOpenQuestionnaire(true);
                        sendGAEvent({
                            category: 'Risk Profile',
                            action: 'Click',
                            label: 'Start questionnaire'
                        })
                    }}
                    id={"risk-profile-card-ability-questionnaire"}
                >
                    {abilityScore ? "Open questionnaire" : "Fill questionnaire"}
                </StatsCardButton>
            </RiskStatsCard>
            <RiskStatsCard
                title={"Risk tolerance"}
                score={toleranceScore}
                vertical={vertical}
                id={"risk-profile-card-tolerance"}
            >
                <StatsCardDescription>
                    Measures your subconsciousness tolerance to risk.
                    Based on the behavioral quiz.
                </StatsCardDescription>
                <StatsCardButton
                    onClick={() => {
                        setOpenQuiz(true);
                        sendGAEvent({
                            category: 'Risk Profile',
                            action: 'Click',
                            label: 'Start quiz'
                        })
                    }}
                    id={"risk-profile-card-tolerance-quiz"}
                >
                    {toleranceScore ? "Open quiz" : "Take quiz"}
                </StatsCardButton>
            </RiskStatsCard>
        </Flex>
        <QuestionnaireDrawer
            open={openQuestionnaire}
            setOpen={setOpenQuestionnaire}
        />
        <QuizDrawer
            open={openQuiz}
            setOpen={setOpenQuiz}
        />
    </Card>
}


export default RiskProfileCard;
