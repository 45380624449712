import {formatterMapper} from "@components/supervision/report_constructor/tree_utils/utils";


const flagRow = (item) => {
    const metricFormatter = formatterMapper(item.metric.units);
    const metricValue = metricFormatter(item.metric.value, item.metric.decimals_precision);
    const globalLimitValue = metricFormatter(item.limit.global, item.metric.decimals_precision);
    const clientLimitValue = metricFormatter(item.limit.client, item.metric.decimals_precision);

    return  "    - " + item.red_flag_name + " warning. "
        + item.metric.name + " (" + metricValue + ") "
        + (item.metric.low_is_good ? "exceeds the upper" : "falls below the lower")
        + " limit (" + (item.limit.type === "client" ? clientLimitValue : globalLimitValue) + ")."
}


const mailtoSupervision = (clientInfo, represetativeInfo, flagInfo) => {
    const br = "%0D%0A";
    const represetativeShortName = (represetativeInfo.name ?? "").split(" ")[0];
    const clientName = clientInfo.client_name.replaceAll("&", "and");
    const synopsis = `Please, pay attention to ${clientName}. `
        + `Below is the list of warnings:`;

    let flagsBody = "";
    if (flagInfo instanceof Array) {
        flagInfo.filter(flag => flag.red_flag_raised).map(flag => {
            console.log("item", flag)
            flagsBody += flagRow(flag) + br;
        })
    } else if (flagInfo instanceof Object) {
        flagsBody = flagRow(flagInfo) + br;
    }

    const end = `Please, get back to me when you figure this out.`;
    const body = `Hi ${represetativeShortName}`
        + `${br}${br}${synopsis}`
        + `${br}${br}${flagsBody}`
        + `${br}`;

    return `mailto:${represetativeInfo.email}`
        +`?subject=Compliance risk warning, ${clientName}`
        +`&body=` + body;
}

export {mailtoSupervision}
