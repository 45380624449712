import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {
    Col,
    Divider,
    List,
    message,
    Row,
    Spin,
    theme,
} from 'antd';
import {getClientReport} from "@API/supervision";
import {SupervisionContext} from "../contexts/SupervisionContext";
import ActionLog from "@components/supervision/client/ActionLog";
import NoDataPlaceholder from "@holistico/aux/NoDataPlaceholder";
import ClientReportHeader
    from "@components/supervision/client/ClientReportHeader";
import ClientFlagCard from "@components/supervision/client/ClientFlagCard";
import {useSize} from "ahooks";


const {useToken} = theme;


const ClientReportContent = ({config, useClientConfig, configsDetails}) => {
    const {clientId} = useParams();
    const [reportLoading, setReportLoading] = useState(false);
    const [clientReport, setClientReport] = useState(null);
    const ref = useRef(null);
    const size = useSize(ref);
    const abortControllerRef = useRef(null);
    const {token} = useToken();

    const hideHistory = size && size.width < 800;

    useEffect(() => {
        if (clientId !== null && config !== null && useClientConfig !== null) {
            retrieveClientReport();
        }
        return () => {
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
        }
    }, [clientId, config, useClientConfig])

    const retrieveClientReport = () => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
        abortControllerRef.current = new AbortController();
        const signal = abortControllerRef.current.signal;

        setReportLoading(true);
        getClientReport(config.id, clientId, useClientConfig, (data, error) => {
            setReportLoading(false);
            if (error) {
                message.error("Error while retrieving client report");
            } else {
                setClientReport(data);
                console.log("getClientReport", data)
            }
        }, signal)
    }

    return <Spin
        style={!clientReport ? {height: "300px"} : {}}
        size="large"
        tip="Loading client report..."
        spinning={reportLoading}
    >
        {clientReport
            ? <SupervisionContext.Provider value={{
                updateData: retrieveClientReport,
                configsDetails: configsDetails
            }}>
                <ClientReportHeader client={clientReport} hideHistory={hideHistory}/>
                <Row ref={ref} gutter={[token.marginLG, token.marginLG]}>
                    <Col span={hideHistory ? 24 : 16}>
                        <List
                            dataSource={clientReport.red_flags}
                            renderItem={(item) => {
                                return <List.Item
                                    key={item.red_flag_key}
                                    style={{
                                        border: "none",
                                        paddingTop: 0
                                    }}
                                >
                                    <ClientFlagCard
                                        client={clientReport}
                                        item={item}
                                        configsDetails={configsDetails}
                                    />
                                </List.Item>
                            }}
                        />
                    </Col>
                    <Col span={hideHistory ? 0 : 8}>
                        <ActionLog client={clientReport}/>
                    </Col>
                </Row>
            </SupervisionContext.Provider>
            : <NoDataPlaceholder description={"Client report not found"}/>
        }
    </Spin>
}

export default ClientReportContent;
