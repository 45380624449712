import React, {useRef, useState} from "react";
import {Card, Flex, theme} from "antd";
import {RightOutlined} from "@ant-design/icons";
import {useSize} from "ahooks";


const {useToken} = theme;


const ButtonCard = ({
                        icon,
                        showArrow,
                        onClick,
                        disabled,
                        children,

                    }) => {
    const [hover, setHover] = useState(false);
    const ref = useRef(null);
    const size = useSize(ref);
    const {token} = useToken();

    return <Card
        size={"small"}
        onClick={disabled ? null : onClick}
        ref={ref}
        onMouseLeave={() => setHover(false)}
        onMouseEnter={() => setHover(true)}
        style={disabled
            ? {
                cursor: "not-allowed",
                background: token.colorFillTertiary,
            }
            : {
                cursor: "pointer",
                boxShadow: hover
                    ? token.boxShadow
                    : "0 2px 6px 0 rgba(0, 0, 0, 0.04), 0 3px 8px 0 rgba(0, 0, 0, 0.03)",
            }
        }
    >
        <Flex gap={"middle"} vertical={size?.width < 500}>
            {icon
                && <Flex align={"center"} justify={"center"} style={{
                    background: token.colorFillTertiary,
                    borderRadius: 40,
                    width: 60,
                    height: 60,
                    fontSize: token.fontSizeHeading3,
                    color: token.colorTextDescription,
                    flexShrink: 0
                }}>
                    {icon}
                </Flex>
            }
            <Flex gap={"middle"} style={{flexGrow: 1}}>
                <div style={{flexGrow: 1}}>
                    {children}
                </div>
                {showArrow
                    && <RightOutlined style={{
                        fontSize: token.fontSizeHeading4,
                        color: token.colorTextDescription,
                    }}/>
                }
            </Flex>
        </Flex>
    </Card>
}


export default ButtonCard;
