import React, {useState} from "react";
import moment from "moment/moment";
import {
    DatePicker,
    Form,
    Input,
    message,
    Modal,
    Spin,
    theme,
    Typography
} from 'antd';
import {setFlagStatus} from "@API/supervision";


const {useToken} = theme;


const IssueStatusModal = ({
                              open,
                              setOpen,
                              status = "in_progress",
                              clientId,
                              flagKey,
                              flagName,
                              onFinish
                          }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const {token} = useToken();

    const title = {
        in_progress: "Start progress",
        resolved: "Mark as resolved",
        unaddressed: "Mark as unaddressed"
    }[status];
    const statusLabel = {
        in_progress: "In progress",
        resolved: "Resolved",
        unaddressed: "Unaddressed"
    }[status];

    const setStatus = (values) => {
        setLoading(true);
        let statusData = {
            client_id: clientId,
            flag_key: flagKey,
            status: status,
            until: values.until?.toISOString(),
            comment: values.comment,
        }
        setFlagStatus(statusData, (resp, error) => {
            setLoading(false);
            if (error) {
                message.error(`Error while setting status for ${flagName}`);
            } else {
                message.success(`${flagName} was marked as "${status === "in_progress" ? "In progress" : "Resolved"}"`);
            }
            setOpen(false);
            onFinish();
        })
    }

    return <Modal
        open={open}
        title={title}
        onCancel={() => setOpen(false)}
        maskClosable={true}
        okButtonProps={{autoFocus: true, htmlType: 'submit'}}
        destroyOnClose
        modalRender={(dom) => (
            <Spin spinning={loading}>
                <Form
                    form={form}
                    onFinish={setStatus}
                    labelCol={{span: 5}}
                    wrapperCol={{span: 19}}
                    autoComplete="off"
                    scrollToFirstError
                    layout="horizontal"
                    validateTrigger={'onSubmit'}
                    requiredMark={false}
                    clearOnDestroy
                >
                    {dom}
                </Form>
            </Spin>
        )}
    >
        <Typography.Paragraph style={{marginBottom: token.marginLG}}>
            {flagName} issue will be marked as "{statusLabel}".
        </Typography.Paragraph>
        {status !== "unaddressed"
            && <Form.Item
                name="until"
                label="Until"
                initialValue={moment().add(7, 'day')}
                rules={[{
                    required: true,
                    type: 'object',
                    message: 'Please select the date',
                }]
                }>
                <DatePicker format={"MMM DD, YYYY"}/>
            </Form.Item>
        }
        <Form.Item
            name="comment"
            label="Comment"
            requiredMark={false}
            rules={[
                {
                    required: true,
                    message: 'Please enter your comment',
                },
            ]}
        >
            <Input.TextArea maxLength={300}/>
        </Form.Item>
    </Modal>
}

export default IssueStatusModal;
