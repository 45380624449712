import React, {useEffect, useState} from "react";
import dayjs from "dayjs";
import {
    Alert,
    Button,
    Card,
    ConfigProvider,
    Divider,
    Flex, List,
    message, Modal,
    Popconfirm, Tag,
    theme, Tooltip,
    Typography
} from "antd";
import {
    CalendarOutlined,
    CheckCircleOutlined,
    CreditCardFilled,
    ExclamationCircleFilled,
    FileAddOutlined,
    LinkOutlined,
    PlusCircleOutlined,
    UnorderedListOutlined,
} from "@ant-design/icons";
import {createFromPositions, deletePortfolio} from "@API/clientPortfolio";
import {setName, updatePortfolioFromPositions} from "@API/portfolio";
import ValueFormatter, {is_null} from "@global/ValueFormatter";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import StatsCard from "@components/retail/cards/StatsCard";
import NestedCard from "@components/retail/cards/NestedCard";
import CardHeader from "@components/retail/cards/CardHeader";
import FakeProgress
    from "@components/retail/portfolio/constructor/FakeProgress";
import PortfolioConstructor
    from "@components/retail/portfolio/constructor/PortfolioConstructor";
import MobileDrawer from "@components/retail/cards/MobileDrawer";
import {useParams} from "react-router-dom";
import {useAuth} from "@hooks/AuthHook";
import LockTooltip, {
    RequestLockTooltip
} from "@components/retail/cards/LockTooltip";
import TourButton from "@components/retail/cards/TourButton";
import PlaidCreateLink
    from "@components/retail/portfolio/plaid/PlaidCreateLink";
import PlaidAlert, {
    ERRORS_TO_SHOW
} from "@components/retail/portfolio/plaid/PlaidAlert";
import PlaidSelectAccountsLink
    from "@components/retail/portfolio/plaid/PlaidSelectAccountsLink";
import ButtonCard from "@components/retail/cards/ButtonCard";


const {useToken} = theme;


const AccountTag = ({
                        tooltip = null,
                        icon = null,
                        color = null,
                        style = {},
                        children
                    }) => {
    const {token} = useToken();

    return <Tooltip title={tooltip} mouseEnterDelay={0.5}>
        <Tag
            bordered={false}
            icon={icon}
            style={{
                margin: 0,
                color: token.colorTextSecondary,
                background: token.colorFillQuaternary,
                ...style
            }}
        >
            {children}
        </Tag>
    </Tooltip>
}


export const AccountCard = ({account, id}) => {
    const {requestLockInfo} = useAuth();
    const {
        proposal,
        fetchProposal,
        setLoading
    } = useHolisticoProposalContext();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openViewDrawer, setOpenViewDrawer] = useState(false);
    const [openProgress, setOpenProgress] = useState(false);
    const [refreshTrigger, setRefreshTrigger] = useState(null);
    const [hover, setHover] = useState(false);
    const {token} = useToken();
    const {sendGAEvent} = useAuth();

    const nPositions = (account?.positions?.length ?? 0)
        + (account?.positions?.length === 1 ? " position" : " positions");
    const marketValue = account?.calculated_values?.market_value;
    const lastUpdateStr = account?.positions_last_update ?? account?.calculated_values?.last_update;
    const lastUpdate = dayjs(lastUpdateStr);
    const outdated = dayjs().diff(lastUpdate, 'hours') > 3 * 24;
    const institutionName = account?.auxiliary_data?.plaid_data?.institution_name;
    const plaidItemId = account?.auxiliary_data?.plaid_data?.item_id;
    const linked = !!institutionName;
    const lockInfo = requestLockInfo("#post-update-portfolio-positions");

    const onEditClick = (e) => {
        setOpenDrawer(true);
        sendGAEvent({
            category: 'Accounts',
            action: 'Click',
            label: 'Edit account'
        })
    }

    const onViewClick = (e) => {
        setOpenViewDrawer(true);
        sendGAEvent({
            category: 'Accounts',
            action: 'Click',
            label: 'View account'
        })
    }

    const onDeleteClick = (e) => {
        setOpenProgress(true);
        sendGAEvent({
            category: 'Accounts',
            action: 'Click',
            label: 'Delete account'
        })
        deletePortfolio(proposal._id, 'cur', account._id, (result, error) => {
            if (!error) {
                fetchProposal(true);
            } else {
                message.error("Something went wrong while deleting account!");
            }
            setOpenProgress(false);
        })
    }

    const updateAccount = (_positions, _name, _positionsChanged, _nameChanged) => {
        setTimeout(() => setRefreshTrigger(Math.random()), 500);
        setOpenDrawer(false);
        if (_positionsChanged && _nameChanged) {
            setOpenProgress(true);
            updatePortfolioFromPositions(account._id, _positions, (result, error) => {
                if (!error) {
                    setName(account._id, _name, (result, error) => {
                        if (error) {
                            message.error("Something went wrong while changing account title!")
                        }
                        fetchProposal(true);
                    });
                } else {
                    message.error("Something went wrong while updating the account!");
                    console.error(result);
                }
                setOpenProgress(false);
            })
        }
        if (_positionsChanged && !_nameChanged) {
            setOpenProgress(true);
            updatePortfolioFromPositions(account._id, _positions, (result, error) => {
                if (!error) {
                    fetchProposal(true);
                } else {
                    message.error("Something went wrong while updating the account!");
                    console.error(result);
                }
                setOpenProgress(false);
            })
        }
        if (!_positionsChanged && _nameChanged) {
            setLoading(true);
            setName(account._id, _name, (result, error) => {
                if (error) {
                    message.error("Something went wrong while changing account title!")
                }
                fetchProposal();
            });
        }
        sendGAEvent({
            category: 'Accounts',
            action: 'Click',
            label: 'Update account account'
        })
    }

    return <Flex
        vertical
        style={{
            maxWidth: "100%",
            background: hover ? token.colorBgHoverBlue : token.colorBgGrey,
            // background:  hover ? token.colorBgHoverBlue : "white",
            border: `1px solid ${token.colorFill}`,
            borderRadius: token.borderRadiusLG,
            padding: `${token.padding}px ${token.paddingLG}px ${token.paddingXXS}px ${token.padding}px `,
            flexGrow: 1,
            overflow: "hidden",
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        id={id}
    >
        <Flex justify={"space-between"} gap={"large"} style={{
            marginBottom: token.marginXS
        }}>
            <Typography.Text ellipsis style={{
                lineHeight: 1.2,
                fontWeight: token.fontWeightStrong,
            }}>
                <CheckCircleOutlined style={{
                    fontSize: token.fontSizeLG,
                    marginRight: token.marginSM
                }}/>
                {account.name}
            </Typography.Text>
            <Typography.Text id={`${id}-mkval`} style={{
                lineHeight: 1.2,
                fontWeight: token.fontWeightStrong,
                whiteSpace: "nowrap",
            }}>
                {ValueFormatter.currency(marketValue)}
            </Typography.Text>
        </Flex>
        <Flex gap={"small"} wrap={"wrap"} id={`${id}-tags`}>
            <AccountTag
                icon={<UnorderedListOutlined/>}
                tooltip={`Account contains ${nPositions}`}
            >
                {nPositions}
            </AccountTag>
            {institutionName &&
                <AccountTag
                    icon={<LinkOutlined/>}
                    tooltip={`Linked to my account at ${institutionName}`}
                >
                    {institutionName}
                </AccountTag>
            }
            {lastUpdate &&
                <AccountTag
                    icon={<CalendarOutlined/>}
                    tooltip={`Account composition last updated at ${lastUpdate.format("hh:mm:ss on MMMM D, YYYY")}`}
                >
                    {lastUpdate.format("MMMM D")}
                </AccountTag>
            }
            {!linked &&
                <AccountTag
                    icon={<ExclamationCircleFilled
                        style={{color: token.colorWarning}}/>}
                    tooltip={
                        "Composition of this account will not be updated "
                        + " overnight automatically. To turn automatic update on,"
                        + " you can add this account "
                        + " from your institution via Plaid."
                    }
                >
                    Static
                </AccountTag>
            }
            {!linked && outdated &&
                <AccountTag
                    icon={<ExclamationCircleFilled
                        style={{color: token.colorError}}/>}
                    tooltip={
                        "Composition of this account has not been updated"
                        + ` since ${lastUpdate.format("MMMM D")}.`
                        + " Update the composition manually or add this account"
                        + " from your institution via Plaid so that "
                        + " it be updated automatically."
                    }
                >
                    Outdated
                </AccountTag>
            }
        </Flex>
        <ConfigProvider theme={{
            components: {
                Button: {
                    colorLink: token.colorTextDescription,
                    colorError: token.colorTextDescription,
                    fontSize: token.fontSizeSM,
                    // paddingInline: 0
                }
            }
        }}>
            <Flex gap={"middle"} align={"center"} style={{
                borderTop: `1px ${token.colorSplit} solid`,
                margin: `${token.margin}px -${token.paddingLG}px 0 -${token.padding + token.paddingSM}px`,
                padding: `0 ${token.padding}px`
            }} id={`${id}-actions`}>
                <Popconfirm
                    title={"Are you sure to delete this account?"}
                    okText={"Yes"}
                    cancelText={"No"}
                    onCancel={(e) => {
                    }}
                    onConfirm={onDeleteClick}
                >
                    <Button type={"link"} danger id={`${id}-delete`}>
                        Delete
                    </Button>
                </Popconfirm>
                {!linked && <>
                    <Divider type={"vertical"}
                             style={{height: token.fontSizeLG}}/>
                    <RequestLockTooltip
                        limitMessage={"You exceeded the limit of editing accounts."}
                        lockMessage={"Editing accounts is not available for you current subscription."}
                        lockedUntil={lockInfo.until}
                        locked={lockInfo.locked}
                    >
                        <Button
                            onClick={onEditClick}
                            type={"link"}
                            disabled={lockInfo.locked}
                            id={`${id}-edit`}
                        >
                            Edit
                        </Button>
                    </RequestLockTooltip>
                </>}
                {linked && <>
                     <Divider type={"vertical"}
                             style={{height: token.fontSizeLG}}/>
                    <PlaidSelectAccountsLink
                        clientId={proposal._id}
                        itemId={plaidItemId}
                        institutionName={institutionName}
                    />
                </>}
                <Divider type={"vertical"} style={{height: 16}}/>
                <Button onClick={onViewClick} type={"link"} id={`${id}-view`}>
                    View
                </Button>
            </Flex>
        </ConfigProvider>
        <MobileDrawer
            open={openDrawer}
            onClose={() => {
                setTimeout(() => setRefreshTrigger(Math.random()), 500);
                setOpenDrawer(false);
            }}
            maskClosable={false}
            backButtonText={"Cancel"}
            id={"portfolio-constructor-drawer"}
        >
            <PortfolioConstructor
                portfolio={account}
                allowEditName={true}
                applyButtonText={"Apply changes"}
                hoverable={false}
                onSubmit={updateAccount}
                refreshTrigger={refreshTrigger}
            />
        </MobileDrawer>
        <MobileDrawer
            open={openViewDrawer}
            onClose={() => {
                setOpenViewDrawer(false);
            }}
            maskClosable={false}
            backButtonText={"Back"}
            id={"portfolio-constructor-drawer"}
        >
            <PortfolioConstructor
                portfolio={account}
                allowEditName={true}
                applyButtonText={"Apply changes"}
                hoverable={false}
                onSubmit={updateAccount}
                viewOnly={true}
            />
        </MobileDrawer>
        <FakeProgress
            tip={"Updating portfolio analytics..."}
            open={openProgress}
            time={30000}
        />
    </Flex>
}


export const AddAccountLink = ({
                                   type = "cur",
                                   onClick = null,
                                   buttonType = "primary",
                                   buttonText = "Add account",
                                   asCard = false,
                                   initialPortfolio = null,
                               }) => {
    const {sendGAEvent} = useAuth();
    const {requestLockInfo} = useAuth();
    const {
        proposal,
        id,
        fetchProposal
    } = useHolisticoProposalContext();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openProgress, setOpenProgress] = useState(false);
    const [refreshTrigger, setRefreshTrigger] = useState(null);

    const portfolios = type === "cur"
        ? proposal?.current_portfolios
        : proposal?.proposed_portfolios;

    const lockInfo = requestLockInfo("#post-add-portfolio-from-positions");

    useEffect(() => {
        if (initialPortfolio) {
            setOpenDrawer(true);
        }
    }, [initialPortfolio])

    const createAccount = (_positions, _name) => {
        setTimeout(() => setRefreshTrigger(Math.random()), 500);
        setOpenDrawer(false);
        const postData = {
            positions: _positions,
            portfolio_name: _name,
            portfolio_type: type
        }
        setOpenProgress(true);
        createFromPositions(id, postData, (result, error) => {
            sendGAEvent({
                category: 'Accounts',
                action: 'Click',
                label: 'Add account from file'
            });
            if (!error) {
                fetchProposal(true);
            } else {
                message.error("Something went wrong while creating account!");
                console.error(result);
            }
            setOpenProgress(false);
        })
    }

    return <>
        <RequestLockTooltip
            limitMessage={"You exceeded the limit of adding accounts from file or asset search."}
            lockMessage={"Adding account from file or asset search is not available for your current subscription."}
            lockedUntil={lockInfo.until}
            locked={lockInfo.locked}
        >
            {asCard
                ? <ButtonCard
                    icon={<FileAddOutlined/>}
                    showArrow={true}
                    onClick={() => {
                        onClick?.();
                        setOpenDrawer(true);
                    }}
                    disabled={lockInfo.locked}
                >
                    <Flex vertical align={"flex-start"} style={{flexGrow: 1}}>
                        <Button
                            type={"link"}
                            disabled={lockInfo.locked}
                            style={{padding: 0}}
                        >
                            {buttonText}
                        </Button>
                        <Typography.Text>
                            Create account manually from file or asset
                            search
                        </Typography.Text>
                    </Flex>
                </ButtonCard>
                : <Button
                    type={buttonType}
                    onClick={() => {
                        onClick?.();
                        setOpenDrawer(true);
                    }}
                    disabled={lockInfo.locked}
                >
                    {buttonText}
                </Button>
            }
        </RequestLockTooltip>
        <MobileDrawer
            open={openDrawer}
            onClose={() => {
                setTimeout(() => setRefreshTrigger(Math.random()), 500);
                setOpenDrawer(false);
            }}
            maskClosable={false}
            backButtonText={"Cancel"}
            id={"portfolio-constructor-drawer"}
        >
            <PortfolioConstructor
                name={portfolios ? `Account ${portfolios.length + 1}` : "Main account"}
                tip={
                    "Fill portfolio positions to create account. " +
                    "You can either upload file with positions or " +
                    "use asset search to fill them manually."
                }
                allowEditName={true}
                applyButtonText={"Save"}
                hoverable={false}
                onSubmit={createAccount}
                refreshTrigger={refreshTrigger}
                portfolio={initialPortfolio}
            />
        </MobileDrawer>
        <FakeProgress
            tip={"Updating portfolio analytics..."}
            open={openProgress}
            time={30000}
        />
    </>
}


export const AddAccountChoice = ({onClick = null}) => {
    return <Flex vertical gap={"middle"}>
        <PlaidCreateLink onClick={onClick} asCard={true}/>
        <AddAccountLink
            onClick={onClick}
            asCard={true}
            buttonText={"From file or asset search"}
        />
    </Flex>
}


const AddAccountModal = ({open, setOpen}) => {
    return <Modal
        title={"Add account(s)"}
        footer={null}
        closable={true}
        maskClosable={true}
        open={open}
        onCancel={() => setOpen(false)}
        afterClose={null}
        width={650}
    >
        <AddAccountChoice onClick={() => setOpen(false)}/>
    </Modal>
}


const AccountsCardTour = ({}) => {
    const steps = [
        {
            title: "Portfolio statistics",
            description: <>
                - Market value<br/>
                - Number of unique positions<br/>
                - Distribution rate / dividend yield<br/>
                - Expense ratio
            </>,
            target: "accounts-card-statistics",
        },
        {
            title: "All your accounts uploaded to Holistico",
            target: "accounts-card-accounts",
        },
        {
            title: "Account market value",
            target: "accounts-card-account-0-mkval",
        },
        {
            title: "Account info",
            description: <>
                - Number of positions<br/>
                - Date of the last update<br/>
                - Institution name if linked via Plaid<br/>
                - Warnings if exist
            </>,
            target: "accounts-card-account-0-tags",
        },
        {
            title: "Click to delete the account",
            target: "accounts-card-account-0-delete",
        },
        {
            title: "Click to edit the account content",
            target: "accounts-card-account-0-edit",
        },
        {
            title: "Click to view the account content",
            target: "accounts-card-account-0-view",
        },
        {
            title: " Click to add new account",
            target: "accounts-card-accounts-add",
        },
    ];

    return <TourButton steps={steps}/>
}


const PlaidAlerts = ({proposal}) => {
    const items = proposal?.plaid_items?.filter(item => item.error && ERRORS_TO_SHOW.includes(item.error.code));
    const {token} = useToken();

    return <>{
        items && items.length > 0 && <List
            dataSource={items}
            renderItem={item => {
                return <List.Item key={item.item_id} style={{
                    border: "none",
                    padding: 0,
                    marginBottom: token.marginXS,
                }}>
                    <PlaidAlert clientId={proposal._id} item={item}/>
                </List.Item>
            }}
            style={{marginBottom: token.margin}}
        />
    }</>
}


const AccountsCard = ({}) => {
    const {sendGAEvent} = useAuth();
    const {isDataLocked, isActionLocked, getDataLimit} = useAuth();
    const {proposal} = useHolisticoProposalContext();
    const [openAddAccountModal, setOpenAddAccountModal] = useState(false);
    const {token} = useToken();
    const {externalId} = useParams();

    const cv = proposal?.p_bucket_cur?.calculated_values;
    const mkval = cv?.market_value;
    const portfolios = proposal?.current_portfolios;
    const full_name = externalId ? proposal?.full_name : "My portfolio";
    let addAccountLocked = isActionLocked("#add-account");
    let lockCustomLabel = null
    const accountsLimit = getDataLimit("#accounts-number");
    if (accountsLimit && portfolios && portfolios.length >= accountsLimit) {
        addAccountLocked = true;
        lockCustomLabel = `Adding more than ${accountsLimit} account(s)`
            + " is not allowed for your current subscription";
    }

    return <Card
        hoverable
        style={{
            background: token.colorBgGrey,
            borderColor: token.colorBorderStrong,
            cursor: "default"
        }}
        id={"accounts-card"}
    >
        <NestedCard background={token.colorBgContainer}>
            <CardHeader
                title={full_name}
                icon={<CreditCardFilled/>}
                // controls={externalId ? null : (mkval > 500000 ?
                //     <SendToAdvisor/> : null)}
                tour={<AccountsCardTour/>}
                id={"accounts-card-header"}
            />
            <Flex
                gap="small"
                align={"stretch"}
                wrap={"wrap"}
                id={"accounts-card-statistics"}
                style={{width: "fit-content"}}
            >
                <StatsCard
                    title="Market value"
                    value={is_null(cv?.market_value) ? "-" : ValueFormatter.currency(cv.market_value)}
                />
                <StatsCard
                    title="Positions"
                    value={is_null(cv?.unique_pos_count) ? "-" : ValueFormatter.int_number(cv.unique_pos_count)}
                />
                <StatsCard
                    title="Distribution rate"
                    value={is_null(cv?.annual_dividend) ? "-" : ValueFormatter.int_percent(cv.annual_dividend)}
                    locked={isDataLocked("#portfolio-dividend-yield")}
                />
                <StatsCard
                    title="Expense ratio"
                    value={is_null(cv?.expense_ratio) ? "-" : ValueFormatter.int_percent(cv.expense_ratio)}
                    locked={isDataLocked("#portfolio-expense-ratio")}
                />

            </Flex>
        </NestedCard>
        <div id={"accounts-card-accounts"}>
            <Typography.Title level={5}>
                Accounts
            </Typography.Title>
            <PlaidAlerts proposal={proposal}/>
            {accountsLimit && portfolios.length >= accountsLimit
                && <LockTooltip
                    customLabel={
                        `Accounts number is restricted by ${accountsLimit}`
                        + ` for your current subscription!`
                    }
                    style={{width: "fit-content"}}
                >
                    <Alert
                        message={"Restricted number of accounts"}
                        type={"warning"}
                        style={{
                            width: "fit-content",
                            marginBottom: token.margin
                        }}
                        showIcon
                    />
                </LockTooltip>
            }
            <Flex vertical gap={"small"} style={{marginBottom: 16}}>
                {portfolios &&
                    portfolios.map((portfolio, index) => {
                        return <AccountCard
                            key={portfolio._id}
                            account={portfolio}
                            id={`accounts-card-account-${index}`}
                        />
                    })
                }
            </Flex>
            <LockTooltip
                label={"Adding account"}
                locked={addAccountLocked}
                customLabel={lockCustomLabel}
                style={{width: "fit-content"}}
            >
                <Button
                    type={"link"}
                    style={{padding: 0}}
                    disabled={addAccountLocked}
                    onClick={() => {
                        setOpenAddAccountModal(true);
                        sendGAEvent({
                            category: 'Accounts',
                            action: 'Click',
                            label: 'Add account'
                        });
                    }}
                    id={"accounts-card-accounts-add"}
                >
                    <PlusCircleOutlined/>
                    Add account
                </Button>
            </LockTooltip>
            <AddAccountModal
                open={openAddAccountModal}
                setOpen={setOpenAddAccountModal}
            />
        </div>
    </Card>
}


export default AccountsCard;
