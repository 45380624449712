import React, {useState} from "react";
import {Button, message} from "antd";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import {
    updatePlaidPortfolios,
} from "@API/clients";
import FakeProgress
    from "@components/retail/portfolio/constructor/FakeProgress";
import PlaidLinkLauncher
    from "@components/retail/portfolio/plaid/PlaidLinkLauncher";


const PlaidUpdateLink = ({
                             clientId,
                             itemId,
                             buttonText="Update credentials"
                         }) => {
    const {fetchProposal} = useHolisticoProposalContext();
    const [ready, setReady] = useState(false);
    const [launchTrigger, setLaunchTrigger] = useState(0);
    const [openProgress, setOpenProgress] = useState(false);

    const onSuccess = () => {
        setOpenProgress(true);
        updatePlaidPortfolios(clientId, itemId, (response, error) => {
            if (error) {
                message.error("Error while updating account(s) from Plaid");
                console.debug(error);
            } else {
                fetchProposal();
            }
            setOpenProgress(false);
        })
    };

    return <>
        <Button
            type={"link"}
            onClick={() => {
                setLaunchTrigger(prev => prev + 1);
            }}
            disabled={!ready}
            style={{
                padding: 0,
            }}
        >
            {buttonText}
        </Button>
        <FakeProgress
            tip={"Updating accounts and portfolio analytics..."}
            open={openProgress}
            time={60000}
        />
        <PlaidLinkLauncher
            clientId={clientId}
            itemId={itemId}
            onSuccess={onSuccess}
            onReady={() => setReady(true)}
            launchTrigger={launchTrigger}
        />
    </>
}

export default PlaidUpdateLink;
