import React, {useState} from "react";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {Flex, theme} from 'antd';
import {useAuth} from "@hooks/AuthHook";
import ConfigSelector
    from "@components/supervision/global_configs/ConfigSelector";
import ClientReportContent
    from "@components/supervision/client/ClientReportContent";
import ClientConfigCheckbox
    from "@components/supervision/global_configs/ClientConfigCheckbox";
import {PageNotFound} from "@app/layout/retail/RetailLayout";
import NoDataPlaceholder from "@holistico/aux/NoDataPlaceholder";
import ClientSearch from "@components/supervision/client/ClientSearch";
// import ClientReportContent
//     from "@components/supervision/client/issue_version/ClientReportContent";


const {useToken} = theme;


const ClientReport = () => {
    const auth = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [config, setConfig] = useState(null);
    const [configsDetails, setConfigsDetails] = useState(null);
    const [useClientConfig, setUseClientConfig] = useState(true);
    const {token} = useToken();

    return <div className="supervision-container" style={{
        minHeight: 500,
    }}>
        <Flex
            align={"baseline"}
            wrap={"wrap"}
            gap={token.margin}
            style={{marginBottom: token.marginXXL}}
        >
            {auth.isSupervisor()
                && <div style={{width: 400}}>
                    <ClientSearch
                        setSelectedClient={(clientId) => {
                            navigate({
                                pathname: clientId,
                                search: location.search
                            })
                        }}
                        clientName={null}
                    />
                </div>
            }
            <div style={{width: 300}}>
                <ConfigSelector
                    setSelectedConfig={setConfig}
                    setConfigsDetails={setConfigsDetails}
                />
            </div>
            <ClientConfigCheckbox setUseClientConfig={setUseClientConfig}/>
        </Flex>
        <Routes>
            <Route
                path=""
                element={
                    <NoDataPlaceholder description={"Search client to see the report"}/>
                }
            />
            <Route
                path=":clientId"
                element={
                    <ClientReportContent
                        config={config}
                        useClientConfig={useClientConfig}
                        configsDetails={configsDetails}
                    />
                }
            />
            <Route path="*" element={<PageNotFound/>}/>
        </Routes>
    </div>
}

export default ClientReport;
