import React, {useEffect, useState} from "react";
import {Col, Flex, Row, Typography} from "antd";
import {RightOutlined} from "@ant-design/icons";

import "@components/portfolio/Portfolio.less";


const ExpandableContainer = ({text, infoComponent, expandableComponent, mark = null, children}) => {
    const [expand, setExpand] = useState(false);

    useEffect(() => {
        setExpand(false);
    }, [expandableComponent, setExpand])

    const clickRow = () => {
        setExpand(!expand);
    }

    return <>
        <Flex
            justify={"space-between"}
            gap={"large"}
            onClick={clickRow}
            className="portfolio-row-item supervision-row-header"
            style={{width: "100%"}}
        >
            <Flex gap={"middle"}>
                 <RightOutlined rotate={expand ? 90 : 0} style={{color: 'red', marginLeft: 8}}/>
                 <Typography.Text key="name" className="portfolio-title-name">
                    {text} {mark}
                </Typography.Text>
            </Flex>
            <div>
                {infoComponent}
            </div>
        </Flex>
        {expand
            && <div className="supervision-row-content">
                {expandableComponent}
            </div>
        }
        {expand && children}
    </>
}

export default ExpandableContainer
