import React, {useEffect} from 'react';
import {createRoot} from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";
import {AuthWrapper} from "@hooks/AuthHook";
import "./index.css"

// Code below is for dayjs to work in DatePicker
// https://github.com/react-component/picker/issues/123
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

import AppRouter from "@app/AppRouter";


if (module.hot) {
    module.hot.accept()
}


if (process.env.REACT_APP_DEBUG === "0") {
    let executed_console;
    if (console) {
        executed_console = console;
    } else if (window.console) {
        executed_console = window.console;
    }
    let methods = ["log", "debug", "warn", "table"];
    for (let i = 0; i < methods.length; i++) {
        executed_console[methods[i]] = function () {
        };
    }
}

const mode = process.env.REACT_APP_UI_VERSION

const container = document.getElementById('root');
const root = createRoot(container);

function AppWithCallbackAfterRender() {
    useEffect(() => {
        console.log('rendered');
    });

    return <AuthWrapper>
        <BrowserRouter>
            <AppRouter/>
        </BrowserRouter>
    </AuthWrapper>
}


root.render(<AppWithCallbackAfterRender/>)
