/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {
    Navigate,
    Route,
    Routes,
    useLocation,
    useNavigate, useSearchParams
} from "react-router-dom";
import {Menu, theme} from "antd";
import {getHiddenHref} from "@global/Utils";
import {ScreenerContextProvider} from "@hooks/ScreenerContext";
import ParamsForm from "@components/screener/ParamsForm";
import AssetsList from "@components/screener/AssetsList";
import {useAuth} from "@hooks/AuthHook";
import LockTooltip from "@components/retail/cards/LockTooltip";
import {PageTourModal} from "@components/retail/cards/TourButton";


const {useToken} = theme;


const getMenuItems = (accessDetails, retail) => {
    console.log(accessDetails);
    const items = [
        {
            key: `company`,
            label: "Companies"
        }, {
            key: `fund`,
            label: "Funds",
        }
    ]
    if (!retail) {
        items.push({
            key: `portfolio`,
            label: "Models marketplace"
        })
    }

    return items.map(item => {
        const locked = (accessDetails?.locked_data ?? []).includes(`#${item.key}-screener`);
        const label = item.key[0].toUpperCase() + item.key.slice(1) + " screener";
        return {
            key: item.key,
            label: <LockTooltip label={label} locked={locked}>
                <div id={`screener-menu-item-${item.key}`}>
                    {locked? item.label : getHiddenHref(item.label, item.key)}
                </div>
            </LockTooltip>,
            disabled: locked
        }
    })
}


const getEntityGroupFromPath = (path) => {
    if (path.includes("screener/company")) {
        return 'company'
    } else if (path.includes("/screener/fund")) {
        return 'fund'
    } else if (path.includes("/screener/portfolio")) {
        return 'portfolio'
    } else {
        return null
    }
}


const CompanyScreener= ({inProposalContext=false}) => {
    return <ScreenerContextProvider entityGroup={'company'} inProposalContext={inProposalContext}>
        <ParamsForm>
            <AssetsList/>
        </ParamsForm>
    </ScreenerContextProvider>
}


const FundScreener= ({inProposalContext=false}) => {
    return <ScreenerContextProvider entityGroup={'fund'} inProposalContext={inProposalContext}>
        <ParamsForm>
            <AssetsList/>
        </ParamsForm>
    </ScreenerContextProvider>
}


const PortfolioScreener = ({inProposalContext=false}) => {
    return <ScreenerContextProvider entityGroup={'portfolio'} inProposalContext={inProposalContext}>
        <ParamsForm>
            <AssetsList/>
        </ParamsForm>
    </ScreenerContextProvider>
}


const ModelsMarketplace= ({inProposalContext=false}) => {
    return <ScreenerContextProvider entityGroup={'portfolio'} inProposalContext={inProposalContext}>
        <ParamsForm showTour={true}>
            <AssetsList/>
        </ParamsForm>
        <PageTourModal tourKey={"marketplace-page"}/>
    </ScreenerContextProvider>
}


const AssetScreener = ({inProposalContext=false, retail=false}) => {
    const {accessDetails} = useAuth();
    const navigate = useNavigate()
    const location = useLocation()
    const [path, setPath] = useState()
    const [searchParams, setSearchParams] = useSearchParams();
    const [paramsStore, setParamsStore] = useState( {company: null, fund: null});
    const [selectedKey, setSelectedKey] = useState(['company']);
    const {token} = useToken();

    useEffect(() => {
        let _entityGroup = getEntityGroupFromPath(location.pathname)
        if (location.pathname === path && _entityGroup) {
            // path didn't change, store params for this entity group
            setParamsStore(prev => {
                prev[_entityGroup] = searchParams
                return prev
            })

        } else {
            // path changed, restore params for this entity group
            if(paramsStore[_entityGroup]) {
                setSearchParams(paramsStore[_entityGroup])
            }
        }
        setPath(location.pathname)
        setSelectedKey(`${_entityGroup}`)
    }, [location.pathname, searchParams])

    return <>
        <Menu
            mode="horizontal"
            selectedKeys={[selectedKey]}
            onClick={(e) => {
                navigate(e.key)
            }}
            items={getMenuItems(accessDetails, retail)}
            style={{
                background: "none",
                border: "none",
                marginBottom: 16,
                // marginLeft: -16,
                marginLeft: token.marginLG - 16,
                marginTop: -16,
                maxWidth: 400,
            }}
            id={"screener-menu"}
        />
        <Routes>
            <Route path="" element={<Navigate to="company" replace/>}/>
            <Route path="company" element={<CompanyScreener inProposalContext={inProposalContext}/>}/>
            <Route path="fund" element={<FundScreener inProposalContext={inProposalContext}/>}/>
            <Route path="portfolio" element={<PortfolioScreener inProposalContext={inProposalContext}/>}/>
        </Routes>
    </>
}

export {AssetScreener, ModelsMarketplace};
