import axiosInstance from "./APIClient";
import axios from "axios";


let cancel;


const getReportBasics = (reportClass, templatesHandler) => {
    const url = `/client/pdf-report/settings`;

    axiosInstance.get(url,
        {
            params: {report_class: reportClass},
            cancelToken: new axios.CancelToken(function executor(c) {
                cancel = c;
            })
        }).then(({data}) => {
            templatesHandler(data);
        })
        .catch((response) => {
            console.log(response);
        })
};


const updateReportPreset = (userTemplate, callback) => {
    let url = `/client/pdf-report/presets`

    axiosInstance.put(url, userTemplate)
        .then(({data}) => {
            callback(data, false);
        })
        .catch((response) => {
            callback(response, true);
            console.log(response);
        })
};

const createReportPreset = (userTemplate, creationCallback) => {
    let url = `/client/pdf-report/presets`

    axiosInstance.post(url, userTemplate)
        .then(({data}) => {
            creationCallback(data);
        })
        .catch((response) => {
            creationCallback(response, true);
            console.log(response);
        })
};


const deleteReportPreset = (_id, deletionCallback) => {
    let url = `/client/pdf-report/presets/${_id}`

    axiosInstance.delete(url)
        .then(() => {
            deletionCallback();
        })
        .catch(({response}) => {
            console.log(response);
        })
};


const createReport = (reportData, callback) => {
    let url = `/client/pdf-report/generate`

    axiosInstance.post(url, reportData, {responseType: "arraybuffer"})
        .then((response) => {
            callback(response);
        })
        .catch(({response}) => {
            callback(response, true);
        })
};


export {
    cancel,
    createReport,
    getReportBasics,
    createReportPreset,
    updateReportPreset,
    deleteReportPreset
}
