import React, {useCallback, useEffect, useRef, useState} from "react";
import {Button, Checkbox, message, Space, Typography} from "antd";
import {downloadPDF, getSummaryReport} from "@API/supervision";

import "../Supervision.less";
import ValueFormatter from "@global/ValueFormatter";
import {SupervisionContext} from "@components/supervision/contexts/SupervisionContext";
import ConfigSelector from "../global_configs/ConfigSelector";
import RedFlagHealthRadar from "@components/supervision/chart/RedFlagHealthRadar";
import SmartRadar from "@components/supervision/summary/SmartRadar";
import {downloadFile} from "@global/FileDownloader";
import {useAuth} from "@hooks/AuthHook";
import ClientConfigCheckbox
    from "@components/supervision/global_configs/ClientConfigCheckbox";

const prepareRadarData = (flagDataArray, clientsTotal) => {
    let radarData = []

    for (let record of flagDataArray) {
        let clientsProblem = record['problem_counters']['clients']
        let flagsProblem = record['problem_counters']['flags']
        let percent = ValueFormatter.round((1 - (clientsProblem / clientsTotal)) * 100, 1)
        radarData.push({
            value: percent,
            key: record['red_flag_key'],
            label: record['red_flag_name'],
            description: record['red_flag_description'],
            clientsTotal: clientsTotal,
            clientsProblem: clientsProblem,
            flagsProblem: flagsProblem
        })
    }
    return radarData
}

const prepareDefaultDetails = (totalCounters, problemCounters) => {
    let clientsProblem = problemCounters['clients']
    let clientsTotal = totalCounters['clients']
    let percent = ValueFormatter.round((1 - (clientsProblem / clientsTotal)) * 100, 1)
    return {
        value: percent,
        key: 'summary',
        label: 'Summary',
        clientsProblem: clientsProblem,
        clientsTotal: clientsTotal,
        flagsProblem: problemCounters['flags'],
        description: <Typography.Paragraph>The stock market always has its ups and downs. Some sectors overperform, and
            some sectors underperform. Chances are that the sectors that did the best last year may not enjoy a repeat
            performance this year—and, of course, 2022 was pretty grim for most of them. By rebalancing your portfolio
            to its original or updated asset allocation, you take steps to lock in gains from the sectors with the best
            returns and purchase shares in the sectors that have lagged behind last year's
            leaders.</Typography.Paragraph>
    }
}

const RadarChart = React.memo(({data, updater}) => {
    return <RedFlagHealthRadar flags_healths={data} updateDetails={updater}/>
})

const SummaryDashboard = () => {
    const [config, setConfig] = useState(null);
    const [useClientConfig, setUseClientConfig] = useState(true);
    const abortControllerRef = useRef(new AbortController());
    const [reportLoading, setReportLoading] = useState(true);
    const [redFlags, setRedFlags] = useState(null);
    const [radarData, setRadarData] = useState(null)
    const [defaultDetails, setDefaultDetails] = useState(null)
    const {sendGAEvent} = useAuth();

    const getReportWithParams = useCallback((configId, _useClientConfig) => {
        setReportLoading(true);

        getSummaryReport(configId, _useClientConfig, (data, error) => {
            setReportLoading(false);
            if (error) {
                message.error("Error while retrieving global supervision config")
                return
            }
            let preparedForRadar = prepareRadarData(data.children, data['total_counters']['clients'])
            let defDetails = prepareDefaultDetails(data['total_counters'], data['problem_counters'])
            setDefaultDetails(defDetails)
            setRadarData(preparedForRadar)
        }, abortControllerRef.current.signal)
    }, [setRedFlags])

    const contextGetGlobalReportWithParams = useCallback(() => {
        if (config) {
            getReportWithParams(config.id, useClientConfig);
        }
    }, [getReportWithParams, config, useClientConfig])

    useEffect(() => {
        return () => {
            abortControllerRef.current.abort();
        }
    }, [])

    const downloadReport = () => {
        sendGAEvent({
            category: 'Supervision',
            action: 'Click',
            label: 'Download report'
        });
        setReportLoading(true);
        downloadPDF(config.id, useClientConfig, (response, error) => {
            if (error) {
                console.error(error)
                message.error("Error generating wealth monitor pdf!");
                setReportLoading(false);
                return
            }
            let file_type = response.headers['content-type'];
            let file_name = `Compliance risk (${new Date().toLocaleString()}).pdf`;
            downloadFile(response.data, file_name, file_type, () => {
                setReportLoading(false);
            })
        }, abortControllerRef.current.signal)
    }

    useEffect(() => {
        if (config !== null && useClientConfig !== null) {
            getReportWithParams(config.id, useClientConfig);
        }
    }, [config, useClientConfig])

    return (
        <div className="supervision-container">
            <Space direction={'horizontal'} style={{marginBottom: 24}} size={15}>
                <div style={{width: 300}}>
                    <ConfigSelector setSelectedConfig={setConfig}/>
                </div>
                <ClientConfigCheckbox setUseClientConfig={setUseClientConfig}/>
                <Button onClick={downloadReport}>Download report</Button>
            </Space>
            <SupervisionContext.Provider value={{
                updateData: contextGetGlobalReportWithParams
            }}>
                <SmartRadar radarData={radarData} defaultDetails={defaultDetails} loading={reportLoading}/>
            </SupervisionContext.Provider>
        </div>
    )
}

export default SummaryDashboard;
