import React, {useCallback, useEffect, useRef, useState} from "react";
import {Badge, message, Space, Table, Tooltip, Typography} from "antd";
import {getClientLimitsReport} from "@API/supervision";

import "../Supervision.less";
import Localizator from "@global/Localizator";
import {AudioMutedOutlined, SisternodeOutlined, TeamOutlined, WalletOutlined, WarningOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import {gold, green, red} from '@ant-design/colors';
import ClientActions from "@components/supervision/actions/ClientActions";
import {SupervisionContext} from "@components/supervision/contexts/SupervisionContext";
import ConfigSelector from "../global_configs/ConfigSelector";
import RepresentativeConfiguredClients from "@components/supervision/indicators/RepresentativeConfiguredClients";

const green_color = green[6]
const gold_color = gold[6]
const staticColumns = [{
    title: <>{'Name'}</>,
    dataIndex: 'name',
    sorter: (a, b) => a.representative_name?.localeCompare(b.representative_name),
    key: 'entity_title',
    width: '35%',
    render: (value, record) => {
        let level = record['level']
        switch (level) {
            case 'client':
                return prepareClientTitle(record)
            case 'representative':
                return prepareAdvisorTitle(record)
        }
    },
}, {
    title: <Tooltip title={'Amount of muted clients'}>Mutes</Tooltip>,
    dataIndex: 'mutes',
    key: 'mutes',
    render: (value, record) => {
        let level = record['level']
        switch (level) {
            case 'client':
                return null
            case 'representative':
                let clientsWithMutes = record['total_counters']['clients_with_client_mute']
                return <Typography.Text type={"warning"}>{clientsWithMutes}</Typography.Text>
        }
    }
}, {
    title: <Tooltip title={'Amount of checks with custom configs'}>Checks</Tooltip>,
    dataIndex: 'limits',
    key: 'limits',
    render: (value, record) => {
        let level = record['level']
        switch (level) {
            case 'client':
                return null
            case 'representative':
                let customFlags = record['total_counters']['flags_with_flag_mute_or_limit']
                return <Typography.Text type={"warning"}>{customFlags}</Typography.Text>
        }
    }
},
]
const prepareAdvisorTitle = (advisor) => {
    return <Typography.Text><WalletOutlined/> {advisor['representative_name']}</Typography.Text>
}

const prepareClientTitle = (client) => {
    let style = {}
    if (client['client_muted']) {
        style = {color: '#cccccc'}
    }
    let title = <Space direction={'vertical'} size={0}>
        <Link className={'flag-client-link'} to={"../client/" + client.client_id}
              relative="path" target={'_blank'} style={style}><TeamOutlined/>{client.client_name}</Link>
        <ClientActions client={client} actions={['mute_client', 'analyze', 'proposal']} size={'small'}
                       showTitle={true}/>
    </Space>
    return title
}
//const configId = '634c0dbb2dbf10e5efa46bf1'
const ClientLimitsReport = () => {
    const [columns, setColumns] = useState([])
    const [reportLoading, setReportLoading] = useState(false);
    const [data, setData] = useState({data: {children: [], problem_counters: {}, total_counters: {}}})
    const [metadata, setMetadata] = useState(null)
    const [config, setConfig] = useState(null);
    const [useClientConfig, setUseClientConfig] = useState(true);
    const abortControllerRef = useRef(new AbortController());

    const loadData = (configId, _useClientConfig) => {
        setReportLoading(true);

        getClientLimitsReport(configId, _useClientConfig, (data, error) => {
            setReportLoading(false);
            if (error) {
                console.error(data)
                message.error("Error while retrieving representatives report").then(r => console.log(r));
                return
            }
            //console.table(data.data)

            let curColumns = [...staticColumns]
            const meta = data.metadata

            for (let col of meta.flags_order) {
                let flagTitle = meta.flag_labels[col]
                let icon = Localizator.t('supervision.flag.icon.' + col)
                if (typeof icon == 'string') {
                    icon = <WarningOutlined />
                }
                let titleTip = <Tooltip title={flagTitle} color={'red'}>{icon}</Tooltip>

                curColumns.push({
                    title: titleTip,
                    dataIndex: ['flags', col, 'problem_counters', 'clients'],
                    align: 'center',
                    width: 30,
                    render: (value, record) => {
                        let level = record['level']
                        if (level == 'representative') {
                            let totalConfigured = record['flags'][col]['total_counters']['clients_with_flag_mute_or_limit']
                            return <RepresentativeConfiguredClients configuredClientsCount={totalConfigured}
                                                                    allClientsCount={record['total_counters']['clients']}
                                                                    flagTitle={flagTitle}/>
                        } else {
                            let flag_raised = record['flags'][col]['raised']
                            let hasCustomSettings = (record['flags'][col]['has_client_limit'] || record['flags'][col]['flag_muted'])
                            if (flag_raised) {
                                if (hasCustomSettings) {
                                    let text = 'Check failed. Client limit applied.'
                                    let icon = <SisternodeOutlined style={{color: red[5]}}/>
                                    if (record['flags'][col]['flag_muted']) {
                                        text = 'Check muted.'
                                        icon = <AudioMutedOutlined style={{color: red[5]}}/>
                                    }
                                    return <Tooltip title={text} color={red[5]}>{icon}</Tooltip>
                                } else {
                                    let status = 'error'
                                    let ttl = flagTitle + ' check failed, no client limit set.'

                                    return <Tooltip title={ttl} color={red[5]}>
                                        <Badge status={status} style={{cursor: 'pointer'}}
                                               onClick={() => {
                                                   console.info("table badge clicked")
                                               }}/>
                                    </Tooltip>
                                }
                            } else {
                                if (hasCustomSettings) {
                                    let text = 'Passed. Client limit applied.'
                                    let icon = <SisternodeOutlined style={{color: green_color}}/>
                                    if (record['flags'][col]['flag_muted']) {
                                        text = 'Check muted.'
                                        icon = <AudioMutedOutlined style={{color: green_color}}/>
                                    }
                                    return <Tooltip title={text} color={green_color}>{icon}</Tooltip>
                                } else {
                                    return <Tooltip title={'No limits for flag.'} color={green_color}>
                                        <Badge status={'success'} style={{cursor: 'pointer'}}
                                               onClick={() => {
                                                   console.info("table badge clicked")
                                               }}/>
                                    </Tooltip>
                                }
                            }
                        }
                    }
                })
            }
            setColumns(curColumns)
            setMetadata(meta);
            setData(data);
        }, abortControllerRef.current.signal)
    }

    const loadDataWithValues = useCallback(() => {
        if (config) {
            loadData(config.id, useClientConfig);
        }
    }, [config, useClientConfig])

    useEffect(() => {
        if (config) {
            loadData(config.id, useClientConfig);
        }
    }, [config, useClientConfig])

    useEffect(() => {
        if (config) {
            setUseClientConfig(config.use_client_config);
        }
    }, [config])

    return (
        <div className="supervision-container">
            <Space direction={'horizontal'} style={{marginBottom: 24}}>
                <div style={{width: 300}}>
                    <ConfigSelector setSelectedConfig={setConfig}/>
                </div>
            </Space>
            <Typography.Title level={4} className="spas">
                Custom Limits
            </Typography.Title>
            <Typography.Paragraph>{Localizator.t('supervision.description.clientLimits')}
            </Typography.Paragraph>
            <SupervisionContext.Provider value={{updateData: loadDataWithValues}}>
                <Table
                    className={'representatives-tree'}
                    rowClassName={'representatives-tree'}
                    style={{width: '100%'}}
                    columns={columns}
                    loading={reportLoading}
                    dataSource={data.data.children}
                    expandRowByClick={true}
                    bordered={false}
                    pagination={{pageSize: 25, hideOnSinglePage: true}}
                    size={"small"}
                    rowKey={record => record.level + record.representative_name + record.client_id}
                    summary={(pageData) => {
                        if (!data || !data.metadata) {
                            return null
                        }
                        let mutedClients = data['data']['total_counters']['clients_with_client_mute']
                        let allClients = data['data']['total_counters']['clients']
                        let flagMutedClients = data['data']['total_counters']['clients_with_flag_mute']
                        let clients_with_client_limit = data['data']['total_counters']['clients_with_any_config']

                        let summary = {}

                        for (let representative of data['data']['children']) {
                            for (let flag_label of data['metadata']['flags_order']) {
                                if (!summary.hasOwnProperty(flag_label)) {
                                    summary[flag_label] = 0
                                }
                                let value = representative['flags'][flag_label]['total_counters']['clients_with_flag_mute_or_limit']
                                summary[flag_label] += value
                            }
                        }

                        let clientsMuted = data['data']['total_counters']['clients_with_client_mute']
                        let flagsWithAnyLimit = data['data']['total_counters']['flags_with_client_limit'] + data['data']['total_counters']['flags_with_flag_mute']
                        let clientsWithAnyLimit = data['data']['total_counters']['clients_with_flag_mute_or_limit']
                        return <>
                            <Table.Summary.Row style={{background: '#E0AD60', fontSize: 12}}>
                                <Table.Summary.Cell index={0} align={'right'}>Total</Table.Summary.Cell>
                                <Table.Summary.Cell index={1} >{clientsMuted}</Table.Summary.Cell>
                                <Table.Summary.Cell index={2} >{flagsWithAnyLimit}</Table.Summary.Cell>
                                {data['metadata']['flags_order'].map(col_name => {
                                    return <Table.Summary.Cell align={'center'}>{summary[col_name]}</Table.Summary.Cell>
                                })

                                }
                            </Table.Summary.Row>
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0}></Table.Summary.Cell>
                                <Table.Summary.Cell index={1} colSpan={23}>
                                    Checks for <Typography.Text type="danger">{mutedClients}</Typography.Text> clients
                                    of {allClients} are skipped.
                                    <Typography.Text
                                        type={"warning"}> {clientsWithAnyLimit}</Typography.Text> have custom
                                    limits set or flags disabled.
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </>
                    }}
                />
            </SupervisionContext.Provider>
            {/*<Typography.Title level={4} className="spas">
                Chart
            </Typography.Title>
            <AdvisorWarnings advisor_rows={data} metadata={metadata}/>*/}
        </div>
    )
}

export default ClientLimitsReport;
