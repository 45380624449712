import React, {useEffect} from "react";
import {App, ConfigProvider, Spin, theme} from "antd";
import {useAuth} from "@hooks/AuthHook";
import RetailLayout from "@app/layout/retail/RetailLayout";
import {TncProvider} from "@hooks/TncContext";
import {initReddit, initTwitter} from "@hooks/AdsConversion";


const {defaultAlgorithm, darkAlgorithm} = theme;
const {useToken} = theme;


const AppRetail = ({}) => {
    const {user, darkMode} = useAuth();
    const {token} = useToken();

    useEffect(() => {
        initTwitter()
        initReddit()
    }, [])

    return <TncProvider>
        <Spin spinning={null == user} style={{height: 800}} size={"large"}
              delay={500}>
            <ConfigProvider
                theme={{
                    algorithm: darkMode ? darkAlgorithm : defaultAlgorithm,
                    token: {
                        // colorPrimary: "#2c5f7e",
                        // colorLink: "#2c5f7e",
                        // colorBgLayout: "#f5f8fa",
                        // colorText: "#03111a",
                        colorPrimary: darkMode ? "#51b4fc" : "#3F8DFA",
                        colorError: "#fa4f57",
                        colorSuccess: "#49aa19",
                        fontSizeHeading1: 48,
                        fontFamily: 'Red Hat Display W, Helvetica, Arial, sans-serif',
                        fontSize: 16,
                        fontWeightStrong: 450,
                        colorBgBase: darkMode ? "#14191F" : "#ffffff",
                        colorBgContainer: darkMode ? "#2e3742" : "#ffffff",
                        headerBg: darkMode ? "#14191F" : "#ffffff",
                        fontSizeXS: 12,
                        colorBgGrey: darkMode ? "#222a33" : "rgb(248, 248, 248)",
                        colorBgGreyDark: darkMode ? "#14191F" : "rgb(240, 240, 240)",
                        colorBgHoverBlue: darkMode ? "#273241" : "#e6f7ff",
                        colorStats: "#e0ad60",
                        colorRadioSelectedBg: '#e0ad60',
                        // colorRadioSelectedBg: '#4f5b67',
                        colorBorder: darkMode ? "#2e3742" : token.colorBorder,
                        colorBorderStrong: darkMode ? "#2e3742" : "rgb(220, 220, 220)",
                        ...(darkMode
                                ? {
                                    colorPrimaryHover: "#78c4fc",
                                    colorLink: "#51b4fc",
                                    colorLinkHover: "#78c4fc",
                                }
                                : {}
                        )
                    },
                    components: {
                        Dropdown: {borderRadiusLG: 8},
                        Drawer: {fontSizeLg: 24},
                        Table: {borderRadiusLG: 8},
                    }
                }}>
                <App
                    className={'holistico retail'}
                >
                    {user ? <RetailLayout/> : <div style={{height: 600}}/>}
                </App>
            </ConfigProvider>
        </Spin>
    </TncProvider>
}

export default AppRetail;
