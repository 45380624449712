import {useAuth} from "@hooks/AuthHook";
import {App, ConfigProvider, Spin} from "antd";
import React from "react";
import HLayout from "@app/layout/ria/HLayout";
import dayjs from "dayjs";
import {TncProvider} from "@hooks/TncContext";


const AppRia = ({}) => {
    const {user} = useAuth();

    const relativeTime = require('dayjs/plugin/relativeTime')
    dayjs.extend(relativeTime)

    return <TncProvider>
        <Spin spinning={null == user}>
            <ConfigProvider
                theme={{
                    token: {
                        colorText: '#4b4b4b',
                        colorPrimary: '#3F8DFA',
                        // colorWarning: '#e0ad60',
                        fontSizeHeading1: 48,
                        fontFamily: 'Red Hat Display W, Helvetica, Arial, sans-serif',
                        fontSize: 16,
                        fontWeightStrong: 600,
                        colorBgBase: '#ffffff',
                        headerBg: 'white',
                        fontSizeXS: 12,
                        colorBgGrey: "rgb(248, 248, 248)",
                        colorBgGreyDark: "rgb(240, 240, 240)",
                        colorBgHoverBlue: "#e6f7ff",
                        colorPrimaryGrey: "rgb(100, 100, 100)",
                        colorPrimaryHoverGrey: "rgb(140, 140, 140)",
                        colorStats: '#e0ad60',
                        colorRadioSelectedBg: '#e0ad60',
                        // colorRadioSelectedBg: '#4f5b67',
                    },
                    components: {
                        "Typography": {
                            "fontWeightStrong": 450
                        },
                        "Menu": {
                            // fontFamily: 'Montserrat',
                            // fontSize: 14,
                            // fontWeight: 800
                        },
                        "Carousel": {
                            arrowOffset: 0,
                            arrowSize: 24
                        },
                        "Form": {
                            // verticalLabelPadding: 0,
                        }
                    }
                }}>
                <App className={'holistico'}>
                    {user ? <HLayout/> : <div style={{height: 600}}></div>}
                </App>
            </ConfigProvider>
        </Spin>
    </TncProvider>
}

export default AppRia
