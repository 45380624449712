import React from "react";
import {Descriptions} from "antd";
import ValueFormatter from "@global/ValueFormatter";
import Localizator from "@global/Localizator";
import moment from "moment";


let fields = [
    {
        label: 'name',
        formatter: (client) => {
            return client['name'] ? client['name'] : "-"
        }
    },
    {
        label: 'representative',
        formatter: (client) => {
            return client['representative'] ? client['representative'] : "-"
        }
    }, {
        label: 'state',
        formatter: (client) => {
            return client['state'] ? client['state'] : "-"
        }
    }, {
        label: 'start_date',
        formatter: (client) => {
            if (client['start_date']) {
                let curMom = moment(client['start_date'])
                let formattedValue = curMom.format("MMM DD, YYYY")
                return client['start_date'] ? formattedValue : "-"
            } else {
                return 'No info'
            }
        }
    }, {
        label: 'market_value',
        formatter: (client) => {
            return client['market_value'] ? ValueFormatter.compactCurrency(client['market_value']) : "-"
        }
    }, {
        label: 'email',
        formatter: (client) => {
            return client['email'] ? client['email'] : "-"
        }
    }, {
        label: 'birth_date',
        formatter: (client) => {
            if (client['birth_date']) {
                let curMom = moment(client['birth_date'])
                let formattedValue = curMom.format("MMM DD, YYYY")
                return client['birth_date'] ? formattedValue : "-"
            } else {
                return 'No info'
            }
        }
    }, {
        label: 'source',
        formatter: (client) => {
            return client['source'] ? client['source'] : 'No info'
        }
    }, {
        label: 'external_id',
        formatter: (client) => {
            return client['external_id']
        }
    }
]

const ClientInfo = ({client}) => {
    return <Descriptions column={1} bordered size={'small'}>
        {fields.map((field, index) => {
            return <Descriptions.Item
                key={index}
                label={Localizator.t(`supervision.client.${field.label}`)}
            >
                {field.formatter(client)}
            </Descriptions.Item>
        })}
    </Descriptions>
}

export default ClientInfo;
