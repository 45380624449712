import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Col, InputNumber, Modal, Row, Select, Spin, Tooltip} from 'antd';
import Localizator from "@global/Localizator";
import {cancel, getModels} from "@API/portfolio";


const {Option, OptGroup} = Select;

// eslint-disable-next-line react/prop-types
const CloneForm = ({clonePortfolio, type}) => {
    const [idToClone, setIdToClone] = useState()
    /* for proposals: weight from client portfolio in percent */
    const [weight, setWeight] = useState(100)
    const [expandStrategies, setExpandStrategies] = useState(false)
    const [availablePortfolios, setAvailablePortfolios] = useState([])
    const [loading, setLoading] = useState(false);
    const [creationLoading, setCreationLoading] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false)

    useEffect(() => {
        const controller = new AbortController();

        setLoading(true);
        getModels(false, null, (portfolios) => {
            if (!controller.signal.aborted) {
                setAvailablePortfolios(portfolios);
                setLoading(false);
            }
        }, controller.signal);

        return () => {
            controller.abort();
        }
    }, []);

    const clone = (id) => {
        setCreationLoading(true);
        setShowConfirm(false);
        clonePortfolio(id, expandStrategies, weight, () => {
            setCreationLoading(false);
        });
    }

    const handleChange = (value) => {
        if (!value) {
            return
        }
        console.log(`selected ${value}`);
        setIdToClone(value)
        clone(value)
    }

    const onCheckChange = (e) => {
        console.log(`checked = ${e.target.checked}`)
        setExpandStrategies(e.target.checked)
    }

    const getOptions = (portfolios) => {
        return portfolios.map(d => <Option
            key={d._id}>{d.name} ({d.calculated_values?.risk?.risk_score})</Option>);
    }

    const weightChanged = (value) => {
        setWeight(value)
    }

    const ModelSelect = () => {
        return <Select
            loading={loading}
            showSearch={true}
            placeholder={'select the portfolio to clone or start typing to search'}
            onChange={handleChange}
            filterOption={(input, option) => {
                let fullName = option.children.join("").toLowerCase()
                return fullName.includes(input.toLowerCase())
            }}
            style={{width: '100%', marginRight: 24}}>
            {getOptions(availablePortfolios)}
        </Select>
    }

    const CloneSelector = ({type}) => {

        if (type == 'prop') {
            return <Row>
                <Col span={20}>
                    <ModelSelect/>
                </Col>
                <Col span={4}>
                    <Tooltip title="What part of the total client market value should be allocated to this portfolio?"
                             color={'gold'}>
                        <InputNumber
                            step={0.1}
                            min={1}
                            max={1000}
                            placeholder="weight"
                            name={"weight"}
                            value={weight}
                            addonAfter={"%"}
                            onChange={value => weightChanged(value)}
                        />
                    </Tooltip>
                </Col>
            </Row>
        } else {
            return <ModelSelect/>
        }
    }

    return (
        (<div>
            {!creationLoading ?
                <CloneSelector type={type}/>
                :
                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Spin size="large"></Spin>
                </div>
            }
            <Modal
                title="Confirm"
                open={showConfirm}
                onOk={() => clone(idToClone)}
                onCancel={() => {
                    setShowConfirm(false)
                }}
            >
                <p>{`Clone ${idToClone}?`}</p>
            </Modal>
        </div>)
    );
}

export default CloneForm;
