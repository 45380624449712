import React, {useState} from "react";
import {Button, Flex, message, Modal, theme, Tooltip, Typography} from "antd";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import {
    addPlaidItemAccounts,
} from "@API/clients";
import FakeProgress
    from "@components/retail/portfolio/constructor/FakeProgress";
import PlaidLinkLauncher
    from "@components/retail/portfolio/plaid/PlaidLinkLauncher";


const PlaidSelectAccountsLink = ({
                                     clientId,
                                     itemId,
                                     institutionName,
                                     buttonText = "Select linked accounts"
                                 }) => {
    const {fetchProposal} = useHolisticoProposalContext();
    const [ready, setReady] = useState(false);
    const [launchTrigger, setLaunchTrigger] = useState(0);
    const [openProgress, setOpenProgress] = useState(false);

    const onSuccess = () => {
        setOpenProgress(true);
        addPlaidItemAccounts(clientId, itemId, (response, error) => {
            if (error) {
                message.error("Error while updating account(s) from Plaid");
                console.debug(error);
            } else {
                fetchProposal();
            }
            setOpenProgress(false);
        })
    };

    return <>
        <Tooltip title={`Select accounts linked to ${institutionName}`}>
            <Button
                type={"link"}
                onClick={() => {
                    setLaunchTrigger(prev => prev + 1);
                }}
                disabled={!ready}
                style={{
                    padding: 0,
                }}
            >
                {buttonText}
            </Button>
        </Tooltip>
        <FakeProgress
            tip={"Updating accounts and portfolio analytics..."}
            open={openProgress}
            time={60000}
        />
        <PlaidLinkLauncher
            clientId={clientId}
            itemId={itemId}
            onSuccess={onSuccess}
            onReady={() => setReady(true)}
            launchTrigger={launchTrigger}
            accountSelectionEnabled={true}
        />
    </>
}

export default PlaidSelectAccountsLink;
