import React, {useState} from "react";
import {Flex, theme} from "antd";


const {useToken} = theme;


const HRadioGroupButton = ({
                               onClick,
                               selected = false,
                               size = "small",
                               selectedBg,
                               children
                           }) => {
    const [hover, setHover] = useState(false);
    const {token} = useToken();

    return <div
        onClick={onClick}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        style={{
            color: selected ? "white" : token.colorText,
            background: selected
                ? (selectedBg ?? token.colorRadioSelectedBg)
                : (hover ? "rgb(0,0,0,0.1)" : "rgb(0,0,0,0.03)"),
            borderRadius: 60,
            lineHeight: 1,
            padding: `${token.paddingXS}px ${token.paddingSM}px`,
            fontSize: size === "small" ? token.fontSizeSM - 1 : token.fontSize,
            cursor: "pointer"
        }}

    >
        {children}
    </div>
}


const HRadioGroup = ({
                         options,
                         value,
                         onChange,
                         size = "small",
                         selectedBg,
                         id,
                         style = {}
                     }) => {
    return <Flex gap={"small"} wrap={"wrap"} id={id} style={style}>
        {options.map(item => {
            return <HRadioGroupButton
                key={item.value}
                onClick={() => onChange(item.value)}
                selected={item.value === value}
                size={size}
                selectedBg={selectedBg}
            >
                {item.label}
            </HRadioGroupButton>
        })}
    </Flex>
}

export default HRadioGroup;
