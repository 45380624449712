import React, {useCallback, useState} from "react";
import {useRequest, useUpdateEffect} from "ahooks";
import {
    Button,
    Card,
    Form,
    Input,
    Spin,
    Upload,
    Typography,
    Flex,
    Tooltip,
    theme,
    message,
} from "antd";
import {
    CloudUploadOutlined,
    DeleteOutlined,
} from "@ant-design/icons";
import {editOrganization, getOrgAsync} from "@API/users";
import UsersList from "@components/admin_panel/users/UsersList";


const {useToken} = theme;


const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};


const OrganizationLogo = ({value, onChange}) => {
    const {token} = useToken();

    const handleChange = (info) => {
        if (info.file.status === 'done') {
            getBase64(info.file.originFileObj, (url) => onChange(url));
        }
    };

    const props = {
        multiple: false,
        showUploadList: false,
        customRequest: async ({onSuccess}) => {
            setTimeout(() => onSuccess(), 300);
        },
        onChange: handleChange,
    }

    return <>
        {value
            ? <Card
                type={"inner"}
                actions={[
                    <Tooltip title={"Upload from another file"} key={"upload"}>
                        <Upload {...props}>
                            <CloudUploadOutlined/>
                        </Upload>
                    </Tooltip>,
                    <Tooltip title={"Delete logo"} key={"delete"}>
                        <DeleteOutlined onClick={() => onChange(null)}/>
                    </Tooltip>
                ]}
                style={{
                    minWidth: 300,
                    width: "fit-content",
                    maxWidth: "100%",
                    overflow: "hidden",
                    borderColor: token.colorBorder
                }}
            >
                {/*Image breaks aspect ratio in Safari, use img instead*/}
                <img src={value} alt={"logo"} height={80}/>
            </Card>
            : <Upload.Dragger
                {...props}
                className={"ignore-holistico"}
                style={{
                    width: 500,
                    maxWidth: "100%",
                    padding: token.paddingXS,
                }}
            >
                <Flex align={"center"} gap={"middle"}>
                    <CloudUploadOutlined style={{
                        fontSize: token.fontSizeHeading1,
                        color: token.colorTextQuaternary,
                    }}/>
                    <Flex vertical align={"flex-start"}>
                        <Typography.Text>
                            Upload logo
                        </Typography.Text>
                        <Typography.Text type={"secondary"}>
                            Click or drag image to this area to upload
                        </Typography.Text>
                    </Flex>
                </Flex>
            </Upload.Dragger>
        }
    </>
}


const OrganizationDetails = ({id}) => {
    const [orgForm] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const {token} = useToken();

    const {
        data: organization,
        loading: loadingOrganization,
        refresh,
        error
    } = useRequest(() => getOrgAsync(id), {
        refreshDeps: [id]
    })

    useUpdateEffect(() => {
        orgForm.setFieldsValue(organization.data);
    }, [organization])

    const orgFormSave = useCallback((values) => {
        setLoading(true);
        editOrganization(id, values, (resp, error) => {
            setLoading(false);
            if (error) {
                message.error("Error while updating organisation details!");
            } else {
                message.success("Organisation details was updated successfully!");
                refresh();
            }
        })
    }, [id])

    return <Card style={{overflow: "hidden"}}>
        <Spin
            size="large"
            spinning={loading || loadingOrganization}
            style={{
                width: "100%"
            }}
        >
            {organization && <>
                <Typography.Title level={4} style={{marginTop: token.margin}}>
                    Organization details
                </Typography.Title>
                <Form
                    initialValues={organization}
                    form={orgForm}
                    name="org-details"
                    onFinish={orgFormSave}
                    requiredMark={false}
                    layout={"vertical"}
                    style={{
                        marginBottom: 2 * token.marginXXL,
                    }}
                >
                    <Form.Item
                        name={"logo"}
                        label={"Logo"}
                    >
                        <OrganizationLogo/>
                    </Form.Item>
                    <Form.Item
                        name={'name'}
                        label={"Name"}
                        rules={[{required: true}]}
                    >
                        <Input style={{width: 500, maxWidth: "100%"}}/>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Save
                        </Button>
                    </Form.Item>
                </Form>
                <UsersList holistico={true}/>
            </>}
        </Spin>
    </Card>
}

export default OrganizationDetails;
