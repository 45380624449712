import axiosInstance from "./APIClient";

const getSummaryReport = (configId, useClientConfig, handler, abortSignal) => {
    let url = `/supervision/stats/summary`;
    axiosInstance.get(url,
        {
            params: {
                config_id: configId,
                use_client_config: useClientConfig,
            },
            // cancelToken: new axios.CancelToken(function executor(c) {
            //     cancels.push(c);
            // })
            signal: abortSignal
        }).then(({data}) => {
        handler(data);
    })
        .catch(({response, cancel}) => {
            if (!cancel) {
                handler(null, true);
            }
        })
}

const downloadPDF = (configId, useClientConfig, handler, abortSignal) => {
    let url = `/supervision/pdf`;

    axiosInstance.get(url,
        {
            params: {
                config_id: configId,
                use_client_config: useClientConfig
            },
            responseType: "arraybuffer",
            signal: abortSignal
        }).then((response) => {
        handler(response);
    })
        .catch(({response}) => {
            console.log('wtf where did we get the error??')
            handler(response, true);
        })
}
const getFlagReport = (configId, useClientConfig, representative, handler, abortSignal) => {
    let url = `/supervision/flag`;

    if (representative === "") {
        representative = null;
    }

    axiosInstance.get(url,
        {
            params: {
                config_id: configId,
                use_client_config: useClientConfig,
                representative: representative
            },
            // cancelToken: new axios.CancelToken(function executor(c) {
            //     cancels.push(c);
            // })
            signal: abortSignal
        }).then(({data}) => {
        handler(data);
    })
        .catch(({response, cancel}) => {
            if (!cancel) {
                handler(null, true);
            }
        })
};

const getRepresentativesReport = (configId, useClientConfig, handler, abortSignal) => {
    let url = `/supervision/representative`;

    axiosInstance.get(url,
        {
            params: {config_id: configId, use_client_config: useClientConfig},
            signal: abortSignal
        }).then(({data}) => {
        handler(data, null);
    })
        .catch(({response, cancel}) => {
            if (!cancel) {
                handler(null, true);
            }
        })
};
const getClientLimitsReport = (configId, useClientConfig, handler, abortSignal) => {
    let url = `/supervision/client_limits`;

    axiosInstance.get(url,
        {
            params: {config_id: configId, use_client_config: useClientConfig},
            signal: abortSignal
        }).then(({data}) => {
        handler(data, null);
    })
        .catch(({response, cancel}) => {
            if (!cancel) {
                handler(null, true);
            }
        })
};

const getRepresentativesReportExcel = (configId, useClientConfig, handler, abortSignal) => {
    let url = `/supervision/excel/representative`;

    axiosInstance.get(url,
        {
            responseType: "arraybuffer",
            params: {config_id: configId, use_client_config: useClientConfig},
            signal: abortSignal
        }).then((response) => {
        handler(response, null);
    })
        .catch(({response, cancel}) => {
            if (!cancel) {
                handler(null, true);
            }
        })
};

const getClientReport = (configId, clientId, useClientConfig, handler, abortSignal) => {
    let url = `/supervision/client_report`;

    axiosInstance.get(url,
        {
            params: {
                config_id: configId,
                client_id: clientId,
                use_client_config: useClientConfig
            },
            // cancelToken: new axios.CancelToken(function executor(c) {
            //     cancels.push(c);
            // })
            signal: abortSignal
        }).then(({data}) => {
        handler(data);
    })
        .catch(({response, cancel}) => {
            if (!cancel) {
                handler(null, true);
            }
        })
};

const getRepresentatives = (handler, abortSignal) => {
    let url = `/supervision/representatives_list`;

    axiosInstance.get(url,
        {
            // cancelToken: new axios.CancelToken(function executor(c) {
            //     cancels.push(c);
            // })
            signal: abortSignal
        }).then(({data}) => {
        handler(data);
    })
        .catch(({response, cancel}) => {
            if (!cancel) {
                handler(null, true);
            }
        })
}

const searchClients = (name, handler, abortSignal) => {
    let url = `/supervision/search_clients`;

    axiosInstance.get(url,
        {
            params: {name: name},
            // cancelToken: new axios.CancelToken(function executor(c) {
            //     cancels.push(c);
            // })
            signal: abortSignal
        }).then(({data}) => {
        handler(data);
    })
        .catch(({response, cancel}) => {
            if (!cancel) {
                handler(null, true);
            }
        })
}

const setMute = (muteData, handler, abortSignal) => {
    let url = `/supervision/client_config/mute_flag`;

    axiosInstance.post(url, muteData,
        {
            // cancelToken: new axios.CancelToken(function executor(c) {
            //     cancels.push(c);
            // })
            signal: abortSignal
        }).then(({data}) => {
        handler(data);
    })
        .catch(({response, cancel}) => {
            if (!cancel) {
                handler(null, true);
            }
        })
}

const setMuteClient = (muteData, handler, abortSignal) => {
    let url = `/supervision/client_config/mute_client`;

    axiosInstance.post(url, muteData,
        {
            // cancelToken: new axios.CancelToken(function executor(c) {
            //     cancels.push(c);
            // })
            signal: abortSignal
        }).then(({data}) => {
        handler(data);
    })
        .catch(({response, cancel}) => {
            if (!cancel) {
                handler(null, true);
            }
        })
}

const setLimit = (limitData, handler, abortSignal) => {
    let url = `/supervision/client_config/set_limit`;

    axiosInstance.post(url, limitData,
        {
            // cancelToken: new axios.CancelToken(function executor(c) {
            //     cancels.push(c);
            // })
            signal: abortSignal
        }).then(({data}) => {
        handler(data);
    })
        .catch(({response, cancel}) => {
            if (!cancel) {
                handler(null, true);
            }
        })
}

const getGlobalConfigsDetails = (handler, abortSignal) => {
    let url = `/supervision/global_config_details`;

    axiosInstance.get(url,
        {
            // cancelToken: new axios.CancelToken(function executor(c) {
            //     cancels.push(c);
            // })
            signal: abortSignal
        }).then(({data}) => {
        handler(data);
    })
        .catch(({response, cancel}) => {
            if (!cancel) {
                handler(null, true);
            }
        })
}

const getGlobalConfigs = (handler, abortSignal) => {
    let url = `/supervision/configs`;

    axiosInstance.get(url,
        {
            // cancelToken: new axios.CancelToken(function executor(c) {
            //     cancels.push(c);
            // })
            signal: abortSignal
        }).then(({data}) => {
        handler(data);
    })
        .catch(({response, cancel}) => {
            if (!cancel) {
                handler(null, true);
            }
        })
}

const getGlobalConfig = (_id, handler, abortSignal) => {
    let url = `/supervision/config/${_id}`;

    axiosInstance.get(url,
        {
            // cancelToken: new axios.CancelToken(function executor(c) {
            //     cancels.push(c);
            // })
            signal: abortSignal
        }).then(({data}) => {
        handler(data);
    })
        .catch(({response, cancel}) => {
            if (!cancel) {
                handler(null, true);
            }
        })
}

const createGlobalConfig = (data, handler, abortSignal) => {
    let url = `/supervision/config`;

    axiosInstance.post(url, data,
        {
            // cancelToken: new axios.CancelToken(function executor(c) {
            //     cancels.push(c);
            // })
            signal: abortSignal
        }).then(({data}) => {
        handler(data);
    })
        .catch(({response, cancel}) => {
            if (!cancel) {
                handler(null, true);
            }
        })
}

const updateGlobalConfig = (_id, data, handler, abortSignal) => {
    let url = `/supervision/config/${_id}`;

    axiosInstance.put(url, data,
        {
            // cancelToken: new axios.CancelToken(function executor(c) {
            //     cancels.push(c);
            // })
            signal: abortSignal
        }).then(({data}) => {
        handler(data);
    })
        .catch(({response, cancel}) => {
            if (!cancel) {
                handler(null, true);
            }
        })
}

const deleteGlobalConfig = (_id, handler, abortSignal) => {
    let url = `/supervision/config/${_id}`;

    axiosInstance.delete(url,
        {
            // cancelToken: new axios.CancelToken(function executor(c) {
            //     cancels.push(c);
            // })
            signal: abortSignal
        }).then(({data}) => {
        handler(data);
    })
        .catch(({response, cancel}) => {
            if (!cancel) {
                handler(null, true);
            }
        })
}

const setFlagStatus = (satusData, handler, abortSignal) => {
    let url = `/supervision/client_config/set_flag_status`;

    axiosInstance.post(url, satusData,
        {
            signal: abortSignal
        }).then(({data}) => {
        handler(data);
    })
        .catch(({response, cancel}) => {
            if (!cancel) {
                handler(null, true);
            }
        })
}

const setClientStatus = (satusData, handler, abortSignal) => {
    let url = `/supervision/client_config/set_client_status`;

    axiosInstance.post(url, satusData,
        {
            signal: abortSignal
        }).then(({data}) => {
        handler(data);
    })
        .catch(({response, cancel}) => {
            if (!cancel) {
                handler(null, true);
            }
        })
}


const updateClientIssue = (action, issueData, handler, abortSignal) => {
    let url = `/supervision/client_config/${action}`;

    axiosInstance.post(url, issueData,
        {
            signal: abortSignal
        }).then(({data}) => {
        handler(data);
    }).catch(({response, cancel}) => {
        if (!cancel) {
            handler(null, true);
        }
    })
}

export {
    downloadPDF,
    getSummaryReport,
    getFlagReport,
    getClientReport,
    getRepresentativesReport,
    getClientLimitsReport,
    getRepresentatives,
    searchClients,
    setMute,
    setMuteClient,
    setLimit,
    getGlobalConfigsDetails,
    getGlobalConfig,
    getGlobalConfigs,
    createGlobalConfig,
    updateGlobalConfig,
    deleteGlobalConfig,
    getRepresentativesReportExcel,
    setFlagStatus,
    setClientStatus,
    updateClientIssue
}
