import React, {useEffect, useState} from "react";
import {Navigate, Route, Routes, useLocation, useNavigate, useParams} from "react-router-dom";
import {useRequest} from "ahooks";
import {Result, Skeleton, Tabs} from "antd";
import {getUserAsync} from "@API/users";
import {useAuth} from "@hooks/AuthHook";
import ProfileDetails from "@holistico/profile/ProfileDetails";
import UserActivity from "@components/admin_panel/users/UserActivity";
import OrganizationDetails from "@holistico/profile/OrganizationDetails";


const TAB_LABELS = {
    details: "User profile",
    organization: "Organization",
    activity: "Activity"
}


const ProfileLayout = ({}) => {
    const auth = useAuth()
    const {id} = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('details');
    const availableKeys = ['details', 'organization'];
    const MODE = process.env.REACT_APP_UI_VERSION;
    if(!MODE || !MODE.includes('holistico')) {
        availableKeys.push('activity');
    }

    const availableTabs = availableKeys.map(key => {
        return {
            label: TAB_LABELS[key] ?? key,
            key: key
        }
    })
    const {
        data: user,
        loading,
        error,
        refresh
    } = useRequest(() => getUserAsync(id), {
        refreshDeps: [id]
    })

    useEffect(() => {
        const pathSegments = location.pathname.split('/');
        const lastPath = pathSegments[pathSegments.length - 1];

        let newKey = lastPath
        if (!availableKeys.includes(lastPath)) {
            newKey = 'details'
        }
        console.log(`setting active tab to ${id}/${newKey}`)
        setActiveTab(newKey)
    }, [location])


    if (error) {
        return <Result status="403" title="Access Denied"/>
    }

    if (!user?.data) {
        return <Skeleton loading={loading}></Skeleton>
    }

    return <div className={"proposal-view"}>
        <div style={{width: '100%'}}>
            <Tabs activeKey={activeTab} onChange={(key) => {
                navigate(`${key}`)
            }} items={availableTabs}/>
            <Routes>
                <Route path="/" element={<Navigate to="details"/>}/>
                <Route path="/details" element={<ProfileDetails getUser={refresh} user={user.data}/>}/>
                <Route path="/activity" element={<UserActivity email={user.data.email}/>}/>
                <Route exact path="/organization" element={<OrganizationDetails id={user.data.organization_id}/>}/>
            </Routes>
        </div>
    </div>
}

export default ProfileLayout;
