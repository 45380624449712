import {Badge, ConfigProvider, List, Table, theme, Typography} from "antd";
import React from "react";
import ValueFormatter from "@global/ValueFormatter";


const {useToken} = theme;


const CustomTable = ({data}) => {
    if (!data || data.length === 0) {
        return null
    }
    let keys = Object.keys(data[0]);
    return <Table
        size={"small"}
        pagination={false}
        dataSource={data.map((row, idx) => ({_rowKey: idx, ...row}))}
        columns={keys.map(key => {
            return {
                title: key,
                key: key,
                dataIndex: key,
            }
        })}
        bordered
        rowKey={"_rowKey"}
    />
}


const PositionsTable = ({positions}) => {
    return <Table
        size={"small"}
        pagination={false}
        columns={[
            {
                title: 'Ticker',
                dataIndex: 'ticker',
                key: 'ticker',
            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
            }, {
                title: 'Weight',
                dataIndex: 'weight',
                key: 'weight',
                render: (value => ValueFormatter.percent(value))
            }, {
                title: 'Mk. Value',
                dataIndex: 'market_value',
                key: 'market_value',
                render: (value => ValueFormatter.compactCurrency(value))
            }
        ]}
        dataSource={positions}
        bordered
    />
}


const ExtendedPositionsTable = ({positions, additional_columns = []}) => {
    let columns = [
        {
            title: 'Ticker',
            dataIndex: 'ticker',
            key: 'ticker',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        }, {
            title: 'Weight',
            dataIndex: 'weight',
            key: 'weight',
            render: (value => ValueFormatter.percent(value))
        }, {
            title: 'Mk. Value',
            dataIndex: 'market_value',
            key: 'market_value',
            render: (value => ValueFormatter.compactCurrency(value))
        }
    ]

    for (let column of additional_columns) {
        columns.push({
            title: column.label,
            dataIndex: column.name,
            key: column.name,
            render: (value) => {
                if (column.units == '%') {
                    return ValueFormatter.int_percent(value)
                } else if (column.type == 'numeric') {
                    return ValueFormatter.number(value)
                } else {
                    return value
                }
            }
        })
    }

    return <Table
        size={"small"}
        pagination={false}
        columns={columns}
        dataSource={positions}
        bordered
    />
}


const MetricsResultDetails = ({details = null, style={}}) => {
    const {token} = useToken();

    return <div style={style}>
        <Typography.Text style={{fontWeight: token.fontWeightStrong}}>
            {details.name}
        </Typography.Text>
        <ConfigProvider theme={{
            components: {
                Table: {
                    fontSize: token.fontSizeSM
                },
            }
        }}>
            {details.type == 'positions' &&
                <PositionsTable positions={details.value}/>}
            {details.type == 'positions_extended' &&
                <ExtendedPositionsTable positions={details.value}
                                        additional_columns={details.additional_columns}/>}
            {details.type == 'table' && <CustomTable data={details.value}/>}
        </ConfigProvider>
    </div>
}

export default MetricsResultDetails;
