/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {Result, Spin} from "antd";

import {useNavigate, useParams} from 'react-router-dom';
import {loadFullClient} from '@API/clients';

import ClientInfo from "./ClientInfo";
import ClientRoutes from "@components/client/ClientRoutes";
import {SpasClientContext} from "@components/client/contexts/SpasClientContext";

const SpasClient = ({}) => {
    const {id} = useParams();
    const [loading, setLoading] = useState(false);
    const [resultData, setResultData] = useState({message: '', status: 'info', title: ''})
    const [infoVisible, setInfoVisible] = useState(true)
    const [client, setClient] = useState(null)
    let navigate = useNavigate()

    useEffect(() => {
        sessionStorage.setItem("clientId", id);
        fetchData();

        return () => {
            if (sessionStorage.getItem("clientId")) {
                sessionStorage.removeItem("clientId");
            }
        }
    }, []);
    
    

    const fetchData = (hard = false, with_loadig = true) => {
        with_loadig && setLoading(true);
        loadFullClient(id, (clientData, error) => {
            if (error) {
                console.log(clientData)
                setResultData({message: clientData['message'], status: 'error', title: 'Access denied!'})
                with_loadig && setLoading(false);
                return
            }
            setClient(clientData);
            with_loadig && setLoading(false);
            if (hard == true) {
                navigate(`/spas/proposal/${clientData._id}`)
            }
        })
    }

    return (
        <Spin style={{height: 800}} spinning={loading} tip="Loading the client..." size={"large"}>
            {client ? <SpasClientContext.Provider value={{
                client: client,
                loadData: fetchData,
                calculated_values: client?.p_bucket_cur?.calculated_values,
                proposed_calculated_values: client?.p_bucket_prop?.calculated_values,
                market_assumptions: client?.p_bucket_cur?.market_assumptions
            }}>
                <ClientInfo client={client} loading={loading}/>
                {client ? <ClientRoutes client={client}/> : null}
            </SpasClientContext.Provider>: <Result style={{background: '#fff'}} title={resultData.title} status={resultData.status} subTitle={resultData.message}/>}

        </Spin>
    );
}

export default SpasClient
