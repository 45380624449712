const getInversionsCount = (arr) => {
    let inv_count = 0;
    for(let i = 0; i < arr.length - 1; i++) {
        for(let j = i + 1; j < arr.length; j++) {
            if(arr[i] > arr[j]) inv_count++;
        }
    }
    return inv_count;
}

const placeSections = (a, b, c, defaultOpt=null) => {
    if (a.length === 0 || b.length === 0) {
        return
    }

    const im = Math.floor(a.length / 2)
    let inversionsOpt = -1
    let iOpt = 0
    for (let i of [...Array(b.length + 1).keys()]) {
        const b_with_a_im = b.slice(0, i).concat([a[im]], b.slice(i));
        let inversions = getInversionsCount(b_with_a_im)
        if (inversionsOpt === -1 || inversions < inversionsOpt) {
            inversionsOpt = inversions
            iOpt = i
        }
    }
    c[a[im]] = b[iOpt] ?? defaultOpt
    // go to the left
    if (c[a[im]]) {
        placeSections(a.slice(0, im), b.slice(0, iOpt + 1), c, c[a[im]])
    } else {
        placeSections(a.slice(0, im), b, c, c[a[im]])
    }
    // go to the right
    if (c[a[im]]) {
        placeSections(a.slice(im + 1), b.slice(iOpt), c, defaultOpt)
    }
}

const sortSections = (sections, selected_keys) => {
    // Algorithm source: https://cs.stackexchange.com/questions/140295/complexity-to-insert-subset-of-array-to-minimize-order-inversions
    if (!sections || !selected_keys) {
        return sections
    }

    // Define place of `selected_keys` in `sections`
    const section_indexes = sections.map((section, index) => index)
    const section_keys = sections.map(section => section.key)
    let a = selected_keys.filter(key => section_keys.includes(key)).map(key => section_keys.indexOf(key))
    let b = section_indexes.filter(key => !a.includes(key))
    let c = Object.fromEntries(a.map(key => [key, null]))
    placeSections(a, b, c)

    // Place of `selected_keys` inside `sections` according to found places
    let sorted_indexes = b
    for (let ai of a) {
        if (c[ai]) {
            sorted_indexes.splice(sorted_indexes.indexOf(c[ai]), 0, ai);
        } else {
            sorted_indexes.push(ai)
        }
    }
    return  sorted_indexes.map(i => sections[i])
}

export default sortSections;
