/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {Button, Form, message, Progress, Space, Table, Typography} from 'antd';

import {cancel, getModels} from "@API/portfolio";
import {getPerformanceExcel} from '@API/allocation';
import ChartStyles from "@global/ChartStyles";
import ValueFormatter from "@global/ValueFormatter";
import {FileExcelOutlined} from "@ant-design/icons";
import {green} from '@ant-design/colors';
import {downloadFile} from "@global/FileDownloader";

const RETURNS_KEY = 'total_returns'

const DEFAULT_COLUMNS = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (name, record) => {
            let text = name
            if (record.ticker) {
                text += " (" + record.ticker.replace("#", "") + ")"
            }
            return <b>{text}</b>
        },
        sorter: (a, b) => ('' + a.name).localeCompare(b.name),
        sortDirections: ['descend', 'ascend'],
    },
    {
        title: 'Risk Score',
        dataIndex: 'risk_score',
        key: 'risk_score',
        align: 'right',
        render: (value, record) => {
            let score = record['calculated_values']['risk']['risk_score']
            return <b>{score}</b>
        },
        onCell: (record, rowIndex) => {
            let score = record['calculated_values']['risk']['risk_score']
            return {}
        },
        sorter: (a, b) => a['calculated_values']['risk']['risk_score'] - b['calculated_values']['risk']['risk_score'],
        sortDirections: ['descend', 'ascend'],
    }, {
        title: 'Distr. Rate',
        dataIndex: 'annual_dividend',
        key: 'annual_dividend',
        align: 'right',
        render: (value, record) => {
            let div = record['calculated_values']['annual_dividend']
            return <b>{ValueFormatter.percent(div/100, '-')}</b>
        },
        sorter: (a, b) => a['calculated_values']['annual_dividend'] - b['calculated_values']['annual_dividend'],
        sortDirections: ['descend', 'ascend'],
    },
]
const PERFORMANCE_COLUMNS = [
    {
        title: '3 mo.',
        dataIndex: 'total_return_1q',
        key: 'total_return_1q',
        align: 'right',
        render: (value, record) => {
            return performanceRender('total_return_1q', record)
        },
        onCell: (rec, rowIndex) => performanceFormat(rec, rowIndex, 'total_return_1q'),
        sortDirections: ['descend', 'ascend'],
    }, {
        title: '6 mo.',
        dataIndex: 'total_return_2q',
        key: 'total_return_2q',
        align: 'right',
        render: (value, record) => {
            return performanceRender('total_return_2q', record)
        },
        onCell: (rec, rowIndex) => performanceFormat(rec, rowIndex, 'total_return_2q'),
        sortDirections: ['descend', 'ascend'],
    }, {
        title: '12 mo.',
        dataIndex: 'total_return_1yr',
        key: 'total_return_1yr',
        align: 'right',
        render: (value, record) => {
            return performanceRender('total_return_1yr', record)
        },
        onCell: (rec, rowIndex) => performanceFormat(rec, rowIndex, 'total_return_1yr'),
        sortDirections: ['descend', 'ascend'],
    }, {
        title: 'YTD',
        dataIndex: 'total_return_ytd',
        key: 'total_return_ytd',
        align: 'right',
        render: (value, record) => {
            return performanceRender('total_return_ytd', record)
        },
        onCell: (rec, rowIndex) => performanceFormat(rec, rowIndex, 'total_return_ytd'),
        sortDirections: ['descend', 'ascend'],
    }
]

const assetClasses = ['US Equity', 'Non-US Equity', 'US Bond', 'Non-US Bond', 'Cash', 'Other']
const renderWeight = (weight) => {
    let formattedValue = ValueFormatter.softPercent(weight, 0)
    let span = <span style={{color: '#e7e7e7'}}>{formattedValue}</span>
    if (weight > 0) {
        span = <span>{formattedValue}</span>
    }
    return <Progress percent={weight * 100} steps={5} size="small"
                     showInfo={true}
                     strokeColor={green[6]}
                     format={value => span}
    />
}

const styleWeight = (weight) => {
    let props = {}
    if (!weight) {
        props['style'] = {
            color: 'gray'
        }
    }
    return props
}
const performanceRender = (propName, record) => {
    if (!record?.calculated_values.hasOwnProperty(RETURNS_KEY)) {
        return <div>-</div>
    }
    let value = record['calculated_values']['total_returns'][propName]

    let formattedValue = ValueFormatter.int_percent(value, '-')
    return <div>{formattedValue}</div>
}
const performanceFormat = (record, rowIndex, propName) => {
    let props = {}
    if (!record?.calculated_values.hasOwnProperty(RETURNS_KEY)) {
        return props
    }
    let value = record['calculated_values']['total_returns'][propName]


    if (isNaN(value)) {
        return {}
    }

    let color = ChartStyles.intPerformance2color(value)
    if (color) {
        props = {style: {background: color}}
    }
    return props
}

const AllocationPerformances = () => {
    const [portfolios, setPortfolios] = useState([]);
    // const [definitionsIds, setDefinitionsIds] = useState([])
    const [columns, setColumns] = useState([])
    const [loading, setLoading] = useState(true);
    const [excelLoading, setExcelLoading] = useState(false);
    // const [searchString, setSearchString] = useState(null)

    useEffect(() => {
        fetchData(null);
        let assetClassColumns = assetClasses.map(assetClass => {
            return {
                title: assetClass,
                dataIndex: assetClass,
                key: assetClass,
                render: (value, record) => {
                    let weight = record['calculated_values']['asset_class'][assetClass]
                    return renderWeight(weight)
                },
                onCell: (record, rowIndex) => {
                    let weight = record['calculated_values']['asset_class'][assetClass]
                    return styleWeight(weight)
                    return {}
                },
                align: 'right',
                sorter: (a, b) => a['calculated_values']['asset_class'][assetClass] - b['calculated_values']['asset_class'][assetClass],
                sortDirections: ['descend', 'ascend'],
            }
        })

        setColumns(DEFAULT_COLUMNS.concat(assetClassColumns).concat(PERFORMANCE_COLUMNS))

        return () => {
            cancel && cancel()
        }
    }, []);

    const fetchData = (search) => {
        const controller = new AbortController();
        setLoading(true)
        getModels(true, null, (portfolios, error) => {
            if (!controller.signal.aborted) {
                setPortfolios(portfolios);
            }
        }, controller.signal);

        return () => {
            controller.abort();
        }
    }

    const download = () => {
        setExcelLoading(true);
        getPerformanceExcel((response, error) => {
            if (error) {
                message.error("Error while downloading performance excel");
                setExcelLoading(false);
                return
            }
            let file_type = response.headers['content-type'];
            let file_name = `SPAS Performances (${new Date().toLocaleString()}).xlsx`;
            downloadFile(response.data, file_name, file_type, () => {
                setExcelLoading(false);
            })
        })
    }

    // const selectModels = () => {
    //     console.info("select models clicked")
    // }

    return (
        <div>
            <Typography.Title level={4} className={'spas'}>Portfolio performance</Typography.Title>
            <Space direction={'horizontal'}>
                <Form.Item>
                    <Button type={'dashed'} onClick={() => download()}
                            icon={<FileExcelOutlined style={{color: "green"}}/>}
                            disabled={loading} loading={excelLoading}
                    >
                        Download
                    </Button>
                </Form.Item>
            </Space>
            <Table
                columns={columns}
                dataSource={portfolios}
                loading={loading}
                rowKey={"_id"}
                size={"small"}
                pagination={{defaultPageSize: 300, hideOnSinglePage: true, pageSize: 300, position: 'topRight'}}
                scroll={{
                    y: "55vh"
                }}
            />
        </div>
    );
}

export default AllocationPerformances;
