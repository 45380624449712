/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import {
    Alert,
    Button,
    Card,
    Divider,
    Flex,
    message,
    Modal,
    Spin,
    theme
} from 'antd';
import {orionRefreshMany} from "@API/portfolio";
import ExpandablePortfolioRow
    from "@components/portfolio/table/ExpandablePortfolioRow";
import PortfolioStats from "@components/portfolio/PortfolioStats";
import PortfolioWeighter
    from "@components/holistico/portfolio/weighter/PortfolioWeighter";
import AddPortfolioButton
    from "@components/holistico/portfolio/new/AddPortfolioButton";
import NoDataPlaceholder from "@holistico/aux/NoDataPlaceholder";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import {
    PortfolioListContext
} from "@components/portfolio/contexts/PortfolioListContext";
import {
    clientPortfolioClone,
    clientPortfolioFromOrion,
    clientProposalsReallocate,
    createEmptyPortfolio,
    createFromFile,
    deletePortfolio
} from "@API/clientPortfolio";
import NestedCard from "@components/retail/cards/NestedCard";
import CardHeader from "@components/retail/cards/CardHeader";
import "./PortfolioComposition.less"
import MarketAssumptions
    from "@holistico/proposal/view/assumptions/MarketAssumptions";


const {useToken} = theme;


const PortfolioComposition = ({bucketType, title="Current portfolio"}) => {
    const {proposal, fetchProposal} = useHolisticoProposalContext()
    const [loadingPortfolios, setLoadingPortfolios] = useState(false);
    const [lockActions, setLockActions] = useState(false);
    const [openWeighter, setOpenWeighter] = useState(false);
    const {token} = useToken();

    const calculatedValues = bucketType === 'prop'
        ? proposal?.p_bucket_prop?.calculated_values
        : proposal?.p_bucket_cur?.calculated_values
    const portfolios = bucketType === 'prop'
        ? proposal?.proposed_portfolios
        : proposal?.current_portfolios

    const clientMarketValue = bucketType === 'prop'
        ? proposal?.p_bucket_prop?.calculated_values['market_value']
        : proposal?.p_bucket_cur?.calculated_values['market_value']

    if (calculatedValues?.market_value > 0) {
        for (let portfolio of portfolios) {
            portfolio.weight = portfolio['calculated_values']['market_value'] / clientMarketValue
        }
    }
    const weighterAvailable = bucketType === 'prop' && portfolios && portfolios.length > 1
    const hasOrionPortfolios = portfolios && portfolios.some(item => item.external_id !== null)

    const addFromFile = (formData, callback) => {
        setLockActions(true);
        // setLoadingPortfolios(true);
        createFromFile(proposal._id, formData, (result, error) => {
            if (!error) {
                message.success('Portfolio was added successfully!');
                fetchProposal();
            } else {
                result && message.error(result.data.message);
            }
            if (callback) {
                callback()
            }
            // setLoadingPortfolios(false);
            setLockActions(false);
        })
    }

    const addEmpty = (portfolioName, callback) => {
        setLockActions(true);
        // setLoadingPortfolios(true);
        let postData = {name: portfolioName, type: bucketType}
        createEmptyPortfolio(proposal._id, postData, (result, error) => {
            if (!error) {
                message.success('Portfolio was added successfully!');
                fetchProposal();
            } else {
                result && message.error('Portfolio creation failed!');
            }
            if (callback) {
                callback();
            }
            // setLoadingPortfolios(false);
            setLockActions(false);
        })
    }

    const addFromOrion = (orion_id, callback) => {
        setLockActions(true);
        // setLoadingPortfolios(true);
        clientPortfolioFromOrion(proposal._id, orion_id, bucketType, (result, error) => {
            if (!error) {
                message.success('Portfolio was added successfully!');
                fetchProposal()
            } else {
                result && message.error(result.data.message);
            }
            if (callback) {
                callback();
            }
            // setLoadingPortfolios(false);
            setLockActions(false);
        })
    }

    const addFromOther = (id, expand_portfolio_positions = false, weight = null, callback = () => {
    }) => {
        setLockActions(true);
        // setLoadingPortfolios(true);
        let cloneMarketValue = null
        /*if (weight) {
            if (calculatedValues?.market_value) {
                cloneMarketValue = calculatedValues['market_value'] * (weight / 100)
            }
        } else */
        if (bucketType === 'prop') {
            cloneMarketValue = proposal?.p_bucket_cur?.calculated_values?.market_value ?? null
        }
        clientPortfolioClone(proposal._id, id, bucketType, expand_portfolio_positions, cloneMarketValue, (result, error) => {
            if (!error) {
                message.success('Portfolio was added successfully!');
                fetchProposal()
            } else {
                result && message.error(result.data.message);
            }
            if (callback) {
                callback();
            }
            // setLoadingPortfolios(false);
            setLockActions(false);
        })
    }

    const removeOne = (id, callback) => {
        setLockActions(true);
        // setLoadingPortfolios(true);
        deletePortfolio(proposal._id, bucketType, id, (result, error) => {
            if (!error) {
                message.success("Portfolio was deleted successfully");
                fetchProposal();
            } else {
                console.log(result)
                result && message.error(result.data.message);
            }
            if (callback) {
                callback();
            }
            // setLoadingPortfolios(false);
            setLockActions(false);
        })
    }

    const submitPortfolioWeights = (save, values) => {
        setOpenWeighter(false)
        if (save) {
            setLockActions(true);
            setLoadingPortfolios(true);
            clientProposalsReallocate(proposal._id, values, (result, error) => {
                if (!error) {
                    message.success("Portfolio weights were updated successfully");
                    fetchProposal();
                } else {
                    result && message.error('Error occurred while reallocating!');
                }
                setLockActions(false);
                setLoadingPortfolios(false);
            })
        }
    }

    const updateAllOrionPortfolios = () => {
        const orionPortfolios = portfolios.filter(portfolio => portfolio.external_id !== null).map(portfolio => {
            return {
                portfolio_id: portfolio._id,
                orion_id: portfolio.external_id
            }
        })
        if (orionPortfolios.length > 0) {
            setLockActions(true);
            setLoadingPortfolios(true);
            orionRefreshMany(orionPortfolios, (result, error) => {
                if (!error) {
                    fetchProposal();
                } else {
                    result && message.error('Error occurred while updating the portfolios!');
                }
                setLoadingPortfolios(false);
                setLockActions(false);
            })
        } else {
            message.warning("No portfolios to update!")
        }
    }

    const openConfirmForUpdateAllOrionPortfolios = () => {
        Modal.confirm({
            title: 'Refresh',
            content: 'This will reload the portfolios from Orion and can take about a minute.',
            onOk: () => {
                updateAllOrionPortfolios()
            }
        });
    }

    return <Card hoverable style={{
        cursor: "default",
        background: token.colorBgGrey,
        borderColor: "rgb(220, 220, 220)",
        overflow: "hidden"
    }}>
        <PortfolioListContext.Provider value={{
            addFromOther: addFromOther,
            addFromOrion: addFromOrion,
            addEmpty: addEmpty,
            addFromFile: addFromFile,
            removeOne: removeOne,
            reloadContext: () => fetchProposal(),
            lockActions: lockActions
        }}>
            <Spin spinning={loadingPortfolios}
                  tip="Updating portfolios..."
                  size={"large"}
                  style={{height: 800}}
            >
                <NestedCard background={"white"}>
                    <CardHeader title={title}/>
                    <Flex gap={"small"} wrap={"wrap"}>
                        <AddPortfolioButton bucketType={bucketType}
                                            disabled={lockActions}
                                            style={{width: 200}}
                        />
                        {weighterAvailable
                            &&
                            <Button disabled={lockActions}
                                    onClick={() => setOpenWeighter(true)}
                                    style={{width: 200}}
                            >
                                Reallocate capital
                            </Button>
                        }
                        {hasOrionPortfolios
                            &&
                            <Button disabled={lockActions}
                                    onClick={openConfirmForUpdateAllOrionPortfolios}
                                    style={{width: 200}}
                            >
                                Refresh Orion portfolios
                            </Button>
                        }
                        <MarketAssumptions
                            title={"Change market assumptions"}
                            disabled={lockActions}
                        />
                    </Flex>
                    <Divider/>
                    <PortfolioStats calculated_values={calculatedValues}/>
                    {bucketType === 'prop'
                        && proposal.p_bucket_cur?.calculated_values?.market_value
                        && proposal.p_bucket_prop?.calculated_values?.market_value
                        && Math.abs(proposal.p_bucket_cur?.calculated_values?.market_value - proposal.p_bucket_prop?.calculated_values?.market_value) > 10
                        &&
                        <Alert
                            message="Warning"
                            description={<>
                                Proposed portfolio market value does not match
                                client's current portfolio market value.
                                To fix this warning, you can manually change
                                the market value of the portfolio(s) below.
                            </>}
                            type="warning"
                            showIcon
                            style={{marginTop: token.marginLG}}
                        />
                    }
                </NestedCard>
                {portfolios?.length > 0
                    ? portfolios.map((portfolio, index) => {
                        return <ExpandablePortfolioRow
                            key={portfolio._id}
                            portfolioInfo={portfolio}
                            type={bucketType}/>
                    })
                    :
                    <NoDataPlaceholder
                        description="No portfolios found..."/>
                }
            </Spin>
        </PortfolioListContext.Provider>
        {weighterAvailable
            &&
            <PortfolioWeighter
                visible={openWeighter}
                existingPortfolios={portfolios}
                clientTotal={proposal?.p_bucket_cur?.calculated_values.market_value}
                doneCallback={submitPortfolioWeights}
            />
        }
    </Card>
}

export default PortfolioComposition;
