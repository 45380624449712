import React, {useEffect, useState} from "react";
import {Checkbox} from "antd";


const ClientConfigCheckbox = ({setUseClientConfig}) => {
    const [checked, setChecked] = useState(true);

    useEffect(() => {
        const valueFromUrl = new URLSearchParams(window.location.search).get("useClientConfig");
        if (valueFromUrl) {
            setChecked(valueFromUrl === "true");
            setUseClientConfig(valueFromUrl === "true");
        } else {
            setChecked(true);
            setUseClientConfig(true);
            setToSearchParams(true);
        }
    }, []);

    const setToSearchParams = (checked) => {
        const url = new URL(window.location.href);
        url.searchParams.set('useClientConfig', checked);
        window.history.pushState(null, '', url.toString());
    }

    return <Checkbox
        checked={checked}
        onChange={(e) => {
            setChecked(e.target.checked);
            setToSearchParams(e.target.checked);
            setUseClientConfig(e.target.checked);
        }}
    >
        Use clients' limits
    </Checkbox>
}

export default ClientConfigCheckbox;
