import React, {useEffect, useState} from "react";
import {Card, List, Typography, theme, Tag, Flex, Affix, Button} from "antd";
import Localizator from "@global/Localizator";
import moment from "moment";
import NoDataPlaceholder from "@holistico/aux/NoDataPlaceholder";
import {RightOutlined} from "@ant-design/icons";
import MobileDrawer from "@components/retail/cards/MobileDrawer";


const {useToken} = theme;


const ActionLogContent = ({client, limit=null}) => {
    const [logs, setLogs] = useState([]);
    const {token} = useToken();

    useEffect(() => {
        const _logs = (client?.config_log ?? []).toReversed();
        if (limit) {
            setLogs(_logs.filter((_, idx) => idx < limit));
        } else {
            setLogs(_logs);
        }
    }, [client, limit]);

    return <List
        dataSource={logs}
        renderItem={(item, idx) => {
            const userName = item.user_name ?? item.user ?? "Someone";
            const actionType = item.action.type + (["set_status", "set_client_status"].includes(item.action.type)  ? "." + item.action.status : "");

            return <List.Item key={idx} style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                paddingBottom: token.paddingLG
            }}>
                <Typography.Text type={"secondary"} style={{
                    fontSize: token.fontSizeSM,
                    marginBottom: token.marginXS
                }}>
                    {moment(item.timestamp).format("MMMM DD, YYYY, hh:mm A")}
                </Typography.Text>
                <Typography.Text strong>
                    {Localizator.t("supervision.action." + actionType)}
                    {item.action.type === "set_limit" && ` of ${item.action.value}`}
                </Typography.Text>
                {item.action.comment
                    && <Typography.Paragraph style={{marginBottom: token.marginXS}}>
                        {item.action.comment}
                    </Typography.Paragraph>
                }
                <Flex wrap={"wrap"}>
                    <Tag style={{marginBottom: token.marginXS}}>
                        {item.action.flag_key
                            ? Localizator.t("supervision.flag.full_name." + item.action.flag_key).toLowerCase()
                            : "all flags"
                        }
                    </Tag>
                    <Tag style={{marginBottom: token.marginXS}}>
                        {"by " + userName}
                    </Tag>
                </Flex>
            </List.Item>
        }}
        locale={{
            emptyText: <NoDataPlaceholder description={"No history found"}/>
        }}
    />
}


export const ActionLogDrawer = ({client, open, setOpen}) => {
    const {token} = useToken();

    return <MobileDrawer
        title={"History"}
        open={open}
        onClose={() => {
            setOpen(false);
        }}
        width={600}
        style={{background: token.colorBgBase}}
    >
        <ActionLogContent client={client}/>
    </MobileDrawer>
}


const ActionLog = ({client}) => {
    const [openDrawer, setOpenDrawer] = useState(false);

    const limit = 5;

    return <Card type={"inner"}>
        <Typography.Title level={3} style={{marginTop: 0}}>
            History
        </Typography.Title>
        <ActionLogContent client={client} limit={limit}/>
        {(client?.config_log ?? []).length > limit &&
            <Button
                onClick={() => setOpenDrawer(true)}
                type={"link"}
                style={{padding: 0}}
            >
                Show full history
                <RightOutlined/>
            </Button>
        }
        <ActionLogDrawer
            client={client}
            open={openDrawer}
            setOpen={setOpenDrawer}
        />
    </Card>
}

export default ActionLog;
