import React, {useState, useEffect, useCallback} from "react";
import {message} from "antd";
import {usePlaidLink} from "react-plaid-link";
import {getPlaidLinkToken} from "@API/clients";


const PlaidLinkLauncher = ({
                               clientId,
                               itemId,
                               onReady,
                               onSuccess,
                               launchTrigger,
                               accountSelectionEnabled = false
                           }) => {
    const [linkToken, setLinkToken] = useState(null);

    const createLinkToken = useCallback(async () => {
        if (window.location.href.includes("?oauth_state_id=")) {
            // For OAuth, use previously generated Link token
            const _linkToken = localStorage.getItem('link_token');
            setLinkToken(_linkToken);
        } else {
            getPlaidLinkToken(clientId, itemId, accountSelectionEnabled, (response, error) => {
                if (error) {
                    message.error("Error while creating Plaid link");
                } else {
                    setLinkToken(response.link_token);
                    localStorage.setItem("link_token", response.link_token);
                }
            })
        }
    }, [setLinkToken]);

    let isOauth = false;
    const config = {
        token: linkToken,
        onSuccess: onSuccess,
    };
    // For OAuth, configure the received redirect URI
    if (window.location.href.includes("?oauth_state_id=")) {
        config.receivedRedirectUri = window.location.href;
        isOauth = true;
    }
    const {open, ready} = usePlaidLink(config);

    useEffect(() => {
        if (linkToken === null) {
            createLinkToken();
        }
        if (isOauth && ready) {
            open();
        }
    }, [linkToken, isOauth, ready, open]);

    useEffect(() => {
        if (ready) {
            onReady?.();
        }
    }, [ready]);

    useEffect(() => {
        if (launchTrigger) {
            open();
        }
    }, [launchTrigger]);

    return <></>
}


export default PlaidLinkLauncher;
