import React, {useContext, useState} from "react";
import {
    Alert,
    Badge,
    Button,
    Flex, message, Modal,
    Tag,
    theme, Tooltip,
    Typography
} from 'antd';
import {
    CheckCircleFilled,
    CloseCircleFilled,
    ExclamationCircleFilled,
    RightOutlined
} from "@ant-design/icons";
import {SupervisionContext} from "../contexts/SupervisionContext";
import IssueStatusModal from "@components/supervision/client/IssueStatusModal";
import {
    formatterMapper
} from "@components/supervision/report_constructor/tree_utils/utils";
import MetricsResultDetails
    from "@components/supervision/client/MetricsResultDetails";
import LimitModal from "@components/supervision/actions/form/LimitModal";
import {setLimit} from "@API/supervision";
import {is_null} from "@global/ValueFormatter";
import moment from "moment/moment";


const {useToken} = theme;


const ClientFlagCard = ({client, item, configsDetails}) => {
    const {updateData} = useContext(SupervisionContext);
    const [hover, setHover] = useState(false);
    const [expand, setExpand] = useState(false);
    const [openProgressModal, setOpenProgressModal] = useState(false);
    const [openResolvedModal, setOpenResolvedModal] = useState(false);
    const [openClearStatusModal, setOpenClearStatusModal] = useState(false);
    const [openLimitModal, setOpenLimitModal] = useState(false);
    const {token} = useToken();

    const metricFormatter = formatterMapper(item.metric.units);
    const metricValue = metricFormatter(item.metric.value, item.metric.decimals_precision);
    const globalLimitValue = metricFormatter(item.limit.global, item.metric.decimals_precision);
    const clientLimitValue = metricFormatter(item.limit.client, item.metric.decimals_precision);

    const saveClientLimit = (values) => {
        let limitData = {
            client_id: client.id,
            flag_key: item.red_flag_key,
        }
        if (values) {
            limitData.is_set = true
            limitData.comment = values.comment;
            limitData.value = values.limit_value;
        } else {
            limitData.is_set = false
        }
        setLimit(limitData, (resp, error) => {
            if (error) {
                message.error("Error while setting the limit!");
            } else {
                message.success(`The limit for ${item.red_flag_name} was updated successfully`);
            }
            setOpenLimitModal(false)
            updateData();
        })
    }

    return <div style={{
        border: `1px solid ${token.colorBorder}`,
        borderRadius: token.borderRadiusLG,
        width: "100%",
        overflow: "hidden",
        boxShadow: "rgba(0, 0, 0, 0.05) -2px 2px 4px 0px",
    }}>
        <Flex
            justify={"space-between"}
            align={"baseline"}
            gap={"middle"}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={() => setExpand(!expand)}
            style={{
                padding: token.padding,
                background: hover ? token.colorBgGrey : "none",
                cursor: "pointer",
            }}
        >
            <Flex gap={"middle"} align={"baseline"}>
                <RightOutlined rotate={expand ? 90 : 0}/>
                <Typography.Text style={{
                    fontSize: token.fontSizeLG,
                    fontWeight: token.fontWeightStrong
                }}>
                    {item.red_flag_name}
                </Typography.Text>
            </Flex>
            <Flex wrap={"wrap"} justify={"flex-end"} gap={"small"}>
                {item.limit.type === "client"
                    && <Tooltip title={"Client's custom limit is used"}>
                        <Tag color={"warning"} icon={<ExclamationCircleFilled/>}>
                            CUSTOM LIMIT
                        </Tag>
                    </Tooltip>
                }
                {!item.red_flag_raised
                    && <Tooltip title={"Everything is on track!"}>
                        <Tag color={"success"} icon={<CheckCircleFilled/>}>
                            PASSED
                        </Tag>
                    </Tooltip>
                }
                {item.red_flag_raised && item.status.status === "unaddressed"
                    && <Tooltip title={"The check failed!"}>
                        <Tag color={"error"} icon={<CloseCircleFilled/>}>
                            FAILED
                        </Tag>
                    </Tooltip>
                }
                {item.red_flag_raised && item.status.status === "in_progress"
                    && <Tooltip title={"The issue is in progress"}>
                        <Tag color={"processing"} icon={<CheckCircleFilled/>}>
                            IN PROGRESS
                        </Tag>
                    </Tooltip>
                }
                {item.red_flag_raised && item.status.status === "resolved"
                    && <Tooltip title={"The issue is marked as resolved"}>
                        <Tag color={"processing"} icon={<CheckCircleFilled/>}>
                            RESOLVED
                        </Tag>
                    </Tooltip>
                }
            </Flex>
        </Flex>
        {expand
            && <div style={{
                padding: `${token.paddingLG}px ${token.padding}px ${token.paddingLG}px 44px`,
                borderTop: `1px solid ${token.colorBorder}`,
            }}>
                <div style={{marginBottom: token.marginXL}}>
                    <Flex gap={"middle"} align={"baseline"} style={{
                        marginBottom: token.marginXXS
                    }}>
                        <Tooltip title={item.metric.name + " for the client's portfolio"}>
                            <div style={{
                                background: token.colorBgGrey,
                                padding: `${token.paddingXXS}px ${token.paddingSM}px`,
                                borderRadius: token.borderRadius,
                            }}>
                                <Typography.Text style={{
                                    color: item.red_flag_raised ? token.colorError : token.colorSuccess,
                                    fontSize: token.fontSizeHeading3,
                                    // fontWeight: token.fontWeightStrong,
                                    lineHeight: 1.2,

                                }}>
                                    {metricValue}
                                </Typography.Text>
                            </div>
                        </Tooltip>
                        <Typography.Text style={{
                            color: token.colorTextDescription,
                            fontSize: token.fontSizeHeading3,
                            // fontWeight: token.fontWeightStrong,
                            lineHeight: 1.2
                        }}>
                            {item.red_flag_raised
                                ? (item.metric.low_is_good ? ">" : "<")
                                : (item.metric.low_is_good ? "≤" : "≥")
                            }
                        </Typography.Text>
                        <Tooltip title={item.limit.description}>
                            <div style={{
                                background: token.colorBgGrey,
                                padding: `${token.paddingXXS}px ${token.paddingSM}px`,
                                borderRadius: token.borderRadius,
                            }}>
                                <Typography.Text style={{
                                    // color: token.colorTextDescription,
                                    fontSize: token.fontSizeHeading3,
                                    // fontWeight: token.fontWeightStrong,
                                    lineHeight: 1.2
                                }}>
                                    {item.limit.type === "client" ? clientLimitValue : globalLimitValue}
                                </Typography.Text>
                            </div>
                        </Tooltip>
                    </Flex>
                    <Typography.Paragraph style={{
                        maxWidth: 600,
                        marginBottom: 0
                    }}>
                        {item.metric.name + " "}
                        {item.red_flag_raised
                            ? (item.metric.low_is_good ? " exceeds the upper " : " falls below the lower ")
                            : (item.metric.low_is_good ? " is below the upper " : " is above the lower ")
                        }
                        limit. {" "}
                        <Button
                            type={"link"}
                            style={{padding: 0}}
                            onClick={() => Modal.info({
                                content: item.red_flag_description
                            })}
                        >
                            Why it's {item.red_flag_raised ? "bad" : "good"}?
                        </Button>
                    </Typography.Paragraph>
                    {!is_null(item.limit.client) && item.limit.type === "client"
                        && <Alert
                            type={"warning"}
                            message={<Flex gap={"middle"} align={"flex-start"}>
                                <ExclamationCircleFilled style={{
                                    color: token.colorWarning,
                                    fontSize: token.fontSizeLG,
                                    marginTop: 2
                                }}/>
                                <div>
                                    <Typography.Text style={{fontWeight: token.fontWeightStrong}}>
                                        Client's custom limit of {clientLimitValue} is
                                        used
                                    </Typography.Text>
                                    <Typography.Paragraph>
                                        It overrides the default limit
                                        of {globalLimitValue}.
                                        The custom limit was set with the
                                        following comment:
                                    </Typography.Paragraph>
                                    <Typography.Paragraph
                                        style={{fontStyle: "italic"}}>
                                        "{item.limit.comment}"
                                    </Typography.Paragraph>
                                    <Typography.Paragraph
                                        style={{fontStyle: "italic"}}>
                                        {item.limit.user_name ?? item.limit.user}
                                        <br/>
                                        {moment(item.limit.timestamp).format("MMM DD, YYYY")}
                                    </Typography.Paragraph>
                                </div>
                            </Flex>}
                            style={{maxWidth: 600}}
                        />
                    }
                    {!is_null(item.limit.client) && item.limit.type !== "client"
                        && <Tooltip title={<>
                            Custom limit of ${clientLimitValue} is set, but ignored.
                            To apply it, choose "Use clients' limits"
                            in the menu above
                        </>}>
                            <Tag icon={<ExclamationCircleFilled/>} color="processing">
                                Custom limit is ignored
                            </Tag>
                        </Tooltip>
                    }
                </div>
                {!!item.details
                    && <MetricsResultDetails
                        details={item.details}
                        style={{marginBottom: token.marginXXL}}
                    />
                }
                {item.status.status === "in_progress"
                    && <Flex gap={"small"} style={{marginBottom: token.margin}}>
                        <Badge dot status={"processing"}/>
                         Issue is marked as "In progress" until
                        {" " + moment(item.status.until).format("MMM DD, YYYY")}
                    </Flex>
                }
                {item.status.status === "resolved"
                    && <Flex gap={"small"} style={{marginBottom: token.margin}}>
                        <Badge dot status={"processing"}/>
                        Issue is marked as "Resolved" until
                        {" " + moment(item.status.until).format("MMM DD, YYYY")}
                    </Flex>
                }
                <Flex gap={"small"} wrap={"wrap"}>
                    {item.status.status !== "in_progress" && item.status.status !== "resolved" && item.red_flag_raised
                        && <Button
                            type={"primary"}
                            onClick={() => setOpenProgressModal(true)}
                        >
                            Start progress
                        </Button>
                    }
                    {item.status.status !== "resolved" && (item.red_flag_raised || item.status.status === "in_progress")
                        && <Button
                            type={item.status.status === "in_progress" ? "primary" : "default"}
                            onClick={() => setOpenResolvedModal(true)}
                        >
                            Mark as resolved
                        </Button>
                    }
                    {item.status.status !== "unaddressed"
                        && <Button onClick={() => setOpenClearStatusModal(true)}>
                            Mark as unaddressed
                        </Button>
                    }
                    <Button onClick={() => setOpenLimitModal(true)}>
                        {is_null(item.limit.client) ? "Set custom limit" : "Update custom limit"}
                    </Button>
                    {!is_null(item.limit.client)
                        && <Button onClick={() => saveClientLimit()}>
                            Remove custom limit
                        </Button>
                    }
                </Flex>
            </div>
        }
        <IssueStatusModal
            open={openProgressModal}
            setOpen={setOpenProgressModal}
            status="in_progress"
            clientId={client.id}
            flagKey={item.red_flag_key}
            flagName={item.red_flag_name}
            onFinish={updateData}
        />
        <IssueStatusModal
            open={openResolvedModal}
            setOpen={setOpenResolvedModal}
            status="resolved"
            clientId={client.id}
            flagKey={item.red_flag_key}
            flagName={item.red_flag_name}
            onFinish={updateData}
        />
        <IssueStatusModal
            open={openClearStatusModal}
            setOpen={setOpenClearStatusModal}
            status="unaddressed"
            clientId={client.id}
            flagKey={item.red_flag_key}
            flagName={item.red_flag_name}
            onFinish={updateData}
        />
        <LimitModal
            modalOpen={openLimitModal}
            setModalOpen={setOpenLimitModal}
            clientName={client.name}
            limitValue={item.limit.client}
            flagName={item.red_flag_name}
            precision={item.metric.decimals_precision}
            limitDetails={configsDetails[item.red_flag_key]}
            save={(values) => {saveClientLimit(values)}}
        />
    </div>
}

export default ClientFlagCard;
