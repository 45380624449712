import React, {useState} from "react";
import {Button, Flex, message, Modal, theme, Tooltip, Typography} from "antd";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import {
    exchangePlaidPublicToken,
} from "@API/clients";
import FakeProgress
    from "@components/retail/portfolio/constructor/FakeProgress";
import {LinkOutlined} from "@ant-design/icons";
import {useAuth} from "@hooks/AuthHook";
import {RequestLockTooltip} from "@components/retail/cards/LockTooltip";
import PlaidLinkLauncher
    from "@components/retail/portfolio/plaid/PlaidLinkLauncher";
import ButtonCard from "@components/retail/cards/ButtonCard";


const PlaidCreateLink = ({
                             onClick = null,
                             asCard = false,
                             style = {}
                         }) => {
    const {requestLockInfo} = useAuth();
    const {id, proposal, fetchProposal} = useHolisticoProposalContext();
    const [ready, setReady] = useState(false);
    const [launchTrigger, setLaunchTrigger] = useState(0);
    const [openProgress, setOpenProgress] = useState(false);
    const [modal, modalContextHolder] = Modal.useModal();

    const lockInfo = requestLockInfo("#post-exchange-plaid-public-token");
    const linkLockInfo = requestLockInfo("#get-create-plaid-link-token");
    if (linkLockInfo.locked) {
        lockInfo.locked = true;
        lockInfo.until = null;
    }

    const onSuccess = (publicToken, metadata) => {
        const existingPortfolios = (proposal.current_portfolios ?? []).concat(proposal.proposed_portfolios ?? []);
        const existingInstitutions = existingPortfolios.map(p => p.auxiliary_data?.plaid_data?.institution_id).filter(inst => !!inst);
        if (existingInstitutions.includes(metadata.institution.institution_id)) {
            modal.error({
                title: `${metadata.institution.name} already linked`,
                content: <>
                    We cannot add accounts from {metadata.institution.name},
                    because you already have accounts linked to this institution.
                    To add accounts from {metadata.institution.name}, use
                    "Select linked accounts" in the account card.
                </>
            });
            return
        }
        setOpenProgress(true);
        exchangePlaidPublicToken(id, publicToken, metadata, (response, error) => {
            if (error) {
                message.error("Error while linking account(s)");
                console.debug(error);
            } else {
                fetchProposal();
            }
            setOpenProgress(false);
        })
    };


    return <>
        <RequestLockTooltip
            limitMessage={"You exceeded the limit of linking accounts from your institution."}
            lockMessage={"Linking account from your institution is not available for you current subscription."}
            lockedUntil={lockInfo.until}
            locked={lockInfo.locked}
        >
            {asCard
                ? <ButtonCard
                    icon={<LinkOutlined/>}
                    showArrow={true}
                    onClick={() => {
                        onClick?.();
                        setLaunchTrigger(prev => prev + 1);
                    }}
                    disabled={!ready || lockInfo.locked}
                >
                    <Flex vertical align={"flex-start"}>
                        <Button
                            type={"link"}
                            disabled={!ready || lockInfo.locked}
                            style={{padding: 0}}
                        >
                            Link from institution
                        </Button>
                        <Typography.Text>
                            Link investment account(s) from your institution
                            using Plaid
                        </Typography.Text>
                    </Flex>
                </ButtonCard>
                : <Button
                    onClick={() => {
                        onClick?.();
                        setLaunchTrigger(prev => prev + 1);
                    }}
                    disabled={!ready || lockInfo.locked}
                >
                    Link from institution
                </Button>
            }
        </RequestLockTooltip>
        <FakeProgress
            tip={"Linking accounts and updating portfolio analytics..."}
            open={openProgress}
            time={60000}
        />
        <PlaidLinkLauncher
            clientId={id}
            onSuccess={onSuccess}
            onReady={() => setReady(true)}
            launchTrigger={launchTrigger}
        />
        {modalContextHolder}
    </>
}

export default PlaidCreateLink;
