import React, {useRef, useState} from "react";
import {Empty, message, Select} from 'antd';
import {SearchOutlined} from "@ant-design/icons";
import {searchClients} from "@API/supervision";
import {debounce} from "@global/Utils";


const ClientSearch = ({setSelectedClient, clientName}) => {
    const [clients, setClients] = useState(null);
    const [loadingClients, setLoadingClients] = useState(false);
    const abortControllerRef = useRef(new AbortController());

    const handleChange = (value) => {
        setSelectedClient(value);
    }

    const handleSearch = debounce((value) => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
        abortControllerRef.current = new AbortController();

        if (value !== "") {
            setLoadingClients(true);
            searchClients(value, (data, error) => {
                setLoadingClients(false);
                if (error) {
                    message.error("Error while searching clients");
                    return
                }
                setClients(data);
            }, abortControllerRef.current.signal)
        } else {
            setClients(null);
        }
    }, 300)

    return (
        <Select
            style={{width: "100%"}}
            showSearch
            value={clientName}
            loading={loadingClients}
            placeholder="Search client"
            defaultActiveFirstOption={false}
            filterOption={false}
            onSearch={handleSearch}
            onChange={handleChange}
            notFoundContent={clients !== null ? <Empty description="No clients"/> : null}
            suffixIcon={<SearchOutlined/>}
        >
            {clients && clients.map((client, inx) => {
                return <Select.Option key={inx} value={client._id}>
                    {client.name}
                </Select.Option>
            })}
        </Select>
    )
}

export default ClientSearch
