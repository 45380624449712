import React, {useState} from "react";
import {
    Button,
    Modal,
    Flex,
    theme,
    Typography, Tooltip
} from 'antd';
import ClientActions from "@components/supervision/actions/ClientActions";
import ValueFormatter, {is_null} from "@global/ValueFormatter";
import MobileDrawer from "@components/retail/cards/MobileDrawer";
import ClientInfo from "@components/supervision/client/ClientInfo";
import {Link} from "react-router-dom";
import {mailtoSupervision} from "@global/Mail";
import {MailOutlined} from "@ant-design/icons";
import {ActionLogDrawer} from "@components/supervision/client/ActionLog";


const {useToken} = theme;


const ViewClientInfoButton = ({client}) => {
    const [openClientDetailsDrawer, setOpenClientDetailsDrawer] = useState(false);
    const {token} = useToken();

    return <>
        <Button onClick={() => setOpenClientDetailsDrawer(true)}>
            View client info
        </Button>
        <MobileDrawer
            open={openClientDetailsDrawer}
            onClose={() => setOpenClientDetailsDrawer(false)}
            width={600}
            style={{background: token.colorBgBase}}
        >
            <Typography.Title level={4} style={{marginTop: 0}}>
                Client info
            </Typography.Title>
            <ClientInfo client={client}/>
        </MobileDrawer>
    </>
}


const AnalyzePortfolioButton = ({client}) => {
    return <Button onClick={() => Modal.info({
        content: <>
            <Typography.Paragraph>
                To analyze client's portfolio, you need to setup
                connection to a custodian like Orion.
            </Typography.Paragraph>
            <Typography.Paragraph>
                For now creating proposal is not available.
            </Typography.Paragraph>
        </>
    })}>
        Analyze portfolio
    </Button>
}


const CreateProposalButton = ({client}) => {
    return <Button onClick={() => Modal.info({
        content: <>
            <Typography.Paragraph>
                To create proposal, you need to setup
                connection to a custodian like Orion.
            </Typography.Paragraph>
            <Typography.Paragraph>
                For now creating proposal is not available.
            </Typography.Paragraph>
        </>
    })}>
        Create proposal
    </Button>
}


const EmailRepresentativeButton = ({client}) => {
    return <Button
        onClick={(e) => {
            const clientInfo = {
                client_id: client.id,
                client_name: client.name,
                external_id: client.external_id,
                client_mute: client.client_mute
            }
            const representativeInfo = {
                name: client.representative,
                email: client.representative_email
            };
            const metricFlagData = client.red_flags;
            window.location.href = mailtoSupervision(clientInfo, representativeInfo, metricFlagData);
            e.preventDefault();
        }}
    >
        Email representative
    </Button>
}


const ShowHistoryButton = ({client}) => {
    const [openDrawer, setOpenDrawer] = useState(false);

    return <>
        <Button onClick={() => setOpenDrawer(true)}>
            View history
        </Button>
        <ActionLogDrawer
            client={client}
            open={openDrawer}
            setOpen={setOpenDrawer}
        />
    </>
}


const ClientActionsPanel = ({client, hideHistory}) => {
    const {token} = useToken();

    const v2 = process.env.REACT_APP_UI_VERSION && process.env.REACT_APP_UI_VERSION.includes('holistico');

    return (v2
            ? <Flex gap={"small"} wrap={"wrap"} style={{marginTop: token.margin}}>
                <EmailRepresentativeButton client={client}/>
                <AnalyzePortfolioButton client={client}/>
                <CreateProposalButton client={client}/>
                <ViewClientInfoButton client={client}/>
                {hideHistory && <ShowHistoryButton client={client}/>}
            </Flex>
            : <ClientActions
                size={'large'}
                showTitle={false}
                client={{
                    client_id: client.id,
                    client_name: client.name,
                    external_id: client.external_id,
                    client_mute: client.client_mute
                }}
                actions={['notify', 'mute_client_warn', 'analyze', 'proposal']}
                representativeInfo={{
                    name: client.representative,
                    email: client.representative_email
                }}
                metricFlagData={client.red_flags}
                style={{
                    marginTop: token.marginXXS,
                    marginLeft: -22
                }}
            />
    )
}


const ClientDescriptionTags = ({client}) => {
    const {token} = useToken();

    const mkval = !is_null(client.market_value) ? ValueFormatter.compactCurrency(client.market_value) : null;
    const age = client.birth_date ? ValueFormatter.dateToAge(client.birth_date) + " y.o." : null;
    const representative = "Repr.: " + client.representative ?? "Unknown";

    return <Flex gap={"small"} align={"baseline"} wrap={"wrap"} style={{
        marginTop: token.marginXXS,
    }}>
        {mkval && <>
            <Typography.Text>
                {mkval}
            </Typography.Text>
            ・
        </>}
        {age && <>
            <Typography.Text>
                {age}
            </Typography.Text>
            ・
        </>}
        <Typography.Text>
            {representative}
        </Typography.Text>
    </Flex>
}


const ClientReportHeader = ({client, hideHistory}) => {
    const {token} = useToken();

    return <div style={{marginBottom: token.marginXXL}}>
        <Typography.Title level={2} style={{
            margin: 0,
            lineHeight: 1.2,
            fontWeight: token.fontWeightStrong
        }}>
            {client.name}
        </Typography.Title>
        <ClientDescriptionTags client={client}/>
        <ClientActionsPanel client={client} hideHistory={hideHistory}/>
    </div>
}

export default ClientReportHeader;
