import React from "react";
import {
    Affix,
    Button,
    ConfigProvider,
    Drawer,
    Flex,
    theme,
    Typography
} from "antd";
import {LeftOutlined} from "@ant-design/icons";


const {useToken} = theme;


const MobileDrawer = ({
                          open,
                          onClose,
                          closable = true,
                          title,
                          width = 900,
                          maskClosable = true,
                          backButtonText = "Back",
                          style = {},
                          rootClassName = null,
                          placement = "right",
                          id,
                          children
                      }) => {
    const {token} = useToken();

    return <Drawer
        onClose={onClose}
        open={open}
        width={width}
        closable={false}
        maskClosable={maskClosable}
        title={null}
        placement={placement}
        style={{background: token.colorBgGreyDark, ...style}}
        styles={{body: {paddingTop: token.paddingXS}}}
        className={"holistico retail"}
        rootClassName={rootClassName}
    >
        <ConfigProvider theme={{
            components: {Tabs: {zIndexPopup: 10000}}
        }}>
            {closable
                && <Button
                    type={"link"}
                    onClick={onClose}
                    size={"large"}
                    icon={<LeftOutlined/>}
                    style={{padding: 0, marginBottom: token.padding}}
                    id={`${id}-back`}
                >
                    {backButtonText}
                </Button>
            }
            {title
                && <Typography.Title level={4} style={{margin: "0 0 12px 0"}}>
                    {title}
                </Typography.Title>
            }
            {children}
        </ConfigProvider>
    </Drawer>
}


export default MobileDrawer;
