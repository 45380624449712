import {useAuth} from "@hooks/AuthHook";
import {ConfigProvider, Spin, theme} from "antd";
import SPASLayout from "@root/SPASLayout";
import React from "react";
import {ComponentsWrapper} from "@hooks/ComponentsHandler";
import {StorageWrapper} from "@hooks/GlobalStorage";
import {SocketWrapper} from "@hooks/WebSocketHook";
import "./AppSpas.less"


const {useToken} = theme;


const AppSpas = ({}) => {
    const {user} = useAuth();
    const {token} = useToken();

    return <Spin spinning={null == user}>
        <SocketWrapper>
            <StorageWrapper>
                <ComponentsWrapper>
                    <ConfigProvider
                        theme={{
                            token: {
                                borderRadius: 6,
                                fontFamily: 'Red Hat Display',
                                fontSize: 16,
                                fontWeightStrong: 600,
                                colorBgBase: '#ffffff',
                                fontSizeXS: 12,
                                colorBgGrey: "rgb(248, 248, 248)",
                                colorBgGreyDark: "rgb(240, 240, 240)",
                                colorBgHoverBlue: "#e6f7ff",
                                colorPrimaryGrey: "rgb(100, 100, 100)",
                                colorPrimaryHoverGrey: "rgb(140, 140, 140)",
                                colorStats: '#e0ad60',
                                // colorRadioSelectedBg: '#1F2830',
                                colorRadioSelectedBg: '#4f5b67',
                            },
                        }}>
                        {user ? <SPASLayout/> : <div style={{height: 600}}></div>}
                    </ConfigProvider>
                </ComponentsWrapper>
            </StorageWrapper>
        </SocketWrapper>
    </Spin>
}

export default AppSpas
