import React, {useEffect, useState} from "react";
import {Navigate, Route, Routes, useParams} from "react-router-dom";
import {
    Button,
    ConfigProvider,
    Flex,
    FloatButton,
    Layout,
    message,
    Result,
    Spin,
    theme,
    Typography
} from 'antd';
import {getRetailClientId} from "@API/clients";
import {HolisticoProposalContextProvider} from "@hooks/ProposalContext";
import {AiChatContextProvider} from "@hooks/AiChatContext";
import RetailHeader from "@app/layout/retail/RetailHeader";
import MyPortfolio from "@components/retail/portfolio/MyPortfolio";
import packageInfo from "../../../../package.json";
import HHeader from "@app/layout/ria/HHeader";
import Screener from "@root/site/Screener";
import WelcomePage from "@components/retail/welcome/WelcomePage";
import {
    ModelsMarketplace
} from "@components/screener/AssetScreener";
import Profile from "@components/retail/profile/Profile";
import AiChatPanel from "@components/retail/portfolio/AiChatPanel";
import FreeSubscriptionAlert
    from "@components/retail/cards/FreeSubscriptionAlert";
import {getLandingUrl} from "@API/utils";
import {useAuth} from "@hooks/AuthHook";


const {Content, Footer} = Layout;
const {useToken} = theme;


export const PageNotFound = () => {
    return <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
            <Button
                type="primary"
                onClick={() => navigate("")}
            >
                Back Home
            </Button>
        }
    />
}


const RetailLayoutInProposalContext = ({externalId = null}) => {
    const [loading, setLoading] = useState(true);
    const [proposalId, setProposalId] = useState(null);
    const [aiExpand, setAiExpand] = useState(false);

    useEffect(() => {
        if (externalId) {
            console.info(`using external proposal with ${externalId}`)
            setLoading(false)
            setProposalId(externalId)
        } else {
            setLoading(true);
            getRetailClientId((response, error) => {
                if (!error) {
                    setProposalId(response);
                } else {
                    message.error("Something went wrong while loading your portfolio!");
                }
                setLoading(false);
            })
        }

    }, [])

    return <Spin spinning={loading} style={{height: 800}} size={"large"}>
        {proposalId
            &&
            <HolisticoProposalContextProvider
                proposal_id={proposalId}
                autoUpdateRetirement={true}
                retail={true}
            >
                <AiChatContextProvider retail={true}>
                    <Routes>
                        {externalId &&
                            <Route exact path="" element={<MyPortfolio/>}/>}
                        <Route path="dashboard" element={<WelcomePage/>}/>
                        <Route path="portfolio" element={<MyPortfolio/>}/>
                        <Route path="screener/*" element={<Screener inProposalContext={true} retail={true}/>}/>
                        <Route path="*" element={<PageNotFound/>}/>
                    </Routes>
                    <AiChatPanel aiExpand={aiExpand} setAiExpand={setAiExpand}/>
                </AiChatContextProvider>
            </HolisticoProposalContextProvider>
        }
    </Spin>
}


const RetailLayout = () => {
    const {accessDetails, darkMode} = useAuth();
    const {externalId} = useParams();
    const version = packageInfo.version;
    const {token} = useToken();

    return (
        <Layout style={{
            minHeight: "100vh",
            background: darkMode ? "#22252a" : "#f6f9fa",
        }}>
            {externalId ? <HHeader/> : <RetailHeader/>}
            <Content style={{
                backgroundImage: "none",
                background: "none",
                margin: "0 auto",
                width: "98%",
                maxWidth: 1600
            }}>
                <FreeSubscriptionAlert/>
                <Routes>
                    {!externalId && <Route exact path="" element={<Navigate to="dashboard" replace={true}/>}/>}
                    <Route path="profile/*" element={<Profile/>}/>
                    {accessDetails.subscription_expired
                        ? <Route path="*" element={<Navigate to="profile/subscription" replace={true}/>}/>
                        : <Route path="*" element={<RetailLayoutInProposalContext externalId={externalId}/>}/>
                    }
                </Routes>
            </Content>
            <FloatButton.BackTop
                visibilityHeight={800}
                style={{
                    bottom: 16,
                    insetInlineEnd: 138
                }}
            />
            <Footer style={{
                marginTop: token.marginXL,
                background: "none"
            }}>
                <ConfigProvider theme={{
                    token: {
                        colorLink: "rgb(130, 130, 130)",
                        linkHoverDecoration: "underline",
                    }
                }}>
                    <Flex gap={token.marginLG} justify={"space-between"}
                          wrap={"wrap"} style={{
                        width: "100%",
                        maxWidth: 800,
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginBottom: 48
                    }}>
                        <Flex vertical style={{flexGrow: 1}}>
                            <Typography.Text>
                                ©{(new Date().getFullYear())} Holistico
                                Investment
                                LLC
                            </Typography.Text>
                            <Typography.Text style={{
                                fontSize: token.fontSizeSM,
                                color: token.colorTextDescription
                            }}>
                                v. {version}
                            </Typography.Text>
                        </Flex>
                        <Flex wrap={"wrap"} gap={token.marginXL}
                              justify={"space-between"} style={{flexGrow: 1}}>
                            <Flex vertical gap={8}
                                  style={{fontSize: token.fontSizeSM}}>
                                <Typography.Text
                                    style={{fontSize: token.fontSizeSM}}>
                                    DATA PROVIDERS
                                </Typography.Text>
                                <a href={"https://intrinio.com"}
                                   target={"_blank"}>
                                    Intrinio
                                </a>
                                <a href={"https://www.morningstar.com"}
                                   target={"_blank"}>
                                    Morningstar
                                </a>
                                <a href={"https://signetfm.com"}
                                   target={"_blank"}>
                                    Signet
                                </a>
                            </Flex>
                            <Flex vertical gap={8}
                                  style={{fontSize: token.fontSizeSM}}>
                                <Typography.Text
                                    style={{fontSize: token.fontSizeSM}}>
                                    LEGAL
                                </Typography.Text>
                                <a href={getLandingUrl() + "/privacy-policy"}
                                   target={"_blank"}>
                                    Privacy Policy
                                </a>
                                <a href={getLandingUrl() + "/terms-of-service"}
                                   target={"_blank"}>
                                    Terms of Service
                                </a>
                            </Flex>
                        </Flex>
                    </Flex>
                </ConfigProvider>
            </Footer>
        </Layout>
    );
}


export default RetailLayout;
