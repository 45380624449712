import React, {useEffect, useRef, useState} from "react";
import {Col, message, Row, Select, Tooltip, Typography} from "antd";
import {getGlobalConfigs, getGlobalConfigsDetails} from "@API/supervision";
import { defaultConfigKey } from "./GlobalConfigs";
import "./GlobalConfigs.less";


const LimitsTooltip = ({limits, details}) => (
    <>
        <Row><b>Config limits</b></Row>
        {Object.keys(details).map((flagKey, inx) => {
            return <Row key={inx}>
                <Col>{details[flagKey].name} </Col>
                <Col style={{marginLeft: "auto"}}>
                    {details[flagKey].limit.type === "choice" ? 
                    details[flagKey].limit.options.filter(option => option.key === limits[flagKey])[0].name :
                    limits[flagKey]}
                    {details[flagKey].limit.units === "%" && details[flagKey].limit.type !== "choice" && "%"}
                </Col>
            </Row>
        })}
    </>
)

const ConfigSelector = ({setSelectedConfig, setConfigsDetails}) => {
    const [configsLoading, setConfigsLoading] = useState(false);
    const [configDetailsLoading, setConfigDetailsLoading] = useState(false);
    const [configsList, setConfigsList] = useState(null);
    const [globalConfigsDetails, setGlobalConfigsDetails] = useState(null);
    const [selectedConfigId, setSelectedConfigId] = useState(null);

    useEffect(() => {
        const controller = new AbortController();
        setConfigDetailsLoading(true);
        getGlobalConfigsDetails((data, error) => {
            if (error) {
                message.error("Error while retrieving configs details")
            } else {
                setGlobalConfigsDetails(data);
                if (setConfigsDetails) {
                    setConfigsDetails(data);
                }
            }
            setConfigDetailsLoading(false);
        }, controller.signal);

        return () => {
            controller.abort();
        }
    }, [])

    useEffect(() => {
        const controller = new AbortController();
        setConfigsLoading(false);
        getGlobalConfigs((data, error) => {
            if (error) {
                message.error("Error while retrieving configs");
            } else {
                setConfigsList(data);
            }
            setConfigsLoading(false);
        }, controller.signal);

        return () => {
            controller.abort();
        }
    }, [])

    useEffect(() => {
        if (!configsList) {
            return;
        }
        const configIdFromUrl = new URLSearchParams(window.location.search).get("configId");
        if (configIdFromUrl) {
            const activeConfig = configsList.find(config => (config.id === configIdFromUrl && !config.out_of_date));
            if (activeConfig && activeConfig.id !== selectedConfigId) {
                setSelectedConfigId(activeConfig.id);
                setSelectedConfig(activeConfig);
                return;
            }
        }
        const defaultConfigId = localStorage.getItem(defaultConfigKey);
        if (defaultConfigId) {
            const activeConfig = configsList.find(config => (config.id === defaultConfigId && !config.out_of_date));
            if (activeConfig && activeConfig.id !== selectedConfigId) {
                setSelectedConfigId(activeConfig.id);
                setToSearchParams(activeConfig.id);
                setSelectedConfig(activeConfig);
                return;
            }
        }
        const activeConfig = configsList.find(config => !config.out_of_date);
        if (activeConfig && activeConfig.id !== selectedConfigId) {
            setSelectedConfigId(activeConfig.id);
            setSelectedConfig(activeConfig);
        } else {
            message.error("Fix out of date configs");
        }
    }, [configsList])


    const setToSearchParams = (configId) => {
        const url = new URL(window.location.href);
        url.searchParams.set('configId', configId);
        window.history.pushState(null, '', url.toString());
    }

    return (
        <Select
            style={{width: "100%"}}
            loading={configsLoading || configDetailsLoading}
            placeholder="Select config"
            value={selectedConfigId}
            onChange={(value) => {
                setSelectedConfigId(value);
                setSelectedConfig(configsList.find(config => config.id === value));
                setToSearchParams(value);
                localStorage.setItem(defaultConfigKey, value);
            }}
        >
            {configsList && globalConfigsDetails && configsList.map((config, inx) => {
                return <Select.Option key={inx} value={config.id} disabled={config.out_of_date}>
                        <Typography.Text style={{fontSize: ''}}>
                            {config.name} {config.out_of_date && <i>(Out of date)</i>}
                            <Tooltip
                                overlayInnerStyle={{minWidth: "260px"}}
                                title={<LimitsTooltip details={globalConfigsDetails} limits={config.limits}/>}
                            >
                                <sup style={{color: '#E0AD60'}}>#</sup>
                            </Tooltip>
                        </Typography.Text>
                </Select.Option>
            })}
        </Select>
    )
}

export default ConfigSelector
