import React, {createContext, useContext, useEffect, useState} from "react";
import dayjs from "dayjs";
import {useRequest, useUpdateEffect} from "ahooks";
import {message, Spin} from "antd";
import {
    loadFullClient as loadFullProposal,
    saveQuestionnaire, updateAiSettings, updateClientInfo as _updateClientInfo,
} from "@API/clients";
import {getAiThoughts} from "@API/ahooks/proposal";
import {useAuth} from "@hooks/AuthHook";
import {
    prepareDataForRequest
} from "@holistico/proposal/view/retirement_map/RetirementMap";
import {calculateRetirement} from "@API/retirement";


const DEFAULT_AI_SETTINGS = {
    'model': 'gpt-4',
    'max_tokens': 800,
    character_params: {
        charm: 5,
        humor: 5,
        mood: 5,
        intelligence: 5,
        assertiveness: 5,
    }
}


const ProposalContext = createContext(null);


const HolisticoProposalContextProvider = ({
                                              proposal_id,
                                              startLoading = true,
                                              loadIteration = 0,
                                              autoUpdateRetirement = false,
                                              retail = false,
                                              children
                                          }) => {
    const {requestLockInfo} = useAuth();
    const [id, setId] = useState(proposal_id);
    const [proposal, setProposal] = useState(null);
    const [conversation, setConversation] = useState([]);
    const [notes, setNotes] = useState(null);
    const [benchmark, setBenchmark] = useState(null);
    const [loading, setLoading] = useState(startLoading);
    const [notesInReport, setNotesInReport] = useState(true);
    const [resultData, setResultData] = useState({
        message: '',
        status: 'info',
        title: ''
    })
    const [aiSettings, setAiSettings] = useState(null);
    const [aiAnalyticalComments, setAnalyticalComments] = useState(null);
    const [aiAnalyticalCommentsProposed, setAnalyticalCommentsProposed] = useState(null);
    const {
        runAsync: getAiAnalyticalComments,
        loading: loadingAiAnalyticalComments
    } = useRequest(getAiThoughts, {manual: true});
    const [loadingRetirement, setLoadingRetirement] = useState(false);
    const [retirement, setRetirement] = useState(null);
    const [loadingRetirementProposed, setLoadingRetirementProposed] = useState(false);
    const [retirementProposed, setRetirementProposed] = useState(null);

    useEffect(() => {
        setId(proposal_id);
    }, [proposal_id]);

    useEffect(() => {
        console.log(`loading proposal ${id}`)
        fetchProposal(true);
    }, [id])

    useEffect(() => {
        if (autoUpdateRetirement) {
            console.log("fetching retirement data")
            fetchRetirementData(false);
            if (!retail) {
                fetchRetirementData(true);
            }
        }
    }, [proposal]);

    useUpdateEffect(() => {
        console.log(`outer proposal ${id} update: ${loadIteration}`)
        fetchProposal(true);
    }, [loadIteration])

    const plugAiSettings = (fullProposal) => {
        if (fullProposal?.related_info?.ai_settings) {
            setAiSettings(fullProposal.related_info.ai_settings)
        } else {
            setAiSettings(DEFAULT_AI_SETTINGS)
        }
    }

    const plugConversation = (fullProposal) => {
        if (fullProposal?.related_info?.conversation.length > 0) {
            console.debug('updating the conversation')
            setConversation(fullProposal?.related_info?.conversation)
        } else {
            setConversation([])
        }
    }

    const plugNotes = (fullProposal) => {
        if (fullProposal?.related_info?.notes) {
            console.debug('updating the notes')
            setNotes(fullProposal?.related_info?.notes)
        } else {
            setNotes('')
        }
    }

    const fetchProposal = (with_loading = true, callback = null) => {
        if (id == null) {
            return
        }
        if (with_loading) {
            console.debug(`loading set!`)
            setLoading(true);
        }
        loadFullProposal(id, (result, error) => {
            setLoading(false);
            if (error) {
                console.log(result)
                setResultData({
                    message: result['message'],
                    status: 'error',
                    title: 'Access denied!'
                })
            } else {
                setProposal(result);
                plugAiSettings(result);
                plugConversation(result);
                plugNotes(result);
                updateAiAnalyticalComments(result, false);
                updateAiAnalyticalComments(result, true);
            }
            if (callback) {
                callback(result, error);
            }
        })
    }

    const fetchRetirementData = (proposed=false) => {
        const _setRetirement = proposed ? setRetirementProposed : setRetirement;
        const _setLoadingRetirement = proposed ? setLoadingRetirementProposed : setLoadingRetirement;
        if (!proposal) {
            _setLoadingRetirement(false);
            _setRetirement(null);
            return;
        }
         _setLoadingRetirement(true);
        const data = prepareDataForRequest(proposal, proposed);
        if (!data) {
            _setLoadingRetirement(false);
            _setRetirement(null);
            return;
        }
        console.log("hook/RetirementMap", data)
        calculateRetirement(data,
            ret_data => {
                _setRetirement(ret_data);
                _setLoadingRetirement(false);
            },
            error => {
                console.log(error);
                message.error("Something went wrong while processing retirement map data!");
                _setLoadingRetirement(false);
            }
        );
    }

    const updateBaseClientInfo = (values, with_loading = true, reload = true, callback) => {
        if (with_loading) {
            setLoading(true)
        }
        _updateClientInfo(proposal._id, values, (response, error) => {
            if (!error) {
                if (reload) {
                    fetchProposal(false);
                }
            } else {
                if (response.data?.validation_errors) {
                    response.data.validation_errors.map(err => {
                        message.error(err.msg);
                    })
                }
            }
            if (with_loading) {
                setLoading(false)
            }
            if (callback) {
                callback()
            }
        })
    }

    const updateClientInfo = (values, with_loading = false, reload = true, callback = null) => {
        _updateClientInfo(proposal._id, values, (response, error) => {
            if (!error) {
                updateQuestionnaire(values?.questionnaire, with_loading, reload, callback)
            } else {
                if (response.data?.validation_errors) {
                    response.data.validation_errors.map(err => {
                        message.error(err.msg);
                    })
                }
                if (callback) {
                    callback()
                }
            }
        });
    }

    const updateQuestionnaire = (values, with_loading = false, reload = true, callback = null) => {
        saveQuestionnaire(proposal._id, values, (response, error) => {
            if (!error) {
                if (reload) {
                    fetchProposal(with_loading);
                }
            } else {
                if (response.data?.validation_errors) {
                    response.data.validation_errors.map(err => {
                        message.error(err.msg);
                    })
                }
            }
            if (callback) {
                callback()
            }
        });
    }

    const updateAiAnalyticalComments = (_proposal = null, forProposed = false) => {
        if (_proposal) {
            // Check if comments exist
            const bucket = forProposed
                ? _proposal?.p_bucket_prop
                : _proposal?.p_bucket_cur;
            const comments = forProposed
                ? _proposal?.related_info?.structured_comments_proposed
                : _proposal?.related_info?.structured_comments;

            const ready = bucket?.calculated_values?.risk;
            const cvDate = bucket?.calculated_values?.last_update ? dayjs(bucket?.calculated_values?.last_update) : null
            const commentDate = comments?.last_update ? dayjs(comments?.last_update) : null;
            if (!ready || !cvDate) {
                return;
            }
            if (commentDate && commentDate >= cvDate) {
                // Comments exist, use it
                if (forProposed) {
                    setAnalyticalCommentsProposed(comments);
                } else {
                    setAnalyticalComments(comments);
                }
                return;
            }
        }
        console.log(
            "updateAiAnalyticalComments", "updating")
        // Comments don't exist or outdated
        const lockInfo = requestLockInfo("#get-ai-structured-comments");
        if (!lockInfo.locked) {
            getAiAnalyticalComments(id, "json", forProposed).then((result) => {
                if (result) {
                    console.log("updateAiAnalyticalComments", result.data);
                    if (forProposed) {
                        setAnalyticalCommentsProposed(result.data);
                    } else {
                        setAnalyticalComments(result.data);
                    }
                }
            }).catch((error) => {
                console.error(error);
                message.error("Failed to load AI analytical comments!");
            }).finally()
        }
    }

    const modifyAiSettings = (aiSettings) => {
        updateAiSettings(id, aiSettings, (response, error) => {
            if (!error) {
                setAiSettings(aiSettings)
            } else {
                message.error("Failed to update ai settings!")
            }
        })
    }

    return <ProposalContext.Provider
        value={{
            id,
            setId,
            proposal,
            setProposal,
            benchmark,
            setBenchmark,
            conversation,
            notes,
            setNotes,
            notesInReport,
            setNotesInReport,
            loading,
            setLoading,
            resultData,
            fetchProposal,
            updateClientInfo,
            updateBaseClientInfo,
            updateQuestionnaire,
            aiSettings,
            modifyAiSettings,
            updateAiAnalyticalComments,
            loadingAiAnalyticalComments,
            aiAnalyticalComments,
            aiAnalyticalCommentsProposed,
            retirement,
            loadingRetirement,
            setLoadingRetirement,
            retirementProposed,
            loadingRetirementProposed,
            setLoadingRetirementProposed,
        }}
    >
        <Spin
            style={{height: 800}}
            spinning={loading}
            tip="Loading..."
            size={"large"}
        >
            {children}
        </Spin>
    </ProposalContext.Provider>
}


const useHolisticoProposalContext = () => {
    const context = useContext(ProposalContext);
    if (!context) {
        throw new Error(
            "useHolisticoProposalContext must be used within HolisticoProposalContextProvider"
        )
    }
    return context;
}


export {HolisticoProposalContextProvider, useHolisticoProposalContext}
