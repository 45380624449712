/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {DatabaseOutlined} from '@ant-design/icons';
import {Button, message, Modal, Space, Spin, Typography} from 'antd';
import PortfolioCreator from '@root/components/portfolio/new/PortfolioCreator';
import {
    clone,
    createEmptyPortfolio,
    createFromFile,
    deleteModelOrTemp,
    fromOrion,
    getModels
} from "@API/portfolio";
import ExpandablePortfolioRow from '@root/components/portfolio/table/ExpandablePortfolioRow';
import {PortfolioListContext} from "@components/portfolio/contexts/PortfolioListContext";
import MainSearch from "@components/clients/MainSearch";
import ModelGraphButton from "@components/models/graph/ModelGraphButton";


const type = 'model';


const ModelPortfolios = () => {
    const [searchString, setSearchString] = useState(null);
    const [fetchTrigger, setFetchTrigger] = useState(0);
    const [showAddPortfolio, setShowAddPortfolio] = useState(false);
    const [portfolios, setPortfolios] = useState([]);
    const [loadingPortfolios, setLoadingPortfolios] = useState(true);

    useEffect(() => {
        const controller = new AbortController();
        setLoadingPortfolios(true);
        setShowAddPortfolio(false);
        getModels(true, searchString, (portfolios, error) => {
            if (!controller.signal.aborted) {
                if (!error) {
                    setPortfolios(portfolios);
                } else {
                    console.log(portfolios);
                    message.error("Error while loading models")
                }
                setLoadingPortfolios(false);
            }
        }, controller.signal);

        return () => {
            controller.abort();
        }
    }, [searchString, fetchTrigger]);

    const fetchModelData = (search) => {
        setFetchTrigger(prev => prev + 1);
    }

    const addFromFile = (formData, callback) => {
        createFromFile(formData, (result, error) => {
            if (!error) {
                message.success("Model was created successfully");
                fetchModelData()
                callback()
            } else {
                callback(error)
                message.error('Error while loading portfolio from file!');
            }
        })
    }

    const addEmpty = (postData) => {
        createEmptyPortfolio(postData, (result, error) => {
            if (!error) {
                message.success("Model was created successfully");
                fetchModelData()
            } else {
                message.error('Error while adding empty portfolio!');
            }
        })
    }

    const addFromOrion = (orion_id) => {
        fromOrion(orion_id, 'model', (result, error) => {
            if (!error) {
                message.success("Model was created successfully");
                fetchModelData()
            } else {
                message.error('Error while loading from Orion');
            }
        })
    }

    //we do nothing with weight here
    const addFromOther = (id, expand_portfolio_positions = false, weight, callback = () => {}) => {
        clone(id, 'model', {}, expand_portfolio_positions, (result, error) => {
            if (!error) {
                message.success("Model was created successfully");
                fetchModelData()
                callback()
            } else {
                message.error('' + error);
                callback(error)
            }
        })
    }

    const deleteExisting = (id) => {
        deleteModelOrTemp(id, (result, error) => {
            if (!error) {
                message.success("Model was removed successfully");
                fetchModelData()
            } else {
                message.error('Error while removing the model');
            }
        })
    }

    return <Spin
        style={{height: 800}}
        spinning={loadingPortfolios}
        tip="Loading models..."
        size={"large"}
    >
        <PortfolioListContext.Provider
            value={{
                addFromOther: addFromOther,
                addFromOrion: addFromOrion,
                reloadContext: () => fetchModelData(),
                addEmpty: addEmpty,
                addFromFile: addFromFile,
                removeOne: deleteExisting
            }}
        >
            <MainSearch
                fetchData={(value) => setSearchString(value)}
                placeholder={"Type in the model name to search"}
            />
            <Space>
                <Button
                    type="primary"
                    onClick={() => setShowAddPortfolio(true)}
                >
                    Add model
                </Button>
                <ModelGraphButton/>
            </Space>
            <div>
                {loadingPortfolios
                    ? null
                    : portfolios.length !== 0
                        ? <div className="portfolio-container">
                            {portfolios.map(portfolio => {
                                return <ExpandablePortfolioRow
                                    key={portfolio._id}
                                    portfolioInfo={portfolio}
                                    type={portfolio.type}
                                />
                            })}
                        </div>
                        : <div className="noDataBox">
                            <DatabaseOutlined style={{fontSize: '2em'}}/>
                            <div>
                                No models found
                            </div>
                        </div>
                }
            </div>
            <Modal
                title={<Typography.Title>Add model</Typography.Title>}
                className={'portfolio-select-modal'}
                open={showAddPortfolio}
                width={750}
                closable={false}
                maskClosable={true}
                onCancel={v => setShowAddPortfolio(false)}
                footer={[]}
            >
                <PortfolioCreator type={type}/>
            </Modal>
        </PortfolioListContext.Provider>
    </Spin>
}

export default ModelPortfolios;
